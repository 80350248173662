import React from 'react';
import ReactDOM from 'react-dom';
import MainRouter from './routes/main-route';
import GlobalStyle from './global/theme/global.styles';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import configureLocale from './global/configs/locale';

configureLocale();

ReactDOM.render(
    <React.StrictMode>
        <GlobalStyle />
        <MainRouter />
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
