export type MemberFamilyStatusItem = {
    label: string;
    value: string;
};

type MemberFamilyStatusType = {
    [key: string]: MemberFamilyStatusItem;
};

export const MemberFamilyStatus: MemberFamilyStatusType = {
    NOT_MARRIED: {
        label: 'Не состоит в браке',
        value: 'NOT_MARRIED',
    },
    MARRIED: {
        label: 'Состоит в браке',
        value: 'MARRIED',
    },
};

export const familyStatusOptions = Object.values(MemberFamilyStatus);
