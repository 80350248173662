import { MembersActions, MembersActionsEnum, MembersState } from './members.types';

const initialState = new MembersState();

const membersReducer = (state = initialState, action: MembersActions) => {
    switch (action.type) {
        case MembersActionsEnum.updateMembers:
            return {
                ...state,
                members: action.payload.members,
                totalCount: action.payload.totalCount,
            };
        case MembersActionsEnum.updateMemberCard:
            return {
                ...state,
                memberCard: action.payload,
            };
        case MembersActionsEnum.updateAdoptedInfoCard:
            return {
                ...state,
                adoptedInfoCard: action.payload,
            };
        default:
            return state;
    }
};

export default membersReducer;
