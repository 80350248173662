import * as t from 'io-ts';
import { UserRole, UserRoleItem } from './user-role.model';

export const UserListType = t.type({
    id: t.number,
    login: t.string,
    role: t.string,
});

export type UserListDTO = t.TypeOf<typeof UserListType>;

class UserList {
    id: number;
    login: string;
    role: UserRoleItem;

    constructor(dto: UserListDTO) {
        this.id = dto.id;
        this.login = dto.login;
        this.role = UserRole[dto.role] || UserRole.USER;
    }
}

export { UserList };
