import styled from 'styled-components';
import { Button } from '../form-controls/button/button';
import { colors, palette } from '../../styles/variables';

export const Wrapper = styled.div<{
    marginTop?: number;
    marginBottom?: number;
    marginRight?: number;
    borderTop?: string;
    width?: number;
    borderRadiusTop?: string;
    withBorderBottom?: boolean;
}>`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    flex-shrink: 0;
    background: ${palette.gray6};
    border-bottom: 1px solid ${colors.borderDefault};
    border-radius: ${({ borderRadiusTop }) => `${borderRadiusTop} ${borderRadiusTop} 0 0`};
    ${(props) => (props.marginTop ? `margin-top: ${props.marginTop}px;` : '')};
    ${(props) => (props.marginBottom ? `margin-bottom: ${props.marginBottom}px;` : '')};

    &.primary-tabs-styles {
        width: ${(props) => (props.marginRight ? `calc(100% - ${props.marginRight}px)` : '100%')};
        background: ${colors.white};
        ${(props) => props.withBorderBottom ? '' : 'border-bottom: none;'}
    }

    &.flex-styles {
        justify-content: center;

        ${(props) =>
        !props.borderTop ? `border-top: 1px solid ${colors.borderDefault};` : `border-top: ${props.borderTop};`};

        div:last-child {
            border-right: none;
        }
    }

    &.vertical-layout {
        background: ${palette.grayLight23};
        border-bottom: none;
        ${(props) => (props.width ? `width: ${props.width}px;` : '')};
        border-right: 1px solid ${colors.borderDefault};
    }
`;

export const TabWrap = styled.div`
    display: flex;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;

    &.flex-styles {
        width: 100%;
    }

    ::-webkit-scrollbar {
        display: none;
    }
    scrollbar-width: none;

    &.vertical-layout {
        flex-direction: column;
    }
`;

export const StyledPlusButton = styled(Button)`
    position: relative;
    align-self: center;
    top: 1px;
    margin: 0 8px;
    cursor: pointer;
    background: none;
    width: 34px;
    margin: 0;
    height: auto;

    &:hover {
        svg > path:last-child {
            fill: ${colors.lightBlack};
        }
    }
`;
