import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import { match } from 'react-router-dom';
import { Button } from '../../../../global/components/button';
import { InfoItem } from '../../../../global/components/info-item';
import { goToState } from '../../../../global/helpers/url-parse.helper';
import { Header, Root } from './ministry-card.styles';
import { useSpinner } from '../../../../global/hooks/use-spinner';
import { getMinistryCard } from '../../redux/ministries.selectors';
import { clearMinistryCard, deleteMinistryCard, loadMinistryCard } from '../../redux/ministries.actions';
import { InfoLine } from '../../../../global/components/info-line';
import { AuthService } from '../../../../auth/services/AuthService';
import { MemberItem } from '../../../family/pages/card/family-card.styles';
import { BottomPadding } from '../../../../global/components/main-wrap/main-wrap.styles';

type MinistryCardPageRouteParams = {
    id: string;
};

type MinistryCardProp = {
    match: match<MinistryCardPageRouteParams>;
};

const MinistryCard = (props: MinistryCardProp) => {
    const showForLeader = useMemo(() => AuthService.availableForLeader(), [AuthService.availableForLeader]);
    const showForAdmin = useMemo(() => AuthService.availableForAdmin(), [AuthService.availableForAdmin]);
    const dispatch = useDispatch();
    const spinnerRunner = useSpinner(dispatch);
    const ministryCard = useSelector(getMinistryCard);
    const [id, setId] = useState<number | null>(null);

    useEffect(() => {
        if (!showForLeader) {
            goToState('/');
            return;
        }
    }, []);

    useEffect(
        () => {
            if (props.match.params.id && Number(props.match.params.id) !== id) {
                dispatch(clearMinistryCard());
                setId(Number(props.match.params.id));
                spinnerRunner(loadMinistryCard(Number(props.match.params.id)));
            }
        },
        [props.match.params.id],
    );

    const editAvailableAsLeader = useMemo(() => {
        const member = AuthService.getUserInfo()?.member;
        if (!member || !ministryCard) {
            return false;
        }
        return member.id === ministryCard.lead.id || (ministryCard.assist && member.id === ministryCard.assist.id);
    }, [ministryCard]);

    const goToList = useCallback(() => {
        goToState('/ministries');
    }, []);

    const goToEdit = useCallback(() => {
        goToState(`/ministry/${props.match.params.id}/edit`);
    }, [props.match.params.id]);

    const goToDelete = useCallback(() => {
        if (!id) {
            return;
        }
        confirmAlert({
            title: 'Удаление',
            message: 'Вы уверены, что хотите удалить эту запись?',
            buttons: [
                {
                    label: 'Да',
                    onClick: async () => {
                        await spinnerRunner(deleteMinistryCard(id));
                        goToList();
                    },
                },
                {
                    label: 'Нет',
                    onClick: () => null,
                },
            ],
        });
    }, [confirmAlert, id, deleteMinistryCard, spinnerRunner, goToList]);

    if (!ministryCard) {
        return (
            <Root>
                <Header>
                    {!!showForAdmin && <>
                        <Button
                            title="Перейти в список"
                            onClick={goToList}
                        />
                    </>}
                </Header>
            </Root>
        );
    }

    return (
        <Root>
            <Header>
                {!!showForAdmin &&
                    <Button
                        title="Перейти в список"
                        onClick={goToList}
                    />
                }
                {
                    (!!showForAdmin || !!editAvailableAsLeader) &&
                    <Button
                        title="Редактировать"
                        onClick={goToEdit}
                    />
                }
                {!!showForAdmin &&
                    <Button
                        title="Удалить"
                        onClick={goToDelete}
                    />
                }
            </Header>
            <InfoItem
                title="Название"
                value={ministryCard.name}
            />
            <InfoLine>
                <InfoItem
                    title="Название"
                    value={ministryCard.name}
                />
                <InfoItem
                    title="Описание"
                    value={ministryCard.description}
                />
                {!!showForAdmin && <InfoItem
                    title="Чат в телеграмме"
                    value={<div>
                        {
                            ministryCard.chat && <a href={`#/chat-info/${ministryCard.chat.id}`}>{ministryCard.chat.chatName}</a>
                        }
                    </div>}
                />}
            </InfoLine>
            <InfoLine>
                <InfoItem
                    title="Лидер"
                    value={<div>
                        <a href={`#/member/${ministryCard.lead.id}`}>{ministryCard.lead.name}</a>
                    </div>}
                />
                <InfoItem
                    title="Помощник"
                    value={<div>
                        {
                            !!ministryCard.assist &&
                            <a href={`#/member/${ministryCard.assist.id}`}>{ministryCard.assist.name}</a>
                        }
                    </div>}
                />
                <InfoItem
                    title="Родительское служение"
                    value={<div>
                        {
                            !!ministryCard.parent &&
                            <a href={`#/ministry/${ministryCard.parent.id}`}>{ministryCard.parent.name}</a>
                        }
                    </div>}
                />
            </InfoLine>
            <InfoItem
                title="Участники"
                value={<div>
                    {
                        ministryCard.chat?.profiles?.map(el =>
                            <MemberItem key={el.id}>
                                <a href={`#/member/${el.id}`}>{el.name}</a>
                            </MemberItem>
                        )
                    }
                </div>}
            />
            <BottomPadding />
        </Root>
    );
};

export default MinistryCard;
