import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getAllHours, getAllMinutes } from '../../helpers/run-time.helpers';
import { Root, Description } from './run-time.styles';

type RunTimeProps = {
    value: string | null;
    onChange: (value: string) => void;
};

type SelectItem = {
    label: string;
    value: string;
};

const hours = getAllHours();
const minutes = getAllMinutes();

const RunTime = (props: RunTimeProps) => {
    const [hour, setHour] = useState<SelectItem | null>(hours[0]);
    const [minute, setMinute] = useState<SelectItem | null>(minutes[0]);

    useEffect(() => {
        props.onChange(`${hour?.value ?? hours[0].value}:${minute?.value ?? minutes[0].value}`);
    }, [hour, minute]);

    useEffect(() => {
        if (!props.value) {
            setHour(hours[0]);
            setMinute(minutes[0]);
        } else {
            const parse = props.value.split(':');
            setHour(hours.find(el => el.value === parse[0]) || hours[0]);
            setMinute(minutes.find(el => el.value === parse[1]) || minutes[0]);
        }
    }, [props.value]);
    return (
        <Root>
            <Select
                options={hours}
                defaultValue={hour}
                value={hour}
                onChange={setHour}
            />
            <Description>ч.</Description>
            <Select
                options={minutes}
                defaultValue={minute}
                value={minute}
                onChange={setMinute}
            />
            <Description>мин.</Description>
        </Root>
    );
};

export default RunTime;
