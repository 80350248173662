import React, { ReactElement, ReactNode } from 'react';


export enum ContextMenuModeEnum {
    mainMenu = 'mainMenu',
    editorPanelMenu = 'editorPanelMenu',
}

export type MarkdownHeaderLvL = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export type Coordinates = {
    x: number;
    y: number;
};

export type ContextMenuItemLeftSpace = MarkdownHeaderLvL;

type ContextMenuChildItem = {
    title: ReactNode;
    leftIcon?: ReactElement;
    rightLabel?: ReactElement;
    rightLabelStyle?: React.CSSProperties;
    labelClassName?: string;
    withDivider?: boolean;
    withoutCloseMenu?: boolean;
    onClick?: (() => void) | (() => Promise<void>);
    href?: string;
    download?: boolean;
    downloadFileName?: string;
    checkboxFlag?: boolean;
    disabled?: boolean;
    hide?: boolean;
    id?: string;
};

export type ContextMenuItem = ContextMenuChildItem & {
    href?: string;
    withDivider?: boolean;
    disabled?: boolean;
    hasHotKey?: string;
    metaKeyType?: 'alt' | 'ctrl';
    child?: ContextMenuChildItem[];
    id?: string;
    leftSpace?: ContextMenuItemLeftSpace;
};

export type ContextMenuValueProps = {
    isShow?: boolean;
    items?: ContextMenuItem[];
    clickCoordinates?: Coordinates;
    anchorElement?: EventTarget;
    mode?: ContextMenuModeEnum;
    maxHeight?: string;
    width?: string;
    closeCallback?: () => void;
    onClose?: () => void;
};

export type ContextMenuSetterContextProps = {
    updateContextMenuCallback: (value: ContextMenuValueProps) => (() => void) | null;
};

export const ContextMenuValueContext = React.createContext<ContextMenuValueProps>({});
export const ContextMenuSetterContext = React.createContext<ContextMenuSetterContextProps>({
    updateContextMenuCallback: () => null,
});
