import { createSelector, Selector } from 'reselect';
import { StateModel } from '../../../global/redux/state.model';
import { UserCard } from '../models/user-card.model';
import { UserList } from '../models/user-list.model';
import { UsersState } from './users.types';

export const getUsersState = (state: StateModel): UsersState => state.users;

export const getUsersList: Selector<StateModel, UserList[]> =
    createSelector(
        [getUsersState],
        (usersState) => usersState.users,
    );

export const getUsersTotalCount: Selector<StateModel, number> =
    createSelector(
        [getUsersState],
        (usersState) => usersState.totalCount,
    );

export const getUserCard: Selector<StateModel, UserCard | null> =
    createSelector(
        [getUsersState],
        (usersState) => usersState.userCard,
    );
