import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import NoteIcon from '@mui/icons-material/Note';
import DeleteIcon from '@mui/icons-material/Delete';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { confirmAlert } from 'react-confirm-alert';
import {
    addParamsToUrl,
    goToState,
} from '../../../../global/helpers/url-parse.helper';
import { deleteMemberCard, updateMembers } from '../../redux/members.actions';
import {
    getMembersList,
    getMembersTotalCount,
} from '../../redux/members.selectors';
import {
    Root,
    Header,
    FilterPanel,
    RedLabel,
    LabelWrap,
    TabWrap,
    FilterOverflowWrap,
    GreenLabel,
    ModalWrap,
} from './members-list.styles';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { MemberList } from '../../models/member-list.model';
import {
    getLocationSearchParamByKey,
    makeGetIsShowLocalSpinner,
} from '../../../../global/redux/global/global.selectors';
import { useLocalSpinner } from '../../../../global/hooks/use-local-spinner';
import { LocalSpinnerKeysEnum } from '../../../../global/constants';
import { BottomPadding, LocalSpinner } from '../../../../global/components/main-wrap/main-wrap.styles';
import { Button } from '../../../../global/components/button';
import { GetMembersListQueryParams } from '../../types/get-members-list-query-params';
import { GetMembersListBodyParams } from '../../types/get-members-list-body-params';
import { useDebounce } from '../../../../global/hooks/use-debounce';
import { InfoItem } from '../../../../global/components/info-item';
import { TextInput } from '../../../../global/components/form-controls/text-input/text-input';
import CreateFamilyModal from '../../../family/components/create-family-modal/create-family-modal';
import {
    MemberStatus,
    MemberStatusItem,
} from '../../models/member-status.model';
import SimpleSelect from '../../../../global/components/form-controls/simple-select/simple-select';
import { AuthService } from '../../../../auth/services/AuthService';
import { LinkA } from '../../../../global/theme/global.styles';
import { Checkbox, FormControlLabel } from '@mui/material';
import { confirmationOptions, MemberConfirmation, MemberConfirmationItem } from '../../models/member-confirmation';
import classNames from 'classnames';
import { PastorList } from '../../models/pastor-list.model';
import { LocalAnyType } from '../../../../global/helpers/type.helper';
import PastorNoteModal from '../../components/pastor-note-modal/pastor-note-modal';
import { CompleteCourse, completeCourseOptions } from '../../models/complete-course.model';
import { MemberFamilyStatus, MemberFamilyStatusItem } from '../../models/member-family-status.model';
import { MemberSex, MemberSexItem } from '../../models/member-sex.model';
import { Calendar } from 'primereact/calendar';
import { FormEvent } from 'primereact/ts-helpers';
import { parseDateForApi } from '../../../../global/helpers/date.helper';
import Modal from 'react-modal';
import SendListModal from '../../components/send-list-modal/send-list-modal';

type MemberItemProps = {
    item: MemberList;
    queryParams: GetMembersListQueryParams;
    bodyParams: GetMembersListBodyParams;
};

const MemberItem = (props: MemberItemProps) => {
    const dispatch = useDispatch();
    const [openCreateFamilyModal, setOpenCreateFamilyModal] =
        useState<boolean>(false);
    const [openPastorNoteModal, setOpenPastorNoteModal] =
        useState<boolean>(false);
    const localSpinnerRunner = useLocalSpinner(dispatch);
    const goToCard = useCallback(() => {
        goToState(`/member/${props.item.id}`);
    }, [props.item.id]);
    const goToEdit = useCallback(() => {
        goToState(`/member/${props.item.id}/edit`);
    }, [props.item.id]);
    const goToDelete = useCallback(() => {
        confirmAlert({
            title: 'Удаление',
            message: 'Вы уверены, что хотите удалить эту запись?',
            buttons: [
                {
                    label: 'Да',
                    onClick: async () => {
                        await localSpinnerRunner(
                            deleteMemberCard(props.item.id),
                            LocalSpinnerKeysEnum.membersList,
                        );
                        localSpinnerRunner(
                            updateMembers(props.queryParams, props.bodyParams),
                            LocalSpinnerKeysEnum.membersList,
                        );
                    },
                },
                {
                    label: 'Нет',
                    onClick: () => null,
                },
            ],
        });
    }, [confirmAlert, props.item.id, props.queryParams, props.bodyParams]);
    const openCreateFamilyModalCallback = useCallback(
        () => setOpenCreateFamilyModal(true),
        [setOpenCreateFamilyModal],
    );
    const openPastorNoteModalCallback = useCallback(
        () => setOpenPastorNoteModal(true),
        [setOpenPastorNoteModal],
    );
    const closeCreateFamilyModalCallback = useCallback(
        (withUpdate?: boolean) => {
            setOpenCreateFamilyModal(false);
            if (withUpdate) {
                localSpinnerRunner(
                    updateMembers(props.queryParams, props.bodyParams),
                    LocalSpinnerKeysEnum.membersList,
                );
            }
        },
        [
            setOpenCreateFamilyModal,
            props.bodyParams,
            props.queryParams,
            updateMembers,
            localSpinnerRunner,
        ],
    );
    const closePastorNoteModalCallback = useCallback(
        (withUpdate?: boolean) => {
            setOpenPastorNoteModal(false);
            if (withUpdate) {
                localSpinnerRunner(
                    updateMembers(props.queryParams, props.bodyParams),
                    LocalSpinnerKeysEnum.membersList,
                );
            }
        },
        [
            setOpenPastorNoteModal,
            props.bodyParams,
            props.queryParams,
            updateMembers,
            localSpinnerRunner,
        ],
    );
    const showForLeader = useMemo(() => AuthService.availableForLeader(), [AuthService.availableForLeader]);
    const showForAdmin = useMemo(() => AuthService.availableForAdmin(), [AuthService.availableForAdmin]);
    const showForPastor = useMemo(() => AuthService.hasPastorPermission(), [AuthService.hasPastorPermission]);
    return (
        <TableRow hover role="checkbox" tabIndex={-1} onDoubleClick={goToCard}>
            <TableCell align="left">
                <LinkA href={`#/member/${props.item.id}`}>
                    {props.item.lastName}
                </LinkA>
            </TableCell>
            <TableCell align="left">
                <LinkA href={`#/member/${props.item.id}`}>
                    {props.item.firstName}
                </LinkA>
            </TableCell>
            <TableCell align="left">{props.item.phone}</TableCell>
            {(!!showForAdmin || !!showForPastor) && <TableCell align="left">
                <LabelWrap>
                    <RedLabel className={classNames({
                        'gray': props.item.status.value !== MemberStatus.EXCOMMUNICATED.value
                    })}>{props.item.status.label}</RedLabel>
                    {
                        !!props.item.outsideGroups &&
                        <RedLabel>Вне малой группы</RedLabel>
                    }
                    {
                        !!props.item.outsideMinistries &&
                        <RedLabel>Вне служения</RedLabel>
                    }
                    {
                        !!props.item.confirmationIsFalse &&
                        <RedLabel>Не подтвердил членство</RedLabel>
                    }
                    {
                        (
                            props.item.status.value === MemberStatus.RBC.value || props.item.status.value === MemberStatus.ON_REMARK.value
                        ) && !props.item.donatedRecently &&
                        <RedLabel>Проблемы с финансами</RedLabel>
                    }
                    {
                        !!props.item.rbcBaptism &&
                        <GreenLabel>Крещён в РБЦ</GreenLabel>
                    }
                </LabelWrap>
            </TableCell>}
            {!!showForLeader && <TableCell align="left">
                {props.item.family && (
                    <>
                        <a href={`#/family/${props.item.family.id}`}>
                            {props.item.family.name}
                        </a>
                    </>
                )}
                {!props.item.family && !!showForAdmin && (
                    <AddIcon
                        className="min-action-icon"
                        onClick={openCreateFamilyModalCallback}
                    />
                )}
            </TableCell>}
            {(!!showForAdmin || !!showForPastor) && <TableCell align="left">
                <NoteIcon onClick={openPastorNoteModalCallback} className={classNames('action-icon', {
                    green: !!props.item.pastorNote,
                })} />
                {
                    !!showForAdmin &&
                    <>
                        <EditIcon onClick={goToEdit} className="action-icon" />
                        <DeleteIcon onClick={goToDelete} className="action-icon" />
                    </>
                }
            </TableCell>}
            {!!openCreateFamilyModal && (
                <CreateFamilyModal
                    member={props.item}
                    onClose={closeCreateFamilyModalCallback}
                />
            )}
            {!!openPastorNoteModal && (
                <PastorNoteModal
                    member={props.item}
                    onClose={closePastorNoteModalCallback}
                />
            )}
        </TableRow>
    );
};

const MembersList = () => {
    const [openSendListModal, setOpenSendListModal] = useState<boolean>(false);
    const showForLeader = useMemo(() => AuthService.availableForLeader(), [AuthService.availableForLeader]);
    const showForAdmin = useMemo(() => AuthService.availableForAdmin(), [AuthService.availableForAdmin]);
    const showForPastor = useMemo(() => AuthService.hasPastorPermission(), [AuthService.hasPastorPermission]);
    const dispatch = useDispatch();

    const pageNo = useSelector(getLocationSearchParamByKey('pageNo'));
    const pageSize = useSelector(getLocationSearchParamByKey('pageSize'));
    const searchString = useSelector(getLocationSearchParamByKey('searchString'));
    const filterStatus = useSelector(getLocationSearchParamByKey('status'));
    const filterFamilyStatus = useSelector(getLocationSearchParamByKey('familyStatus'));
    const filterSex = useSelector(getLocationSearchParamByKey('sex'));
    const filterBirthdayFrom = useSelector(getLocationSearchParamByKey('birthdayFrom'));
    const filterBirthdayTo = useSelector(getLocationSearchParamByKey('birthdayTo'));
    const filterAdoptedFrom = useSelector(getLocationSearchParamByKey('adoptedFrom'));
    const filterAdoptedTo = useSelector(getLocationSearchParamByKey('adoptedTo'));
    const filterMembershipConfirmation = useSelector(getLocationSearchParamByKey('membershipConfirmation'));
    const filterCompleteCourse = useSelector(getLocationSearchParamByKey('completeCourse'));
    const filterHasNotAvatar = useSelector(getLocationSearchParamByKey('hasNotAvatar'));
    const filterPastorList = useSelector(getLocationSearchParamByKey('pastorList'));
    const filterMembersFilter = useSelector(getLocationSearchParamByKey('membersFilter'));
    const filterRbcBaptism = useSelector(getLocationSearchParamByKey('rbcBaptism'));
    const debounceSearchInputText = useDebounce(searchString);
    const membersList = useSelector(getMembersList);
    const membersTotalCount = useSelector(getMembersTotalCount);
    const localSpinnerRunner = useLocalSpinner(dispatch);
    const isShowSpinner = useSelector(
        makeGetIsShowLocalSpinner(LocalSpinnerKeysEnum.membersList),
    );

    const [showMembersFilters, setShowMembersFilters] = useState(!!filterMembersFilter);

    const queryParams: GetMembersListQueryParams = useMemo(
        () => ({
            pageNo: pageNo ? Number(pageNo) : 0,
            pageSize: pageSize ? Number(pageSize) : 10,
        }),
        [pageNo, pageSize],
    );

    const bodyParams: GetMembersListBodyParams = useMemo(
        () => {
            const filters: GetMembersListBodyParams = {
                searchString: debounceSearchInputText || '',
                statuses: showForLeader ? (filterStatus ? [filterStatus] : []) : [MemberStatus.RBC.value],
                hasNotAvatar: ((filterHasNotAvatar === null) || (filterHasNotAvatar === undefined)) ? undefined : filterHasNotAvatar === 'true',
                rbcBaptism: ((filterRbcBaptism === null) || (filterRbcBaptism === undefined)) ? undefined : filterRbcBaptism === 'true',
                membershipConfirmation: filterMembershipConfirmation,
                married: ((filterFamilyStatus === null) || (filterFamilyStatus === undefined)) ? undefined : filterFamilyStatus === 'MARRIED',
                sex: filterSex,
                birthdayFrom: filterBirthdayFrom,
                birthdayTo: filterBirthdayTo,
                adoptedFrom: filterAdoptedFrom,
                adoptedTo: filterAdoptedTo,
            };

            if (filterCompleteCourse === CompleteCourse.COMPLETE_AU.value || filterCompleteCourse === CompleteCourse.NOT_COMPLETE_AU.value) {
                filters.statuses = [MemberStatus.RBC.value, MemberStatus.ON_REMARK.value];
                filters.completeAu = filterCompleteCourse;
            }

            if (filterPastorList === PastorList.OUTSIDE_GROUP.value) {
                filters.statuses = [MemberStatus.RBC.value, MemberStatus.ON_REMARK.value];
                filters.outsideGroups = true;
            }

            if (filterPastorList === PastorList.OUTSIDE_MINISTRY.value) {
                filters.statuses = [MemberStatus.RBC.value, MemberStatus.ON_REMARK.value];
                filters.outsideMinistries = true;
            }

            if (filterPastorList === PastorList.EXCOMMUNICATED.value) {
                filters.statuses = [MemberStatus.EXCOMMUNICATED.value];
            }

            if (filterPastorList === PastorList.NOT_CONFIRM_MEMBERSHIP.value) {
                filters.statuses = [MemberStatus.RBC.value, MemberStatus.ON_REMARK.value];
                filters.membershipConfirmation = MemberConfirmation.NOT_CONFIRM.value;
            }

            if (filterPastorList === PastorList.TEMPORARY_SUSPENSION_MEMBERSHIP.value) {
                filters.statuses = [MemberStatus.TEMP_DROPPED.value];
            }

            if (filterPastorList === PastorList.NOT_DONATE_RECENTLY.value) {
                filters.statuses = [MemberStatus.RBC.value, MemberStatus.ON_REMARK.value];
                filters.notDonateRecently = true;
            }

            if (filterPastorList === PastorList.MEMBERS_FILTER.value) {
                filters.membersFilter = filterMembersFilter;
            }

            return filters;
        },
        [
            filterBirthdayFrom,
            filterBirthdayTo,
            filterAdoptedFrom,
            filterAdoptedTo,
            debounceSearchInputText,
            filterRbcBaptism,
            filterStatus,
            filterHasNotAvatar,
            filterPastorList,
            filterMembersFilter,
            filterMembershipConfirmation,
            filterCompleteCourse,
            filterFamilyStatus,
            filterSex,
        ],
    );
    const statusOptions = useMemo(() => Object.values(MemberStatus), []);
    const familyStatusOptions = useMemo(() => Object.values(MemberFamilyStatus), []);
    const sexOptions = useMemo(() => Object.values(MemberSex), []);

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    useEffect(() => {
        localSpinnerRunner(
            updateMembers(queryParams, bodyParams),
            LocalSpinnerKeysEnum.membersList,
        );
    }, [queryParams, bodyParams]);

    const pageNoValue = useMemo(
        () => (membersTotalCount ? (pageNo === null ? 0 : Number(pageNo)) : 0),
        [pageNo, membersTotalCount],
    );

    const filterStatusValue = useMemo(
        () =>
            !!filterStatus && !!MemberStatus[filterStatus]
                ? MemberStatus[filterStatus]
                : null,
        [filterStatus],
    );

    const filterFamilyStatusValue = useMemo(
        () =>
            !!filterFamilyStatus && !!MemberFamilyStatus[filterFamilyStatus]
                ? MemberFamilyStatus[filterFamilyStatus]
                : null,
        [filterFamilyStatus],
    );

    const filterSexValue = useMemo(
        () =>
            !!filterSex && !!MemberSex[filterSex]
                ? MemberSex[filterSex]
                : null,
        [filterSex],
    );

    const filterBirthdayFromValue = useMemo(
        () =>
            filterBirthdayFrom ? new Date(filterBirthdayFrom) : null,
        [filterBirthdayFrom],
    );

    const filterBirthdayToValue = useMemo(
        () =>
            filterBirthdayTo ? new Date(filterBirthdayTo) : null,
        [filterBirthdayTo],
    );

    const filterAdoptedFromValue = useMemo(
        () =>
            filterAdoptedFrom ? new Date(filterAdoptedFrom) : null,
        [filterAdoptedFrom],
    );

    const filterAdoptedToValue = useMemo(
        () =>
            filterAdoptedTo ? new Date(filterAdoptedTo) : null,
        [filterAdoptedTo],
    );

    const filterMembershipConfirmationValue = useMemo(
        () =>
            !!filterMembershipConfirmation && !!MemberConfirmation[filterMembershipConfirmation]
                ? MemberConfirmation[filterMembershipConfirmation]
                : null,
        [filterMembershipConfirmation],
    );

    const filterCompleteCourseValue = useMemo(
        () =>
            !!filterCompleteCourse && !!CompleteCourse[filterCompleteCourse]
                ? CompleteCourse[filterCompleteCourse]
                : null,
        [filterCompleteCourse],
    );

    const pageSizeValue = useMemo(
        () =>
            membersTotalCount ? (pageSize === null ? 10 : Number(pageSize)) : 0,
        [pageSize, membersTotalCount],
    );
    const handleChangePage = useCallback(
        (
            event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
            page: number,
        ) => {
            addParamsToUrl([
                {
                    key: 'pageNo',
                    value: page,
                },
            ]);
        },
        [addParamsToUrl],
    );
    const handleChangeRowsPerPage = useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            addParamsToUrl([
                {
                    key: 'pageNo',
                    value: 0,
                },
                {
                    key: 'pageSize',
                    value: event.target.value,
                },
            ]);
        },
        [addParamsToUrl],
    );
    const goToCreate = useCallback(() => {
        goToState('/member/create');
    }, []);

    const updateSearchString = useCallback(
        (value: string | null) => {
            addParamsToUrl([
                {
                    key: 'searchString',
                    value,
                },
            ]);
        },
        [addParamsToUrl],
    );

    const updateStatus = useCallback(
        (value: MemberStatusItem | null) => {
            addParamsToUrl([
                {
                    key: 'status',
                    value: value?.value || undefined,
                },
            ]);
        },
        [addParamsToUrl],
    );

    const updateFamilyStatus = useCallback(
        (value: MemberFamilyStatusItem | null) => {
            addParamsToUrl([
                {
                    key: 'familyStatus',
                    value: value?.value || undefined,
                },
            ]);
        },
        [addParamsToUrl],
    );

    const updateSex = useCallback(
        (value: MemberSexItem | null) => {
            addParamsToUrl([
                {
                    key: 'sex',
                    value: value?.value || undefined,
                },
            ]);
        },
        [addParamsToUrl],
    );

    const updateBirthdayFrom = useCallback((event: FormEvent<(Date | null)>) => {
        addParamsToUrl([
            {
                key: 'birthdayFrom',
                value: event.value ? parseDateForApi(event.value) : null,
            }
        ]);
    }, [addParamsToUrl]);

    const updateBirthdayTo = useCallback((event: FormEvent<(Date | null)>) => {
        addParamsToUrl([
            {
                key: 'birthdayTo',
                value: event.value ? parseDateForApi(event.value) : null,
            }
        ]);
    }, [addParamsToUrl]);

    const updateAdoptedFrom = useCallback((event: FormEvent<(Date | null)>) => {
        addParamsToUrl([
            {
                key: 'adoptedFrom',
                value: event.value ? parseDateForApi(event.value) : null,
            }
        ]);
    }, [addParamsToUrl]);

    const updateAdoptedTo = useCallback((event: FormEvent<(Date | null)>) => {
        addParamsToUrl([
            {
                key: 'adoptedTo',
                value: event.value ? parseDateForApi(event.value) : null,
            }
        ]);
    }, [addParamsToUrl]);

    const updateMembershipConfirmation = useCallback(
        (value: MemberConfirmationItem | null) => {
            addParamsToUrl([
                {
                    key: 'membershipConfirmation',
                    value: value?.value || undefined,
                },
            ]);
        },
        [addParamsToUrl],
    );

    const updateCompleteCourse = useCallback(
        (value: MemberConfirmationItem | null) => {
            addParamsToUrl([
                {
                    key: 'completeCourse',
                    value: value?.value || undefined,
                },
            ]);
        },
        [addParamsToUrl],
    );

    const updatePastorList = useCallback(
        (event: LocalAnyType, value: LocalAnyType) => {
            setShowMembersFilters(value === PastorList.MEMBERS_FILTER.value);
            addParamsToUrl([
                {
                    key: 'pastorList',
                    value: value || undefined,
                },
                {
                    key: 'membersFilter',
                    value: value === PastorList.MEMBERS_FILTER.value ? PastorList.QUEUE_FOR_INTERVIEW.value : undefined,
                },
                {
                    key: 'pageNo',
                    value: 0,
                },
                {
                    key: 'pageSize',
                    value: 10,
                },
            ]);
        },
        [addParamsToUrl],
    );

    const updateMembersFilter = useCallback(
        (event: LocalAnyType, value: LocalAnyType) => {
            setShowMembersFilters(!!value);
            addParamsToUrl([
                {
                    key: 'pastorList',
                    value: value ? PastorList.MEMBERS_FILTER.value : undefined,
                },
                {
                    key: 'membersFilter',
                    value: value || undefined,
                },
                {
                    key: 'pageNo',
                    value: 0,
                },
                {
                    key: 'pageSize',
                    value: 10,
                },
            ]);
        },
        [addParamsToUrl],
    );

    const hasNotAvatarValue = useMemo(() => filterHasNotAvatar === 'true', [filterHasNotAvatar]);

    const toggleHasNotAvatar = useCallback(
        () => {
            addParamsToUrl([
                {
                    key: 'hasNotAvatar',
                    value: filterHasNotAvatar ? undefined : 'true',
                },
            ]);
        },
        [addParamsToUrl, filterHasNotAvatar],
    );

    const hasRbcBaptismValue = useMemo(() => filterRbcBaptism === 'true', [filterRbcBaptism]);

    const toggleRbcBaptism = useCallback(
        () => {
            addParamsToUrl([
                {
                    key: 'rbcBaptism',
                    value: filterRbcBaptism ? undefined : 'true',
                },
            ]);
        },
        [addParamsToUrl, filterRbcBaptism],
    );

    const clearFilters = useCallback(() => {
        addParamsToUrl([
            {
                key: 'searchString',
                value: undefined,
            },
            {
                key: 'status',
                value: undefined,
            },
            {
                key: 'membershipConfirmation',
                value: undefined,
            },
            {
                key: 'completeCourse',
                value: undefined,
            },
            {
                key: 'hasNotAvatar',
                value: undefined,
            },
            {
                key: 'birthdayFrom',
                value: undefined,
            },
            {
                key: 'adoptedFrom',
                value: undefined,
            },
            {
                key: 'birthdayTo',
                value: undefined,
            },
            {
                key: 'adoptedFrom',
                value: undefined,
            },
            {
                key: 'adoptedTo',
                value: undefined,
            },
            {
                key: 'familyStatus',
                value: undefined,
            },
            {
                key: 'sex',
                value: undefined,
            },
            {
                key: 'rbcBaptism',
                value: undefined,
            },
            {
                key: 'pageNo',
                value: 0,
            },
            {
                key: 'pageSize',
                value: 10,
            },
        ]);
    }, [addParamsToUrl]);

    const openCreateSendListModal = useCallback(() => {
        setOpenSendListModal(true);
    }, []);

    const closeCreateSendListModal = useCallback(() => {
        setOpenSendListModal(false);
    }, []);

    return (
        <Root>
            <Header>
                {!!showForAdmin && <>
                    <Button title="Создать" onClick={goToCreate} />
                    <Button title="Рассылка для выбранных профилей" onClick={openCreateSendListModal} />
                </>}
            </Header>
            {
                (!!showForAdmin || !!showForPastor) &&
                <TabWrap>
                    <Tabs
                        value={filterPastorList}
                        onChange={updatePastorList}
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                    >
                        <Tab label="Весь список" value={null} />
                        <Tab label={PastorList.MEMBERS_FILTER.label} value={PastorList.MEMBERS_FILTER.value} />
                        <Tab label={PastorList.OUTSIDE_GROUP.label} value={PastorList.OUTSIDE_GROUP.value} />
                        <Tab label={PastorList.OUTSIDE_MINISTRY.label} value={PastorList.OUTSIDE_MINISTRY.value} />
                        <Tab label={PastorList.EXCOMMUNICATED.label} value={PastorList.EXCOMMUNICATED.value} />
                        <Tab label={PastorList.NOT_CONFIRM_MEMBERSHIP.label} value={PastorList.NOT_CONFIRM_MEMBERSHIP.value} />
                        <Tab label={PastorList.TEMPORARY_SUSPENSION_MEMBERSHIP.label} value={PastorList.TEMPORARY_SUSPENSION_MEMBERSHIP.value} />
                        <Tab label={PastorList.NOT_DONATE_RECENTLY.label} value={PastorList.NOT_DONATE_RECENTLY.value} />
                    </Tabs>
                </TabWrap>
            }
            {
                (!!showForAdmin || !!showForPastor) && !!showMembersFilters &&
                <TabWrap>
                    <Tabs
                        value={filterMembersFilter}
                        onChange={updateMembersFilter}
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                    >
                        <Tab label={PastorList.QUEUE_FOR_INTERVIEW.label} value={PastorList.QUEUE_FOR_INTERVIEW.value} />
                        <Tab label={PastorList.SECOND_INTERVIEW.label} value={PastorList.SECOND_INTERVIEW.value} />
                        <Tab label={PastorList.READY_FOR_TESTIMONY.label} value={PastorList.READY_FOR_TESTIMONY.value} />
                        <Tab label={PastorList.READY_FOR_BAPTISM.label} value={PastorList.READY_FOR_BAPTISM.value} />
                    </Tabs>
                </TabWrap>
            }
            <FilterOverflowWrap>
                <FilterPanel>
                    <InfoItem
                        className="filter-item"
                        title="Поиск"
                        value={
                            <TextInput
                                value={searchString}
                                onChange={updateSearchString}
                            />
                        }
                    />
                    {
                        !!showForAdmin && !filterPastorList &&
                        <>
                            <SimpleSelect
                                className="filter-item"
                                label="Статус"
                                options={statusOptions}
                                value={filterStatusValue}
                                onChange={updateStatus}
                            />
                            <SimpleSelect
                                className="filter-item"
                                label="Семейное положение"
                                options={familyStatusOptions}
                                value={filterFamilyStatusValue}
                                onChange={updateFamilyStatus}
                            />
                            <SimpleSelect
                                className="filter-item"
                                label="Пол"
                                options={sexOptions}
                                value={filterSexValue}
                                onChange={updateSex}
                            />
                            <SimpleSelect
                                className="filter-item"
                                label="Подтверждение членства"
                                options={confirmationOptions}
                                value={filterMembershipConfirmationValue}
                                onChange={updateMembershipConfirmation}
                            />
                            <SimpleSelect
                                className="filter-item"
                                label="Курсы"
                                options={completeCourseOptions}
                                value={filterCompleteCourseValue}
                                onChange={updateCompleteCourse}
                            />
                            <Button className="local-button" onClick={handleOpen} title="Фильтры по датам"></Button>
                            <Modal
                                isOpen={open}
                                onRequestClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description">
                                <ModalWrap>
                                    <Calendar
                                        showButtonBar
                                        placeholder="Дата рождения, от"
                                        locale={'ru'}
                                        className="local-datapicker"
                                        value={filterBirthdayFromValue}
                                        onChange={updateBirthdayFrom}
                                        dateFormat={'dd.mm.yy'}
                                        headerTemplate={() => (
                                            <span style={{ fontWeight: 'bold' }}>Дата рождения, от</span>
                                        )}
                                    ></Calendar>
                                    <Calendar
                                        showButtonBar
                                        placeholder="Дата рождения, до"
                                        locale={'ru'}
                                        className="local-datapicker"
                                        value={filterBirthdayToValue}
                                        onChange={updateBirthdayTo}
                                        dateFormat={'dd.mm.yy'}
                                        headerTemplate={() => (
                                            <span style={{ fontWeight: 'bold' }}>Дата рождения, до</span>
                                        )}
                                    ></Calendar>
                                    <Calendar
                                        showButtonBar
                                        placeholder="Принятие в церковь, от"
                                        locale={'ru'}
                                        className="local-datapicker"
                                        value={filterAdoptedFromValue}
                                        onChange={updateAdoptedFrom}
                                        dateFormat={'dd.mm.yy'}
                                        headerTemplate={() => (
                                            <span style={{ fontWeight: 'bold' }}>Принятие в церковь, от</span>
                                        )}
                                    ></Calendar>
                                    <Calendar
                                        showButtonBar
                                        placeholder="Принятие в церковь, до"
                                        locale={'ru'}
                                        className="local-datapicker"
                                        value={filterAdoptedToValue}
                                        onChange={updateAdoptedTo}
                                        dateFormat={'dd.mm.yy'}
                                        headerTemplate={() => (
                                            <span style={{ fontWeight: 'bold' }}>Принятие в церковь, до</span>
                                        )}
                                    ></Calendar>
                                </ModalWrap>
                            </Modal>
                            <FormControlLabel
                                className="filter-item filter-item-checkbox"
                                control={
                                    <Checkbox
                                        checked={hasRbcBaptismValue}
                                        onClick={toggleRbcBaptism}
                                    />
                                }
                                label="Крещён в РБЦ"
                            />
                            <FormControlLabel
                                className="filter-item filter-item-checkbox"
                                control={
                                    <Checkbox
                                        checked={hasNotAvatarValue}
                                        onClick={toggleHasNotAvatar}
                                    />
                                }
                                label="Без фото"
                            />
                        </>
                    }
                    <Button
                        className="clear-filter-button"
                        title="Сбросить фильтры"
                        onClick={clearFilters}
                    />
                </FilterPanel>
            </FilterOverflowWrap>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: '700px' }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Фамилия</TableCell>
                                <TableCell align="left">Имя</TableCell>
                                <TableCell align="left">Телефон</TableCell>
                                {!!showForAdmin && <TableCell align="left">Статус</TableCell>}
                                {!!showForLeader && <TableCell align="left">Семья</TableCell>}
                                {!!showForAdmin && <TableCell align="left" />}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {membersList.map((member, index) => (
                                <MemberItem
                                    item={member}
                                    key={index}
                                    queryParams={queryParams}
                                    bodyParams={bodyParams}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[3, 10, 25, 100]}
                    component="div"
                    count={membersTotalCount}
                    rowsPerPage={pageSizeValue}
                    page={pageNoValue}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Записей на странице"
                />
            </Paper>
            <ClipLoader loading={isShowSpinner} size={30} css={LocalSpinner} />
            <BottomPadding />
            {!!openSendListModal && (
                <SendListModal
                    bodyParams={bodyParams}
                    onClose={closeCreateSendListModal}
                />
            )}
        </Root>
    );
};

export default MembersList;
