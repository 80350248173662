import { BaseActionResult } from '../../helpers/actions';

export class GlobalState {
    activeKeys: string[] = [];
    isShowGlobalSpinner: boolean = false;
    displayedLocalSpinners: string[] = [];
    locationSearch: string = '';
    isOpenMenu: boolean = false;
}

export enum GlobalActionsEnum {
    empty = 'GLOBAL_EMPTY',
    keydown = 'GLOBAL_KEY_DOWN',
    keyup = 'GLOBAL_KEY_UP',
    toggleGlobalSpinner = 'GLOBAL_TOGGLE_GLOBAL_SPINNER',
    addDisplayedLocalSpinner = 'GLOBAL_ADD_DISPLAYED_LOCAL_SPINNER',
    removeDisplayedLocalSpinner = 'GLOBAL_REMOVE_DISPLAYED_LOCAL_SPINNER',
    updateLocationSearch = 'GLOBAL_UPDATE_LOCATION_SEARCH',
    setIsOpenMenu = 'SET_IS_OPEN_MENU',
}

export type GlobalActions =
    BaseActionResult<boolean, typeof GlobalActionsEnum.toggleGlobalSpinner>
    | BaseActionResult<string[], typeof GlobalActionsEnum.addDisplayedLocalSpinner>
    | BaseActionResult<string[], typeof GlobalActionsEnum.removeDisplayedLocalSpinner>
    | BaseActionResult<string, typeof GlobalActionsEnum.updateLocationSearch>
    | BaseActionResult<string, typeof GlobalActionsEnum.empty>
    | BaseActionResult<string[], typeof GlobalActionsEnum.keydown>
    | BaseActionResult<boolean, typeof GlobalActionsEnum.setIsOpenMenu>
    | BaseActionResult<string[], typeof GlobalActionsEnum.keyup>;
