import React, { useCallback, useEffect, useMemo } from 'react';
import { ClipLoader } from 'react-spinners';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import {
    addParamsToUrl,
    goToState,
} from '../../../../../global/helpers/url-parse.helper';
import { Root, FilterPanel, RunButton, Header, ActionWrap } from './ov-cohort-list.styles';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { confirmAlert } from 'react-confirm-alert';
import {
    getLocationSearchParamByKey,
    makeGetIsShowLocalSpinner,
} from '../../../../../global/redux/global/global.selectors';
import { useLocalSpinner } from '../../../../../global/hooks/use-local-spinner';
import { LocalSpinnerKeysEnum } from '../../../../../global/constants';
import {
    BottomPadding,
    LocalSpinner,
} from '../../../../../global/components/main-wrap/main-wrap.styles';
import { Button } from '../../../../../global/components/button';
import { useDebounce } from '../../../../../global/hooks/use-debounce';
import { InfoItem } from '../../../../../global/components/info-item';
import { TextInput } from '../../../../../global/components/form-controls/text-input/text-input';
import { AuthService } from '../../../../../auth/services/AuthService';
import { LinkA } from '../../../../../global/theme/global.styles';
import { getOVCohortList, getOVCohortTotalCount } from '../../../redux/cohort/ov-cohort.selectors';
import { GetOVCohortListQueryParams } from '../../../types/cohort/get-ov-cohort-list-query-params';
import { GetOVCohortListBodyParams } from '../../../types/cohort/get-ov-cohort-list-body-params';
import { deleteOVCohortCard, runOVCohort, updateOVCohorts } from '../../../redux/cohort/ov-cohort.actions';
import { OVCohort } from '../../../models/cohort/ov-cohort.model';
import { LabelWrap } from '../../../../members/pages/list/members-list.styles';
import { TabBar } from '../../../../../global/components/tab-bar/tab-bar';
import { useOVTabs } from '../../../hooks/use-ov-tabs';
import CreateSendListFromCSV from '../../../../send-list/components/create-send-list-from-csv/create-send-list-from-csv';

type OVCohortItemProps = {
    item: OVCohort;
    queryParams: GetOVCohortListQueryParams;
    bodyParams: GetOVCohortListBodyParams;
};

const OVCohortItem = (props: OVCohortItemProps) => {
    const dispatch = useDispatch();
    const localSpinnerRunner = useLocalSpinner(dispatch);

    const runCallback = useCallback(() => {
        confirmAlert({
            title: 'Запуск когорты',
            message: 'Все не принятые регистрации в текущей запущенной когорте будут перенесены в эту когорту?',
            buttons: [
                {
                    label: 'Запустить',
                    onClick: async () => {
                        await localSpinnerRunner(
                            runOVCohort(props.item.id),
                            LocalSpinnerKeysEnum.ovCohortList,
                        );
                        localSpinnerRunner(
                            updateOVCohorts(props.queryParams, props.bodyParams),
                            LocalSpinnerKeysEnum.ovCohortList,
                        );
                    },
                },
                {
                    label: 'Отмена',
                    onClick: () => null,
                },
            ],
        });
    }, [confirmAlert, props.item.id, props.queryParams, props.bodyParams]);

    const goToDelete = useCallback(() => {
        confirmAlert({
            title: 'Удаление',
            message: 'Вы уверены, что хотите удалить эту когорту?',
            buttons: [
                {
                    label: 'Да',
                    onClick: async () => {
                        await localSpinnerRunner(deleteOVCohortCard(props.item.id), LocalSpinnerKeysEnum.ovCohortList);
                        localSpinnerRunner(updateOVCohorts(props.queryParams, props.bodyParams), LocalSpinnerKeysEnum.ovCohortList);
                    },
                },
                {
                    label: 'Нет',
                    onClick: () => null,
                },
            ],
        });
    }, [confirmAlert, props.item.id, props.queryParams, props.bodyParams]);

    const goToEdit = useCallback(() => {
        goToState(`/ov/cohort/${props.item.id}/edit`);
    }, [props.item.id]);

    const goToCard = useCallback(() => {
        goToState(`/ov/cohort/${props.item.id}`);
    }, [props.item.id]);

    return (
        <TableRow hover role="checkbox" tabIndex={-1} onDoubleClick={goToCard}>
            <TableCell align="left">
                {props.item.id}
            </TableCell>
            <TableCell align="left">
                <LinkA href={`#/ov/cohort/${props.item.id}`}>
                    {props.item.title}
                </LinkA>
            </TableCell>
            <TableCell align="left">
                <ActionWrap>
                    {
                        props.item.isRegistrationOpen
                            ? 'Активная когорта'
                            : <LabelWrap>
                                <RunButton onClick={runCallback}>
                                    Запустить
                                </RunButton>
                            </LabelWrap>
                    }
                    <EditIcon onClick={goToEdit} className="action-icon" />
                    {
                        !props.item.isRegistrationOpen &&
                        <DeleteIcon onClick={goToDelete} className="action-icon" />
                    }
                </ActionWrap>
            </TableCell>
        </TableRow>
    );
};

const OVCohortList = () => {
    const availablePage = useMemo(
        () => AuthService.availableForAdmin() || AuthService.hasOVPermission(),
        [],
    );
    const { onTabClick, tabs } = useOVTabs('1');
    const dispatch = useDispatch();
    const pageNo = useSelector(getLocationSearchParamByKey('pageNo'));
    const pageSize = useSelector(getLocationSearchParamByKey('pageSize'));
    const ovCohortList = useSelector(getOVCohortList);
    const ovCohortTotalCount = useSelector(getOVCohortTotalCount);
    const localSpinnerRunner = useLocalSpinner(dispatch);
    const isShowSpinner = useSelector(
        makeGetIsShowLocalSpinner(LocalSpinnerKeysEnum.ovCohortList),
    );
    const searchString = useSelector(
        getLocationSearchParamByKey('searchString'),
    );
    const debounceSearchInputText = useDebounce(searchString);

    const queryParams: GetOVCohortListQueryParams = useMemo(
        () => ({
            pageNo: pageNo ? Number(pageNo) : 0,
            pageSize: pageSize ? Number(pageSize) : 10,
        }),
        [pageNo, pageSize],
    );

    const bodyParams: GetOVCohortListBodyParams = useMemo(
        () => ({
            searchString: debounceSearchInputText || '',
        }),
        [debounceSearchInputText],
    );

    useEffect(() => {
        if (!availablePage) {
            goToState('/');
            return;
        }
    }, []);

    useEffect(() => {
        localSpinnerRunner(
            updateOVCohorts(queryParams, bodyParams),
            LocalSpinnerKeysEnum.ovCohortList,
        );
    }, [queryParams, bodyParams]);

    const pageNoValue = useMemo(
        () => (ovCohortTotalCount ? (pageNo === null ? 0 : Number(pageNo)) : 0),
        [pageNo, ovCohortTotalCount],
    );
    const pageSizeValue = useMemo(
        () =>
            ovCohortTotalCount
                ? pageSize === null
                    ? 10
                    : Number(pageSize)
                : 0,
        [pageSize, ovCohortTotalCount],
    );
    const handleChangePage = useCallback(
        (
            event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
            page: number,
        ) => {
            addParamsToUrl([
                {
                    key: 'pageNo',
                    value: page,
                },
            ]);
        },
        [addParamsToUrl],
    );
    const handleChangeRowsPerPage = useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            addParamsToUrl([
                {
                    key: 'pageNo',
                    value: 0,
                },
                {
                    key: 'pageSize',
                    value: event.target.value,
                },
            ]);
        },
        [addParamsToUrl],
    );

    const updateSearchString = useCallback(
        (value: string | null) => {
            addParamsToUrl([
                {
                    key: 'searchString',
                    value,
                },
            ]);
        },
        [addParamsToUrl],
    );

    const clearFilters = useCallback(() => {
        addParamsToUrl([
            {
                key: 'searchString',
                value: undefined,
            },
            {
                key: 'pageNo',
                value: 0,
            },
            {
                key: 'pageSize',
                value: 10,
            },
        ]);
    }, [addParamsToUrl]);

    const goToCreate = useCallback(() => {
        goToState('/ov/cohort/create');
    }, []);

    return (
        <>
            <TabBar
                tabs={tabs}
                onTabClick={onTabClick}
            />
            <Root>
                <Header>
                    <Button title="Создать" onClick={goToCreate} />
                    <CreateSendListFromCSV />
                </Header>
                <FilterPanel>
                    <InfoItem
                        className="filter-item"
                        title="Поиск"
                        value={
                            <TextInput
                                value={searchString}
                                onChange={updateSearchString}
                            />
                        }
                    />
                    <Button
                        className="clear-filter-button"
                        title="Сбросить фильтры"
                        onClick={clearFilters}
                    />
                </FilterPanel>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: '700px' }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Id</TableCell>
                                    <TableCell align="left">Название</TableCell>
                                    <TableCell align="left" />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {ovCohortList.map((cohort, index) => (
                                    <OVCohortItem
                                        item={cohort}
                                        key={index}
                                        queryParams={queryParams}
                                        bodyParams={bodyParams}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[3, 10, 25, 100]}
                        component="div"
                        count={ovCohortTotalCount}
                        rowsPerPage={pageSizeValue}
                        page={pageNoValue}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="Записей на странице"
                    />
                </Paper>
                <ClipLoader loading={isShowSpinner} size={30} css={LocalSpinner} />
                <BottomPadding />
            </Root>
        </>
    );
};

export default OVCohortList;
