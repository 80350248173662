import { EntityForSelect } from '../../../global/components/multiple-select-entity/multiple-select-entity';
import { GroupList } from '../models/group-list.model';
import { GroupsRepository } from '../repositories/groups.repository';

export const loadGroupsForSelect = async (searchString: string): Promise<EntityForSelect[]> => {
    const result = await GroupsRepository.getList({
        pageNo: 0,
        pageSize: 10,
    }, {
        searchString,
    });
    return parseGroupsListToEntityForSelect(result.items);
};

export const parseGroupItemToEntityForSelect = (group: GroupList): EntityForSelect => ({
    id: group.id,
    name: group.name,
});

export const parseGroupsListToEntityForSelect = (members: GroupList[]): EntityForSelect[] => members.map(parseGroupItemToEntityForSelect);
