import { BaseActionResult } from '../../../global/helpers/actions';
import { MinistryCard } from '../models/ministry-card.model';
import { MinistryList } from '../models/ministry-list.model';

export class MinistriesState {
    ministries: MinistryList[] = [];
    totalCount: number = 0;
    ministryCard: MinistryCard | null = null;
}

export type UpdateMinistriesAction = {
    ministries: MinistryList[];
    totalCount: number;
}

export enum MinistriesActionsEnum {
    updateMinistries = 'UPDATE_MINISTRIES',
    updateMinistryCard = 'UPDATE_MINISTRY_CARD',
}

export type MinistriesActions =
    BaseActionResult<
          UpdateMinistriesAction,
          typeof MinistriesActionsEnum.updateMinistries
      >
    | BaseActionResult<
        MinistryCard | null,
        typeof MinistriesActionsEnum.updateMinistryCard
    >;
