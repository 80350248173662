import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { Answer, Content, Footer, QuestionTitle, QuestionWrap, Root } from './answer-list-modal.styles';
import { Button } from '../../../../../global/components/button';
import { OVRegistration } from '../../../models/registration/ov-registration.model';
import { OVQuestionList } from '../../../models/question/ov-question-list.model';
import { OVQuestionRepository } from '../../../repositories/ov-question.repository';
import { OVAnswerRepository } from '../../../repositories/ov-answer.repository';
import { OVAnswer } from '../../../models/answer/ov-answer.model';

type OVAnswerListModalProps = {
    registration: OVRegistration;
    onClose: () => void;
};

const OVAnswerListModal = (props: OVAnswerListModalProps) => {
    const [questions, setQuestions] = useState<OVQuestionList[]>([]);
    const [answers, setAnswers] = useState<OVAnswer[]>([]);

    useEffect(() => {
        init();
    }, []);

    const init = useCallback(async () => {
        const questionCollection = await OVQuestionRepository.getList({
            pageNo: 0,
            pageSize: 1000,
        }, {
            isAvaliable: true,
        });
        setQuestions(questionCollection.items);
        const answerCollection = await OVAnswerRepository.getList({
            pageNo: 0,
            pageSize: 1000,
        }, {
            registrationId: props.registration.id,
        });
        setAnswers(answerCollection.items);
    }, [props.registration.id]);

    const cancelCallback = useCallback(() => {
        props.onClose();
    }, [props.onClose]);

    const getAnswer = useCallback((questionId: number) => {
        return answers.find(a => a.question.id === questionId)?.answer || '';
    }, [answers]);

    const modalStyle: Modal.Styles = useMemo(() => ({
        content: {
            width: '600px',
            height: '400px',
        }
    }), []);

    return (
        <Modal
            isOpen
            style={modalStyle}
        >
            <Root>
                <Content>
                    {
                        questions.map(question =>
                            <QuestionWrap key={question.id}>
                                <QuestionTitle>{question.title}:</QuestionTitle>
                                <Answer>{getAnswer(question.id)}</Answer>
                            </QuestionWrap>
                        )
                    }
                </Content>
                <Footer>
                    <Button
                        title="Закрыть"
                        onClick={cancelCallback}
                        secondary
                    />
                </Footer>
            </Root>
        </Modal>
    );
};

export default OVAnswerListModal;
