import styled from 'styled-components/macro';

const Root = styled.div`
    width: 100%;
`;

const MapWrap = styled.div<{ width?: number, height?: number }>`
    width: ${({ width }) => width ? `${width}vw` : '700px'};
    height: ${({ height }) => height ? `${height}vh` : '300px'};
`;

export { Root, MapWrap };
