import styled from 'styled-components';
import { TextInput } from '../../form-controls/text-input/text-input';
import { colors, palette } from '../../../styles/variables';

interface TabWrapperProps {
    isActive?: boolean;
    isFixed?: boolean;
    isPrimaryTabs?: boolean;
    isVerticalLayout?: boolean;
    isFirst?: boolean;
    borderRadiusTop?: string;
}
export const TabWrapper = styled.div<TabWrapperProps>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: ${({ isFirst, borderRadiusTop }) =>
        isFirst && borderRadiusTop ? `${borderRadiusTop} 0px 0px 0px` : 'unset'};
    gap: 4px;
    position: relative;
    min-height: 35px;
    padding: 0 8px;
    cursor: pointer;
    box-sizing: border-box;
    color: ${colors.lightBlack};
    border-right: 1px solid ${colors.borderDefault};
    font: normal 400 15px/15px 'Consolas', sans-serif;

    &.flex-styles {
        flex-grow: 1;
        justify-content: center;
    }

    &.vertical-styles {
        border-right: none;
        width: 154px;
        justify-content: flex-start;

        background: ${({ isActive }) => {
        if (isActive) {
            return colors.tabColorBgActive;
        }
        return palette.grayLight23;
    }};
    }

    &:hover {
        background: ${({ isVerticalLayout, isActive }) => {
        if (isVerticalLayout && isActive) {
            return '';
        }
        return isVerticalLayout ? palette.grayLight2 : colors.tabColorBgActive;
    }};

        .close-icon-wrapper {
            svg > path:last-child {
                visibility: visible;
            }
        }
    }

    background: ${({ isActive, isFixed }) => {
        if (isActive) {
            return colors.tabColorBgActive;
        }
        if (isFixed) {
            return palette.white;
        }
        return palette.gray6;
    }};

    &.primary-tab-styles {
        background: ${({ isActive }) => {
        if (isActive) {
            return colors.buttonPrimary;
        }
        return colors.white;
    }};

        color: ${({ isActive }) => {
        if (isActive) {
            return colors.white;
        }
        return palette.gray7;
    }};

        .close-icon-wrapper {
            &.show {
                svg > path:last-child {
                    fill: ${colors.white};
                }
            }
        }
    }
`;

export const CloseIconWrapper = styled.div<TabWrapperProps>`
    align-self: center;
    padding: ${({ isPrimaryTabs }) => {
        if (isPrimaryTabs) {
            return '3px 0 0';
        }
        return '5px 0 0';
    }};

    svg > path:last-child {
        visibility: hidden;
    }

    &.show {
        svg > path:last-child {
            visibility: visible;
        }
    }

    &:hover {
        svg > path:last-child {
            fill: ${colors.lightBlack};
        }
    }
`;

export const FileNameEditingInput = styled(TextInput)<{ width: number; letterCount: number }>`
    min-width: 30px;
    width: ${(props) => `calc(${(props.width + 8).toFixed(2)}px - ${props.letterCount * 0.05}em)`};
    height: auto;
    max-width: 100%;

    &::selection {
        background-color: ${colors.tabChangeSelection};
    }
`;

export const FileNameEditingInputInner: React.CSSProperties = {
    border: 'none',
    borderRadius: '0',
    paddingRight: 0,
    paddingTop: 5,
    backgroundColor: 'inherit',
};

export const ActiveBorder = styled.div<TabWrapperProps>`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: ${({ isActive, isFixed, isPrimaryTabs }) => {
        if (isFixed || isActive) {
            return '3px';
        }
        if (isPrimaryTabs) {
            return 0;
        }
        return '3px solid transparent';
    }};
    background-color: ${({ isActive }) => {
        return isActive ? colors.buttonPrimary : palette.gray2;
    }};
`;

export const Title = styled.div<TabWrapperProps>`
    padding: ${({ isPrimaryTabs }) => {
        if (isPrimaryTabs) {
            return 0;
        }
        return '3px 0 0';
    }};
    white-space: nowrap;
`;
