import { OVCohortActions, OVCohortActionsEnum, OVCohortState } from './ov-cohort.types';

const initialState = new OVCohortState();

const ovCohortReducer = (state = initialState, action: OVCohortActions) => {
    switch (action.type) {
        case OVCohortActionsEnum.updateOVCohorts:
            return {
                ...state,
                cohorts: action.payload.cohorts,
                totalCount: action.payload.totalCount,
            };
        case OVCohortActionsEnum.updateOVCohortCard:
            return {
                ...state,
                cohortCard: action.payload,
            };
        default:
            return state;
    }
};

export default ovCohortReducer;
