import { BaseActionResult } from '../../../global/helpers/actions';
import { ResourcesList } from '../models/resources-list.model';
import { ResourcesCard } from '../models/resources-card.model';

export class ResourcesState {
    resources: ResourcesList[] = [];
    totalCount: number = 0;
    resourcesCard: ResourcesCard | null = null;
}

export type UpdateResourcesAction = {
    resources: ResourcesList[];
    totalCount: number;
}

export enum ResourcesActionsEnum {
    updateResources = 'UPDATE_RESOURCES',
    updateResourcesCard = 'UPDATE_RESOURCES_CARD',
}

export type ResourcesActions =
    BaseActionResult<
        UpdateResourcesAction,
        typeof ResourcesActionsEnum.updateResources
    >
    | BaseActionResult<
    ResourcesCard | null,
        typeof ResourcesActionsEnum.updateResourcesCard
    >;
