import React, { FC, memo, useCallback, useContext, useMemo, useRef } from 'react';
import { StyledPlusButton, TabWrap, Wrapper } from './tab-bar.styles';
import { ReactComponent as PlusIcon } from './icons/plus.svg';
import { Tab } from './tab/tab';
import { ContextMenuItem, ContextMenuSetterContext } from '../context-menu/context';
import cn from 'classnames';

export type TabItemType = {
    id?: string;
    domId?: string;
    index?: number;
    title: string;
    icon?: JSX.Element;
    isActive?: boolean;
    isFixed?: boolean;
    isNotSaved?: boolean;
    isEdit?: boolean;
};

export type CallbackTabParam = {
    e?: React.MouseEvent;
    id?: string;
    index?: number;
    newTitle?: string;
};

export type TabBarProps = {
    tabs: TabItemType[];
    isAvailableRename?: boolean;
    isAvailableClose?: boolean;
    isAvailableAddTab?: boolean;
    isPrimaryTabs?: boolean;
    isVerticalLayout?: boolean;
    isFlex?: boolean;
    marginTop?: number;
    marginBottom?: number;
    marginRight?: number;
    borderTop?: string;
    width?: number;
    openContextMenuOnWrap?: boolean;
    borderRadiusTop?: string;
    withBorderBottom?: boolean;
    onContextMenu?: (params: CallbackTabParam) => ContextMenuItem[];
    onTabClick?: (params: CallbackTabParam) => void;
    onAddTab?: () => void;
    onTabEnter?: (params: CallbackTabParam) => void;
    onTabClose?: (params: CallbackTabParam) => void;
    rightActionElement?: React.ReactNode;
};

export const TabBar: FC<TabBarProps> = memo(
    ({
        tabs,
        onAddTab,
        onTabClick,
        onTabEnter,
        onTabClose,
        onContextMenu,
        isAvailableClose,
        isAvailableAddTab,
        isAvailableRename,
        isPrimaryTabs,
        isVerticalLayout,
        isFlex,
        rightActionElement,
        marginTop,
        marginBottom,
        marginRight,
        borderTop,
        width,
        borderRadiusTop,
        openContextMenuOnWrap,
        withBorderBottom,
    }) => {
        const ref = useRef<HTMLDivElement | null>(null);
        const isAddButtonDisabled = !!tabs.find((tab) => tab.title === '');
        const haveEmptyTab = useRef(isAddButtonDisabled);
        const { updateContextMenuCallback } = useContext(ContextMenuSetterContext);

        const handleWheel = (e: React.WheelEvent<HTMLDivElement>) => {
            if (!ref.current) {
                return;
            }
            ref.current?.scrollBy({ left: -e.deltaY });
        };

        const onMouseDown = () => {
            haveEmptyTab.current = !!tabs.find((tab) => tab.title === '');
            return false;
        };

        const onAddIconClick = () => {
            if (haveEmptyTab.current) {
                haveEmptyTab.current = false;
                return;
            }
            onAddTab?.();
        };

        const wrapperClass = useMemo(
            () =>
                cn({
                    'flex-styles': isFlex,
                    'primary-tabs-styles': isPrimaryTabs,
                    'vertical-layout': isVerticalLayout,
                }),
            [isFlex, isPrimaryTabs, isVerticalLayout],
        );

        const onContextMenuCallback = useCallback(
            (e: React.MouseEvent<HTMLDivElement>) => {
                if (!openContextMenuOnWrap || !onContextMenu) {
                    return;
                }
                e.preventDefault();
                e.stopPropagation();
                const items = onContextMenu({});
                updateContextMenuCallback({
                    items,
                    clickCoordinates: { x: e.clientX, y: e.clientY },
                    anchorElement: e.target,
                });
            },
            [onContextMenu, openContextMenuOnWrap],
        );

        if (!isAvailableAddTab && !rightActionElement && !tabs.length) {
            return null;
        }

        return (
            <Wrapper
                className={wrapperClass}
                marginTop={marginTop}
                marginBottom={marginBottom}
                marginRight={marginRight}
                borderTop={borderTop}
                width={width}
                borderRadiusTop={borderRadiusTop}
                onContextMenu={onContextMenuCallback}
                withBorderBottom={withBorderBottom}
            >
                <TabWrap ref={ref} onWheel={handleWheel} className={wrapperClass}>
                    {tabs.map((tab, index) => (
                        <Tab
                            key={tab.id || tab.index || index}
                            id={tab.id}
                            domId={tab.domId}
                            title={tab.title}
                            isFixed={tab.isFixed}
                            isFlex={isFlex}
                            isEdit={tab.isEdit}
                            isActive={tab.isActive}
                            isNotSaved={tab.isNotSaved}
                            icon={tab.icon}
                            index={index}
                            borderRadiusTop={borderRadiusTop}
                            onClick={onTabClick}
                            onEnter={onTabEnter}
                            onClose={onTabClose}
                            onContextMenu={onContextMenu}
                            isAvailableClose={isAvailableClose}
                            isAvailableRename={isAvailableRename}
                            isPrimaryTabs={isPrimaryTabs}
                            isVerticalLayout={isVerticalLayout}
                            data-qa={`tab-${tab.title}`}
                        />
                    ))}
                </TabWrap>
                {isAvailableAddTab && (
                    <StyledPlusButton disabled={isAddButtonDisabled}>
                        <PlusIcon onMouseDown={onMouseDown} onClick={onAddIconClick} />
                    </StyledPlusButton>
                )}
                {rightActionElement}
            </Wrapper>
        );
    },
);
