import { api } from '../../../config/api';
import { BaseRepository, ResolverMethods } from '../../../global/repository/base.repository';
import { apiHost } from '../../../params';
import { ResourcesCard, ResourcesCardDTO, ResourcesCardType } from '../models/resources-card.model';
import { ResourcesList, ResourcesListDTO, ResourcesListType } from '../models/resources-list.model';
import { CreateResourcesParams } from '../types/create-resources-params';
import { GetResourcesListBodyParams } from '../types/get-resources-list-body-params';
import { GetResourcesListQueryParams } from '../types/get-resources-list-query-params';

export class ResourcesRepository extends BaseRepository {
    static defaultUrl = `${apiHost}/api/v1/resources/`;
    static api = api;

    static getList(queryParams: GetResourcesListQueryParams, bodyData: GetResourcesListBodyParams) {
        return ResourcesRepository.resolveCollection<ResourcesListDTO, ResourcesList>({
            url: '',
            method: ResolverMethods.post,
            queryParams,
            bodyData,
            model: ResourcesList,
            modelType: ResourcesListType,
        });
    };

    static create(bodyData: CreateResourcesParams) {
        return ResourcesRepository.resolve<ResourcesCardDTO, ResourcesCard>({
            url: 'create',
            method: ResolverMethods.post,
            bodyData,
            model: ResourcesCard,
            modelType: ResourcesCardType,
        });
    };

    static getCard(id: number) {
        return ResourcesRepository.resolve<ResourcesCardDTO, ResourcesCard>({
            url: `${id}/`,
            model: ResourcesCard,
            modelType: ResourcesCardType,
        });
    };

    static update(id: number, bodyData: CreateResourcesParams) {
        return ResourcesRepository.resolve<ResourcesCardDTO, ResourcesCard>({
            url: `${id}/update`,
            method: ResolverMethods.put,
            bodyData,
            model: ResourcesCard,
            modelType: ResourcesCardType,
        });
    };

    static delete(id: number) {
        return ResourcesRepository.resolveWithoutModel<void>({
            url: `${id}/delete`,
            method: ResolverMethods.delete,
        });
    };
}
