import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../../global/components/button';
import { InfoItem } from '../../../../global/components/info-item';
import { goToState } from '../../../../global/helpers/url-parse.helper';
import { Header, Root } from './settings-card.styles';
import { useSpinner } from '../../../../global/hooks/use-spinner';
import { AuthService } from '../../../../auth/services/AuthService';
import { BottomPadding } from '../../../../global/components/main-wrap/main-wrap.styles';
import { getSettingsCard } from '../../redux/service.selectors';
import { clearSettingsCard, loadSettingsCard } from '../../redux/service.actions';

const SettingsCard = () => {
    const availablePage = useMemo(() => AuthService.availableForAdmin() || AuthService.hasServicePermission(), []);
    const dispatch = useDispatch();
    const spinnerRunner = useSpinner(dispatch);
    const settingsCard = useSelector(getSettingsCard);

    useEffect(() => {
        if (!availablePage) {
            goToState('/');
            return;
        }
    }, []);

    useEffect(() => {
        dispatch(clearSettingsCard());
        spinnerRunner(loadSettingsCard());
    }, []);

    const goToEdit = useCallback(() => {
        goToState('/service-settings/edit');
    }, []);

    if (!settingsCard) {
        return (
            <Root>
                <Header>
                </Header>
            </Root>
        );
    }

    return (
        <Root>
            <Header>
                <Button
                    title="Редактировать"
                    onClick={goToEdit}
                />
            </Header>
            <InfoItem
                title="Ссылка на трансляцию собрания"
                value={settingsCard.translationLink}
            />
            <InfoItem
                title="План собрания"
                value={settingsCard.plan}
            />
            <BottomPadding />
        </Root>
    );
};

export default SettingsCard;
