import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '../../../../global/components/button';
import { TextInput } from '../../../../global/components/form-controls/text-input/text-input';
import { InfoItem } from '../../../../global/components/info-item';
import { InfoLine } from '../../../../global/components/info-line';
import { goToState } from '../../../../global/helpers/url-parse.helper';
import { Footer, Header, Root } from './family-create.styles';
import { useSpinner } from '../../../../global/hooks/use-spinner';
import { CreateFamilyParams } from '../../types/create-family-params';
import { createFamilyCard } from '../../redux/family.actions';
import MultipleSelectEntity, { EntityForSelect } from '../../../../global/components/multiple-select-entity/multiple-select-entity';
import { loadMembersForSelect } from '../../../members/helpers/api.helpers';
import { AuthService } from '../../../../auth/services/AuthService';
import { BottomPadding } from '../../../../global/components/main-wrap/main-wrap.styles';

const FamilyCreate = () => {
    const showForAdmin = useMemo(() => AuthService.availableForAdmin(), [AuthService.availableForAdmin]);
    const dispatch = useDispatch();
    const spinnerRunner = useSpinner(dispatch);
    const [name, setName] = useState<string | null>(null);
    const [childCount, setChildCount] = useState<string | null>(null);
    const [members, setMembers] = useState<EntityForSelect[]>([]);
    const goToList = useCallback(() => {
        goToState('/family');
    }, []);

    useEffect(() => {
        if (!showForAdmin) {
            goToState('/');
            return;
        }
    }, []);
    

    const isValidForm = useMemo(
        () => !!name && !!members.length,
        [
            name,
            members,
        ],
    );

    const createCallback = useCallback(() => {
        if (!name || !members.length) {
            return;
        }
        const params: CreateFamilyParams = {
            name,
            childCount: Number(childCount) || 0,
            members: members.map(el => el.id),
            weddingDay: '',
        };
        spinnerRunner(createFamilyCard(params));
    }, [
        name,
        childCount,
        members,
        spinnerRunner,
        createFamilyCard,
    ]);

    return (
        <Root>
            <Header>
                <Button
                    title="Перейти в список"
                    onClick={goToList}
                />
            </Header>
            <InfoLine>
                <InfoItem
                    title="Название *"
                    value={<TextInput
                        value={name}
                        onChange={setName}
                    />}
                />
                <InfoItem
                    title="Кол-во детей"
                    value={<TextInput
                        value={childCount}
                        onChange={setChildCount}
                        typeInput="number"
                    />}
                />
                <MultipleSelectEntity
                    label="Участники *"
                    values={members}
                    onChange={setMembers}
                    loadOptions={loadMembersForSelect}
                />
            </InfoLine>
            <Footer>
                <Button
                    title="Создать"
                    onClick={createCallback}
                    disabled={!isValidForm}
                />
            </Footer>
            <BottomPadding />
        </Root>
    );
};

export default FamilyCreate;
