import { UserPermissions } from '../../modules/users/models/user-permissions.model';

export class AuthService {

    public static PROCESS_REFRESH: boolean = false;
    public static LOGIN_STATE: string = 'login';

    /**
     * Получаем информацию по пользователю
     * @returns {String}
     */
    public static getUserInfo() {
        try {
            const item = window.localStorage.getItem('RBCAdminUserInfo');
            if (!item) {
                return null;
            }
            return JSON.parse(item);
        } catch (ex) {
            return null;
        }
    }

    /**
     * Получаем информацию о роле пользователя
     */
    public static getUserRole(): string | null {
        const userInfo = AuthService.getUserInfo();
        return userInfo?.role || null;
    }

    /**
     * Получаем информацию о разрешениях пользователя
     */
    public static getUserPermissions(): Array<{
        id: number,
        name: string,
    }> {
        const userInfo = AuthService.getUserInfo();
        return userInfo?.permissions || [];
    }

    /**
     * Есть ли у пользователя доступ к сервису основ веры?
     */
    public static hasOVPermission(): boolean {
        const permissions = AuthService.getUserPermissions();
        return !!permissions.find(el => el.name === UserPermissions.OV.value);
    }

    /**
     * Есть ли у пользователя доступ к сервису участников?
     */
    public static hasMembersPermission(): boolean {
        const permissions = AuthService.getUserPermissions();
        return !!permissions.find(el => el.name === UserPermissions.MEMBERS.value);
    }

    /**
     * Есть ли у пользователя доступ к сервису рассылок?
     */
    public static hasMessagesPermission(): boolean {
        const permissions = AuthService.getUserPermissions();
        return !!permissions.find(el => el.name === UserPermissions.MESSAGES.value);
    }

    /**
     * Есть ли у пользователя доступ к сервису служений?
     */
    public static hasServicePermission(): boolean {
        const permissions = AuthService.getUserPermissions();
        return !!permissions.find(el => el.name === UserPermissions.SERVICE.value);
    }

    /**
     * Есть ли у пользователя доступ к пасторским функциям?
     */
    public static hasPastorPermission(): boolean {
        const permissions = AuthService.getUserPermissions();
        return !!permissions.find(el => el.name === UserPermissions.PASTOR.value);
    }

    /**
     * пользователь имеет роль USER или выше
     */
    public static availableForUser(): boolean {
        const userRole = AuthService.getUserRole();
        return !!userRole;
    }

    /**
     * пользователь имеет роль LEADER или выше
     */
    public static availableForLeader(): boolean {
        const userRole = AuthService.getUserRole();
        return userRole === 'LEADER' || userRole === 'ADMIN';
    }

    /**
     * пользователь имеет роль ADMIN или выше
     */
    public static availableForAdmin(): boolean {
        const userRole = AuthService.getUserRole();
        return userRole === 'ADMIN';
    }

    /**
     * Запишем информацию о пользователе
     * @param {String} userInfo
     */
    // eslint-disable-next-line
    public static setUserInfo(userInfo: any): void {
        const oldUserInfo = AuthService.getUserInfo();
        const newUserInfo = oldUserInfo ? userInfo ? Object.assign(oldUserInfo, userInfo) : null : userInfo;
        window.localStorage.setItem('RBCAdminUserInfo', JSON.stringify(newUserInfo));
    }

    /**
     * Выйти из учётки
     */
    public static logOut(): void {
        AuthService.setUserInfo(null);
        const urlArray = window.location.href.split('?');
        if (urlArray.length > 1) {
            return;
        }
        window.location.href = `${window.location.href.split('#')[0]}#/${AuthService.LOGIN_STATE}`;
    }
}
