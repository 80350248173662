import { isLeft } from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import { Collection } from '../types/collection';

// eslint-disable-next-line
export type LocalAnyType = any;

type LocalDTOType = { id?: string | number };

class TypeHelper {

    /**
     * Создания типа enums для io-ts
     */
    static createEnum = <E>(e: LocalAnyType, name: string): t.Type<E> => {
        const keys: LocalAnyType = {};
        Object.keys(e)
            .forEach((k: string) => (keys[e[k]] = null));
        return t.keyof(keys, name) as LocalAnyType;
    }

    /**
     * Показать ошибку в консоли о несоответствии типов
     */
    static showConsoleError = (idEntity: string | number | undefined, classEntity: string, result: LocalAnyType): void => {
        if (typeof window === 'undefined') {
            return;
        }
        window.console.log('');
        window.console.log('============== io-ts start error =============');
        window.console.log(`Произошла ошибка при обработке данных, полученных с сервера. Сущность: ${classEntity}, id сущности: ${idEntity}`);
        result.left.forEach((value: LocalAnyType) => {
            let context = '';
            value.context.forEach((ctx: LocalAnyType) => {
                context = `${context}${ctx.key} `;
            });
            window.console.log(`Значение: ${value.value}, key: ${context}`);
        });
        window.console.log('============== io-ts end error =============');
        window.console.log('');
    }

    /**
     * Проверяем один элемент
     */
    static checkElement<M, DTO extends LocalDTOType>(
        entityType: t.TypeC<LocalAnyType> | t.IntersectionC<[t.TypeC<LocalAnyType>, t.TypeC<LocalAnyType>]>,
        entityClass: LocalAnyType,
        entityName: string,
        entity: DTO,
    ): M | null {
        const result = entityType.decode(entity);
        if (isLeft(result)) {
            TypeHelper.showConsoleError(entity.id, entityName, result);
            return null;
        }
        return new entityClass(entity);
    }

    /**
     * Проверяем элементы в массиве
     */
    static checkElementsArray<M, DTO extends LocalDTOType>(
        entityType: t.TypeC<LocalAnyType> | t.IntersectionC<[t.TypeC<LocalAnyType>, t.TypeC<LocalAnyType>]>,
        entityClass: LocalAnyType,
        entityName: string,
        array: DTO[],
    ): M[] {
        return array.map((item: LocalAnyType) => TypeHelper.checkElement<M, DTO>(entityType, entityClass, entityName, item))
            .filter((el: M | null): el is M => !!el);
    }

    /**
     * Проверяем элементы в коллекции
     */
    static checkElementsCollection<M, DTO extends LocalDTOType>(
        entityType: t.TypeC<LocalAnyType> | t.IntersectionC<[t.TypeC<LocalAnyType>, t.TypeC<LocalAnyType>]>,
        entityClass: LocalAnyType,
        entityName: string,
        collection: Collection<DTO>,
    ): Collection<M> {
        return {
            items: collection.items.map((item: LocalAnyType) => TypeHelper.checkElement<M, DTO>(entityType, entityClass, entityName, item))
                .filter((el: M | null): el is M => !!el),
            totalCount: collection.totalCount,
        };
    }
}

export { TypeHelper };
