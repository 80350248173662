import React, { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { ContextMenuSetterContext, ContextMenuSetterContextProps, ContextMenuValueContext, ContextMenuValueProps } from '../context';
import ContextMenu from '..';
import { LocalAnyType } from '../../../helpers/type.helper';

export type FCWithChildren<T = LocalAnyType> = FC<PropsWithChildren<T>>;

export const ContextMenuProvider: FCWithChildren = (props) => {
    const [value, setValue] = useState<ContextMenuValueProps>({});

    const updateValue = useCallback((newValue: ContextMenuValueProps) => {
        setValue((oldValue) => {
            if (oldValue.isShow && newValue.isShow === false) {
                oldValue.onClose?.();
            }
            return newValue;
        });
    }, []);

    const setterValue = useMemo<ContextMenuSetterContextProps>(() => ({
        updateContextMenuCallback: (newValue: ContextMenuValueProps) => {
            if (newValue.isShow === undefined) {
                newValue.isShow = true;
            }
            newValue.closeCallback = () => {
                updateValue({ isShow: false });
                if (newValue.onClose) {
                    newValue.onClose();
                }
            };
            updateValue(newValue);
            return newValue.closeCallback;
        },
    }), []);

    return <ContextMenuValueContext.Provider value={value}>
        <ContextMenuSetterContext.Provider value={setterValue}>
            <div
                id="data-context-menu-is-show-checker"
                data-context-menu-is-show={!!value.isShow}
            ></div>
            {props.children}
            <ContextMenu />
        </ContextMenuSetterContext.Provider>
    </ContextMenuValueContext.Provider>;
};
