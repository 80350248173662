import styled from 'styled-components/macro';

const Root = styled.div`
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .min-action-ico {
        cursor: pointer;
    }
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 12px;

    & > div {
        margin-right: 12px;
    }
`;

const Avatar = styled.img`
    max-width: 200px;
    margin-bottom: 20px;
`;

const AvatarActionWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 12px;

    & > div, & > label {
        margin-right: 12px;
    }
`;

const FileUploadLabel = styled.label`
    cursor: pointer;
`;

const FileUploadInput = styled.input`
    display: none;
`;

const CardHeader = styled.div`
    margin: 24px 0 12px;
    font-size: 16px;
    font-weight: 600;
`;

export { Root, Header, Avatar, AvatarActionWrap, FileUploadInput, FileUploadLabel, CardHeader };
