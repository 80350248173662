export type ResourcesPlatformItem = {
    label: string;
    value: string;
};

type ResourcesPlatformType = {
    [key: string]: ResourcesPlatformItem;
};

export const ResourcesPlatform: ResourcesPlatformType = {
    web: {
        label: 'Веб',
        value: 'web',
    },
    tg: {
        label: 'Телеграмм',
        value: 'tg',
    },
};

export const resourcesPlatformOptions = Object.values(ResourcesPlatform);
