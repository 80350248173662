import { UsersActions, UsersActionsEnum, UsersState } from './users.types';

const initialState = new UsersState();

const usersReducer = (state = initialState, action: UsersActions) => {
    switch (action.type) {
        case UsersActionsEnum.updateUsers:
            return {
                ...state,
                users: action.payload.users,
                totalCount: action.payload.totalCount,
            };
        case UsersActionsEnum.updateUserCard:
            return {
                ...state,
                userCard: action.payload,
            };
        default:
            return state;
    }
};

export default usersReducer;
