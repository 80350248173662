import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { StateModel } from '../redux/state.model';
import store from '../redux/store';
import { LocalAnyType } from './type.helper';

export type BaseActionResult<R, T = string> = {
    type: T;
    payload: R;
};

/**
 * Функция для нормального создания экшенов
 * на вход принимает колбэк в который передаёт текущий стейт и ожидает от него тип экшена и payload
 * @param callback
 * @returns
 */
export const baseActionCreator = <R>(
    callback: (state: StateModel, dispatch: Dispatch<LocalAnyType>) => ([string, R] | null),
): ThunkAction<BaseActionResult<R> | null, StateModel, null, BaseActionResult<R>> => {
    return (dispatch: Dispatch<BaseActionResult<R>>) => {
        const storeObject = store.getState();
        const result = callback(storeObject, dispatch);
        if (!result) {
            return null;
        }
        const [type, payload] = result;
        return dispatch({ type, payload });
    };
};

/**
 * Функция для нормального создания экшенов с промисами
 * на вход принимает колбэк в который передаёт текущий стейт и ожидает от него тип экшена и payload
 * @param callback
 * @returns
 */
export const baseAsyncActionCreator = <R>(
    callback: (state: StateModel, dispatch: Dispatch<LocalAnyType>) => Promise<[string, R] | null>,
): ThunkAction<
    Promise<BaseActionResult<R> | null>,
    StateModel,
    null,
    BaseActionResult<R>
> => {
    return async (dispatch: Dispatch<BaseActionResult<R>>) => {
        const storeObject = store.getState();
        const result = await callback(storeObject, dispatch);
        if (!result) {
            return null;
        }
        const [type, payload] = result;
        return dispatch({ type, payload });
    };
};
