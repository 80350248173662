import { toast } from 'react-toastify';
import { baseAsyncActionCreator } from '../../../../global/helpers/actions';
import { SendListRepository } from '../../repositories/send-list.repository';

const uploadSCVSendList = (file: File) =>
    baseAsyncActionCreator<string>(async () => {
        await SendListRepository.uploadSendListFile(file);
        toast.success('Рассылка успешно загружена и запланирована');
        return null;
    });

export {
    uploadSCVSendList,
};