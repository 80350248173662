import { BaseActionResult } from '../../../global/helpers/actions';
import { AdoptedInfo } from '../models/adopted-info.model';
import { MemberCard } from '../models/member-card.model';
import { MemberList } from '../models/member-list.model';

export class MembersState {
    members: MemberList[] = [];
    totalCount: number = 0;
    memberCard: MemberCard | null = null;
    adoptedInfoCard: AdoptedInfo | null = null;
}

export type UpdateMembersAction = {
    members: MemberList[];
    totalCount: number;
}

export enum MembersActionsEnum {
    updateMembers = 'UPDATE_MEMBERS',
    updateMemberCard = 'UPDATE_MEMBER_CARD',
    updateAdoptedInfoCard = 'UPDATE_ADOPTED_INFO_CARD',
}

export type MembersActions =
    BaseActionResult<
          UpdateMembersAction,
          typeof MembersActionsEnum.updateMembers
      >
    | BaseActionResult<
        MemberCard | null,
        typeof MembersActionsEnum.updateMemberCard
    >
    | BaseActionResult<
        AdoptedInfo | null,
        typeof MembersActionsEnum.updateAdoptedInfoCard
    >;
