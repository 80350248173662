const palette = {
    white: '#fff',
    black1: '#212121',
    azure: '#00b0c2',
    gray: '#585757',
    gray2: '#DBDBDB',
    gray3: '#4B4B4B',
    gray4: '#535158',
    gray5: '#333238',
    gray6: '#F9F9F9',
    gray7: '#5E6466',
    gray8: '#767D80',
    gray9: '#C7C7C7',
    gray10: '#7C7C7C',
    gray11: '#1F1E24',
    gray12: '#E1E3E5',
    gray13: '#626168',
    grayLight: '#C4C4C4',
    grayLight2: '#F3F3F3',
    grayLight3: '#EEF0EF',
    grayLight4: '#C6C6C6',
    grayLight5: '#F8F9F9',
    grayLight6: '#eeeeee',
    grayLight7: '#E5E5E5',
    grayLight8: '#b6b6b6',
    grayLight9: '#F5F5F5',
    grayLight10: '#D9D9D9',
    grayLight11: '#858585',
    grayLight12: '#C7C7C7',
    grayLight13: '#EAEAEA',
    grayLight14: '#A4A4A4',
    grayLight15: '#6B6F80',
    grayLight16: '#757575',
    grayLight17: '#DCDEE6',
    grayLight18: '#EFEFF3',
    grayLight19: '#E7E7E7',
    grayLight20: '#2B2B2F',
    grayLight21: '#D2D1D1',
    grayLight22: '#D7DADC',
    grayLight23: '#F8F8F8',
    grayLight24: '#1F1E241A',
    blue: '#4B7695',
    blue400: '#50B6E9',
    blue900: '#0E5F87',
    blue2: '#70C7F2',
    blue3: '#2795CD',
    blue4: '#E1F1FB',
    blue5: '#E9F3FC',
    blue6: '#1a84b8',
    blue7: '#E9F5FC',
    blue8: '#4597F8',
    blue9: '#F0FAFF',
    blue10: '#1B84B8',
    lightBlue: '#619ac3',
    lightBlue2: '#F6F8FA',
    lightBlue3: '#e8f6fe',
    red: '#c23735',
    red2: '#e25453',
    red3: '#DA0E0E',
    green: '#0D783B',
    red4: '#CF222E',
    greenLight: '#87BBA2',
    greenNormal: '#0D783B',
    grayText: '#616871',
    blackText: '#141519',
    radioBorder: '#dedddd',
    yellow: '#fffae3',
    yellow2: '#fff5db',
    orange: '#CC7715',
    yellow3: '#ECA921',
    sequenceHighlightStroke: '#995B87',
};

const colors = {
    mainFontColor: palette.gray,
    white: '#FFFFFF',
    black: '#000',
    lightBlack: '#212121',
    grey: '#eee',
    GreyLighten1: '#a5a5a5',
    GreyLighten2: '#e6e6e6',
    GreyLighten3: '#F5F5F5',
    GreyLighten5: '#f7f7f7',
    GreyLighten6: '#f8f8f8',
    iconGrey: '#767d80',
    BgToolkitNav: '#E5E5E5',
    BlockSearchOnModelingArea: '#78A6FC',
    BlockSearchResultItem: '#ECF2FF',
    TabColor: palette.blue,
    line: palette.gray,
    acausalLine: palette.blue3,
    threePhaseLine: '#6691A7',
    electricalPinLine: '#2795CD',
    gasLine: '#7E3B8B',
    mechanicalRotationalLine: '#42CD27',
    mechanicalTranslationalLine: '#298018',
    isothermalLiquidLine: '#2742CD',
    moistAirLine: '#50B6E9',
    moistAirSource: '#CCE9F7',
    thermalLine: '#FA5A4A',
    thermalLiquidLine: '#F9A630',

    magneticLine: '#DF548D',
    selected: '#79BEEE',
    buttonStaticBack: palette.blue,
    buttonStaticColor: palette.white,
    buttonHoverBack: palette.lightBlue,
    buttonPressedColor: palette.gray,
    buttonPressedCircle: palette.blue3,
    buttonPressedBack: palette.white,
    borderColor: palette.grayLight,
    borderDefault: '#D7DADC',
    defaultFont: palette.gray,
    radioButtonsBack: palette.grayLight3,
    selectBack: palette.white,
    selectOptionBackHover: palette.grayLight7,
    blockInfo: '#9C9D9E',
    currentLineBorder: '#e0e9f0',
    grayFilter: '#c0c0c0',
    toolbarShadow1: '#ebebeb',
    toolbarShadow2: '#d6d6d6',
    suggest: '#4fb477',
    scriptTabShadow: '#bfbfbf',
    infoLabel: '#ECF2FF',
    warningLabel: '#fff1e4',
    errorLabel: '#FFEDED',
    debugLabel: '#FBF8F1',
    infoBorderLabel: '#78A6FC',
    warningBorderLabel: '#FCA959',
    warning: '#CC7715',
    errorBorderLabel: '#E25453',
    debugBorderLabel: '#FFDC78',
    selectedCell: '#A6DCFF',
    codeControlGray: '#666666',
    codeControlGreen: '#069952',
    editorToolsDivider: '#EAEAEA',
    buttonPrimary: '#1B84B8',
    buttonPrimaryHover: '#1371A0',
    buttonPrimaryDisabled: '#76B5D4',
    scriptEditorSelectedText: '#d7ecfa',
    secondaryHover: '#F5F5F5',
    mainScrollbarBackGround: '#eceff1',
    textInputBorder: '#a1a4b1',
    stderr: '#ECF2FF',
    statusBarBackground: '#DCDEE6',
    borderGrayDark: '#BBBFCC',
    borderGrayDefault: '#D7DADC',
    backgroundInfo: '#E6F6FF',
    blockLibraryDescription: '#4B4B4B',
    accordingTextDisabled: '#989DA0',
    beforeDeleteHint: '#4B4B4B',
    tabColorBgActive: '#E9F5FC',
    tabColorBg: '#F3FBFF',
    tabUnderline: '#2795CD',
    tabChangeSelection: '#ADDEFB',
    checkboxHoverBg: '#f1f1f1',
    disabled: '#9E9E9E',
    gitInputPlaceholder: '#767D80',
    progressBarGreen1: 'rgb(43, 194, 83)',
    progressBarGreen2: 'rgb(84, 240, 84)',
    juliaVariableDimensionalAndType: '#7E898E',
    linkColor: '#0000EE',
    borderDanger: 'rgba(207, 34, 46, 1)',
    bgDanger: 'rgba(255, 235, 233, 1)',
    borderAttention: 'rgba(204, 119, 21, 1)',
    bgAttention: 'rgba(255, 245, 219, 1)',
    borderAccent: 'rgba(27, 132, 184, 1)',
    bgAccent: 'rgba(232, 246, 254, 1)',
    notificationActionLink: '#0969DA',
};

const defaultShadow = 'box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15)';
const shadow1 = 'box-shadow: 3px 3px 23px rgba(176, 179, 187, 0.3)';
const frameShadowForLiveScript =
    'box-shadow: 0 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08)';
const contextMenuShadow = 'box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.15)';
const shadowForScriptEditorComponents =
    '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);';
const linearGradientMatlab =
    'linear-gradient(90deg, #0072BD 2.42%, #D95319 20.63%, #EDB120 35.92%, #7E2F8E 51.81%, #87BC55 68.87%, #4DBEEE 84.76%, #A2142F 100%)';
const linearGradientParula = `linear-gradient(90deg, #352A87 2.42%, #0F5CDD 15.63%, #127DD8 28.85%, #079CCF 42.57%,
        #15B1B4 56.29%, #59BD8C 69.51%, #A5BE6B 77.13%, #E1B952 84.25%, #FCCE2E 92.38%, #F9FB0E 97.97%)`;

export {
    colors,
    palette,
    defaultShadow,
    shadow1,
    shadowForScriptEditorComponents,
    frameShadowForLiveScript,
    contextMenuShadow,
    linearGradientMatlab,
    linearGradientParula,
};
