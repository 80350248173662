import * as t from 'io-ts';

export const MinistryMinListType = t.type({
    id: t.number,
    name: t.string,
});

export type MinistryMinListDTO = t.TypeOf<typeof MinistryMinListType>;

class MinistryMinList {
    id: number;
    name: string;

    constructor(dto: MinistryMinListDTO) {
        this.id = dto.id;
        this.name = dto.name;
    }
}

export { MinistryMinList };
