import { BaseActionResult } from '../../../global/helpers/actions';
import { SettingsCard } from '../models/settings-card.model';

export class ServiceState {
    settingCard: SettingsCard | null = null;
}

export enum ServiceActionsEnum {
    updateSettingCard = 'UPDATE_SETTING_CARD',
}

export type ServiceActions =
    BaseActionResult<
        SettingsCard | null,
        typeof ServiceActionsEnum.updateSettingCard
    >;
