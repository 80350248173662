import styled from 'styled-components/macro';

const Root = styled.div`
    min-height: calc(100% - 12px);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
`;

const Title = styled.div`
    font-size: 24px;
    margin-top: 12px;
    margin-bottom: 12px;
`;

const Content = styled.div`
    flex-grow: 1;
`;

const Footer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    margin-bottom: 12px;
`;

export { Root, Footer, Title, Content };
