import styled from 'styled-components/macro';
import { colors } from '../../../styles/variables';
import { sharedButtonStyle } from '../../../styles/shared';

const StyledButton = styled.button`
    ${sharedButtonStyle};
    
    &.no-border-radius {
        border-radius: 0;
    }

    &.pressed {
        color: ${colors.buttonPressedColor};
        background-color: ${colors.buttonPressedBack};
    }
`;

export {
    StyledButton
};
