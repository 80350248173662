import * as t from 'io-ts';

export const OVQuestionListType = t.type({
    id: t.number,
    title: t.string,
});

export type OVQuestionListDTO = t.TypeOf<typeof OVQuestionListType>;

class OVQuestionList {
    id: number;
    title: string;

    constructor(dto: OVQuestionListDTO) {
        this.id = dto.id;
        this.title = dto.title;
    }
}

export { OVQuestionList };
