import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '../../../../global/components/button';
import { TextInput } from '../../../../global/components/form-controls/text-input/text-input';
import { InfoItem } from '../../../../global/components/info-item';
import { InfoLine } from '../../../../global/components/info-line';
import { goToState } from '../../../../global/helpers/url-parse.helper';
import { Footer, Header, Root } from './ministries-create.styles';
import { useSpinner } from '../../../../global/hooks/use-spinner';
import { EntityForSelect } from '../../../../global/components/multiple-select-entity/multiple-select-entity';
import { loadMembersForSelect } from '../../../members/helpers/api.helpers';
import { CreateMinistryParams } from '../../types/create-ministry-params';
import { createMinistryCard } from '../../redux/ministries.actions';
import SingleSelectEntity from '../../../../global/components/single-select-entity/single-select-entity';
import { loadMinistriesForSelect } from '../../helpers/api.helpers';
import { loadChatInfoForSelect } from '../../../chat-info/helpers/api.helpers';
import { AuthService } from '../../../../auth/services/AuthService';
import { BottomPadding } from '../../../../global/components/main-wrap/main-wrap.styles';

const MinistryCreate = () => {
    const showForAdmin = useMemo(() => AuthService.availableForAdmin(), [AuthService.availableForAdmin]);
    const dispatch = useDispatch();
    const spinnerRunner = useSpinner(dispatch);
    const [name, setName] = useState<string | null>(null);
    const [chatInfo, setChatInfo] = useState<EntityForSelect | null>(null);
    const [description, setDescription] = useState<string | null>(null);
    const [lead, setLead] = useState<EntityForSelect | null>(null);
    const [assist, setAssist] = useState<EntityForSelect | null>(null);
    const [parent, setParent] = useState<EntityForSelect | null>(null);
    const goToList = useCallback(() => {
        goToState('/ministries');
    }, []);

    useEffect(() => {
        if (!showForAdmin) {
            goToState('/');
            return;
        }
    }, []);

    const isValidForm = useMemo(
        () => !!name && !!lead,
        [
            name,
            lead,
        ],
    );

    const createCallback = useCallback(() => {
        if (!name || !lead) {
            return;
        }
        const params: CreateMinistryParams = {
            name: name,
            chatId: chatInfo?.id || null,
            description,
            leadId: lead.id,
            assistId: assist?.id || null,
            parentId: parent?.id || null,
        };
        spinnerRunner(createMinistryCard(params));
    }, [
        name,
        chatInfo,
        description,
        lead,
        assist,
        parent,
        spinnerRunner,
        createMinistryCard,
    ]);

    return (
        <Root>
            <Header>
                <Button
                    title="Перейти в список"
                    onClick={goToList}
                />
            </Header>
            <InfoLine>
                <InfoItem
                    title="Название *"
                    value={<TextInput
                        value={name}
                        onChange={setName}
                    />}
                />
                <InfoItem
                    title="Описание"
                    value={<TextInput
                        value={description}
                        onChange={setDescription}
                    />}
                />
                <SingleSelectEntity
                    label="Чат в телеграмме"
                    value={chatInfo}
                    onChange={setChatInfo}
                    loadOptions={loadChatInfoForSelect}
                />
            </InfoLine>
            <InfoLine>
                <SingleSelectEntity
                    label="Лидер *"
                    value={lead}
                    onChange={setLead}
                    loadOptions={loadMembersForSelect}
                />
                <SingleSelectEntity
                    label="Помощник"
                    value={assist}
                    onChange={setAssist}
                    loadOptions={loadMembersForSelect}
                />
                <SingleSelectEntity
                    label="Родительское служение"
                    value={parent}
                    onChange={setParent}
                    loadOptions={loadMinistriesForSelect}
                />
            </InfoLine>
            <Footer>
                <Button
                    title="Создать"
                    onClick={createCallback}
                    disabled={!isValidForm}
                />
            </Footer>
            <BottomPadding />
        </Root>
    );
};

export default MinistryCreate;
