import React, { useCallback, useMemo } from 'react';
import { CurrentInput, Description } from './text-input-mask.styles';

interface TextInputMaskProps {
    className?: string;
    autofocus?: boolean;
    style?: React.CSSProperties;
    onChange: (v: string | null) => void;
    onEnter?: () => void;
    onBlur?: () => void;
    value: string | number| null;
    description?: string;
    mask: string;
}

export const TextInputMask: React.FC<TextInputMaskProps> = ({
    className,
    style,
    autofocus,
    onChange,
    onEnter,
    onBlur,
    description,
    value,
    mask,
}) => {
    const onChangeCallback = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange(e.currentTarget.value);
        },
        [onChange],
    );

    const onKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            e.stopPropagation();
        },
        [],
    );

    const onKeyUp = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            e.stopPropagation();

            if (onEnter && e.key === 'Enter') {
                onEnter();
            }
        },
        [onEnter],
    );

    const visibleValue = useMemo(() => {
        if (value === null) {
            return '';
        }
        return `${value}`;
    }, [value]);

    return (
        <>
            <CurrentInput
                style={style}
                className={className}
                autoFocus={autofocus}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                onChange={onChangeCallback}
                onBlur={onBlur}
                value={visibleValue}
                mask={mask}
                alwaysShowMask
            />
            {
                description &&
                <Description>{description}</Description>
            }
        </>
    );
};
