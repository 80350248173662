import styled from 'styled-components/macro';
import { colors, defaultShadow, palette } from '../../styles/variables';

const ContextMenuWrap = styled.div`
    position: fixed;
    top: -1000px;
    left: -1000px;
    background: #fff;
    ${defaultShadow};
    border-radius: 8px;
    z-index: 130;
    padding: 7px 0;
`;


const ChildContextMenuWrap = styled.div`
    position: absolute;
    top: -10000px;
    left: -10000px;
    min-width: 100px;
    background: #fff;
    ${defaultShadow};
    border-radius: 8px;
    z-index: 100;
    padding: 7px 0;
`;

const ContextMenuItemBlock = styled.div`
    padding: 6px 16px 8px 16px;
    position: relative;
    cursor: pointer;
    min-width: 100%;
    box-sizing: border-box;
    white-space: nowrap;
    color: ${palette.blackText};
    display: flex;
    align-items: center;
    font-size: 16px;
    min-height: 32px;


    &:hover {
        background: ${colors.GreyLighten3};
    }

    &:last-of-type {
        border-bottom: none;
    }

    &.is-disabled {
        color: ${colors.borderColor};
        cursor: default;

        &:hover {
            background: inherit;
        }
    }

    &.with-left-icon {
        padding-left: 47px;
    }

    &.with-right-padding {
        padding-right: 75px;
    }

    &.left-space-1 {
        padding-left: 8px;
    }
    &.left-space-2 {
        padding-left: 16px;
    }
    &.left-space-3 {
        padding-left: 24px;
    }
    &.left-space-4 {
        padding-left: 32px;
    }
    &.left-space-5 {
        padding-left: 40px;
    }
    &.left-space-6 {
        padding-left: 48px;
    }
`;

const LeftIcon = styled.div`
    position: absolute;
    top: 50%;
    left: 13px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;

    &.is-disabled {
        svg path {
            fill: ${colors.borderColor};
        }
    }

    &.is-child-menu {
        left: 13px;
    }
`;

const RightLabel = styled.div<{ left?: string }>`
    position: absolute;
    top: 50%;
    left: ${(props) => props.left ?? 'calc(100% - 28px)'};
    transform: translateY(-50%);
    font-size: 12px;

    &.is-disabled {
        svg path {
            fill: ${colors.borderColor};
        }
    }

    &.has-hot-key {
        left: inherit;
        right: 14px;
    }

    &.delete-word {
        right: 14px;
        left: auto;
    }
`;

const ContextMenuDivider = styled.div`
    min-width: 100%;
    height: 1px;
    background: ${colors.BgToolkitNav};
    box-sizing: border-box;
    margin: 4px 0;
`;

const ItemTitle = styled.div`
    width: 100%;
`;

export {
    ContextMenuWrap,
    ContextMenuItemBlock,
    ContextMenuDivider,
    RightLabel,
    LeftIcon,
    ChildContextMenuWrap,
    ItemTitle,
};
