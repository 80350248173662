import { api } from '../../config/api';
import { LocalAnyType } from '../../global/helpers/type.helper';
import { BaseRepository, ResolverMethods } from '../../global/repository/base.repository';
import { apiHost } from '../../params';
import { AuthService } from '../services/AuthService';

export class AuthRepository extends BaseRepository {
    static tokenUrl = 'authorization/token';
    static defaultUrl = `${apiHost}/api/v1/authorization/`;
    static api = api;

    static whoami() {
        return AuthRepository.resolveWithoutModel({
            url: 'me',
        }).then((res: LocalAnyType) => {
            const userInfo = AuthService.getUserInfo() || {};
            AuthService.setUserInfo({
                ...userInfo,
                ...res,
            });
        });
    };

    static token(login?: string | null, password?: string | null) {
        const params = new FormData();
        params.set('username', login || '');
        params.set('password', password || '');

        return AuthRepository.resolveWithoutModel({
            url: 'token',
            method: ResolverMethods.post,
            bodyData: params,
        })
            .then((res: LocalAnyType) => {
                AuthService.setUserInfo(res || {});
                return AuthRepository.whoami();
            });
    };
}
