export type TestimonyStatusItem = {
    label: string;
    value: string;
};

type TestimonyStatusType = {
    [key: string]: TestimonyStatusItem;
};

export const TestimonyStatus: TestimonyStatusType = {
    READ: {
        label: 'Написано',
        value: 'READ',
    },
    CHECKED: {
        label: 'Проверено',
        value: 'CHECKED',
    },
    CORRECTED: {
        label: 'Корректируется',
        value: 'CORRECTED',
    },
    TALKED: {
        label: 'Рассказано',
        value: 'TALKED',
    },
    CANCELED: {
        label: 'Не будет членом церкви',
        value: 'CANCELED',
    },
};

export const testimonyStatusOptions = Object.values(TestimonyStatus);
