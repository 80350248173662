import * as t from 'io-ts';
import { MemberMinList, MemberMinListType } from '../../members/models/member-min-list.model';

export const GroupListType = t.type({
    id: t.number,
    name: t.string,
    lead: t.union([MemberMinListType, t.null]),
    district: t.string,
});

export type GroupListDTO = t.TypeOf<typeof GroupListType>;

class GroupList {
    id: number;
    name: string;
    lead: MemberMinList | null;
    district: string;

    constructor(dto: GroupListDTO) {
        this.id = dto.id;
        this.name = dto.name;
        this.lead = dto.lead ? new MemberMinList(dto.lead) : null;
        this.district = dto.district;
    }
}

export { GroupList };
