declare const ymaps: LocalAnyType;

import React, { useCallback, useEffect, useState } from 'react';
import { LocalAnyType } from '../../helpers/type.helper';
import { Root, MapWrap } from './map.styles';
import { defaultCoords } from '../../../modules/groups/pages/groups-list-on-map/constants';
import { Coords, SizeType } from '../../types/map';

export type MapObject = {
    coords: Coords | null;
    onClickCallback?: () => void;
    updateCoordsCallback?: (coords: Coords) => void;
    tooltipData?: {
        header: string;
        description: string;
    }
};

type MapComponentProps = {
    objects: MapObject[];
    disable?: boolean;
    size?: SizeType;
};

const MapComponent = ({ 
    objects, 
    disable, 
    size,
}: MapComponentProps) => {
    const [myMap, setMyMap] = useState<LocalAnyType>(null);

    useEffect(() => {
        if (myMap) {
            return;
        }
        ymaps.ready(initMap);
    }, []);

    const initMap = useCallback(() => {
        const map = new ymaps.Map('map-container', {
            center: objects.length > 1 
                ? defaultCoords 
                : objects[0].coords || defaultCoords,
            zoom: 10,
        });

        objects.forEach(object => {
            const coord = object.coords || defaultCoords;
            const tooltipData = object.tooltipData;
            
            const placemark = new ymaps.Placemark(
                coord,
                tooltipData
                    ? {
                        balloonContentHeader: tooltipData?.header,
                        balloonContentBody: tooltipData.description,
                        // balloonContentBody: `${tooltipData?.leaders}<br>${tooltipData?.day?.label}`,
                    }
                    : null,
                {
                    preset: 'islands#blueDotIcon',
                    draggable: !disable,
                    balloonOffset: [0, -40],
                    clickable: !!tooltipData,
                    visible: true,
                }
            );

            placemark.events.add('dragend', (e: LocalAnyType) => {
                const newCoords = e.get('target').geometry.getCoordinates();
                if (object.updateCoordsCallback) {
                    object.updateCoordsCallback(newCoords);
                }
            });

            placemark.events.add('click', () => {
                if (object.onClickCallback) {
                    object.onClickCallback();
                }
            });

            object.tooltipData && (
                placemark.events.add('mouseenter', () => placemark.balloon.open()),
                placemark.events.add('mouseleave', () => placemark.balloon.close())
            );
            
            map.geoObjects.add(placemark);
        });

        objects.length > 1 
            ? map.setBounds(map.geoObjects.getBounds()) 
            : map;
        setMyMap(map);
    }, [objects, disable]);

    return (
        <Root>
            <MapWrap id="map-container" {...{ width: size?.width, height: size?.height }}/>
        </Root>
    );
};

export default MapComponent;
