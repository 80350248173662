import { BaseActionResult } from '../../../../global/helpers/actions';
import { OVCohort } from '../../models/cohort/ov-cohort.model';

export class OVCohortState {
    cohorts: OVCohort[] = [];
    totalCount: number = 0;
    cohortCard: OVCohort | null = null;
}

export type UpdateOVCohortAction = {
    cohorts: OVCohort[];
    totalCount: number;
}

export enum OVCohortActionsEnum {
    updateOVCohorts = 'UPDATE_OV_COHORTS',
    updateOVCohortCard = 'UPDATE_OV_COHORT_CARD',
}

export type OVCohortActions =
    BaseActionResult<
    UpdateOVCohortAction,
        typeof OVCohortActionsEnum.updateOVCohorts
    >
    | BaseActionResult<
    OVCohort | null,
        typeof OVCohortActionsEnum.updateOVCohortCard
    >;
