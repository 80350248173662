import { css } from 'styled-components';
import { colors } from './variables';

const sharedButtonStyle = css`
    //font-family: Consolas, serif;
    position: relative;
    border: none;
    background-color: ${colors.buttonStaticBack};
    height: 35px;
    padding: 0 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: 16px;
    line-height: 18.73px;
    color: ${colors.buttonStaticColor};
    cursor: pointer;

    &:hover {
        background-color: ${colors.buttonHoverBack};
    }
`;

const scrollStyle = css`
    -webkit-overflow-scrolling: touch;

    /* total width */

    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #23282b;
    }

    /* background of the scrollbar except button or resizer */

    &::-webkit-scrollbar-track {
        background-color: #fff;
    }

    &::-webkit-scrollbar-track:hover {
        background-color: #fff;
    }

    /* scrollbar itself */

    &::-webkit-scrollbar-thumb {
        border: 0;
        border-radius: 16px;
        background-color: #eceff1;
    }

    &::-webkit-scrollbar-thumb:hover {
        border: 0;
        background-color: #eceff1;
    }

    /* set button(top and bottom of the scrollbar) */

    &::-webkit-scrollbar-button {
        display: none;
    }
`;

export {
    sharedButtonStyle,
    scrollStyle,
};
