import { AnyAction, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
    BaseActionResult,
} from '../../helpers/actions';
import { toggleGlobalSpinner } from '../../redux/global/global.actions';
import { StateModel } from '../../redux/state.model';

export function useSpinner(
    dispatch: Dispatch,
) {
    const startCallback = async <R = void>(createdAction: ThunkAction<
        Promise<BaseActionResult<R> | null>,
        StateModel,
        null,
        BaseActionResult<R>
    >) => {
        const actionStartSpinner = toggleGlobalSpinner(true) as unknown as AnyAction;
        const actionStopSpinner = toggleGlobalSpinner(false) as unknown as AnyAction;
        dispatch(actionStartSpinner);
        try {
            // @ts-expect-error
            await dispatch(createdAction);
        } finally {
            dispatch(actionStopSpinner);
        }
    };

    return startCallback;
}
