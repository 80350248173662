import { toast } from 'react-toastify';
import { baseAsyncActionCreator } from '../../../global/helpers/actions';
import { goToState } from '../../../global/helpers/url-parse.helper';
import { GlobalActionsEnum } from '../../../global/redux/global/global.types';
import { GroupCard } from '../models/group-card.model';
import { GroupsRepository } from '../repositories/groups.repository';
import { CreateGroupParams } from '../types/create-group-params';
import { GetGroupListBodyParams } from '../types/get-group-list-body-params';
import { GetGroupListQueryParams } from '../types/get-group-list-query-params';
import { GroupsActionsEnum, UpdateGroupsAction } from './groups.types';
import { QueryParamsType } from '../../../global/repository/base.repository';

const updateGroups = (queryParams: GetGroupListQueryParams, bodyData: GetGroupListBodyParams) =>
    baseAsyncActionCreator<UpdateGroupsAction>(async () => {
        const result = await GroupsRepository.getList(queryParams, bodyData);
        return [GroupsActionsEnum.updateGroups, {
            groups: result.items,
            totalCount: result.totalCount,
        }];
    });

const updateAllGroups = (queryParams?: QueryParamsType) =>
    baseAsyncActionCreator<GroupCard[]>(async () => {
        const result = await GroupsRepository.getAllGroups(queryParams);
        return [
            GroupsActionsEnum.updateAllGroups,
            result,
        ];
    });

const clearGroupCard = () =>
    baseAsyncActionCreator<GroupCard | null>(async () => {
        return [GroupsActionsEnum.updateGroupCard, null];
    });

const loadGroupCard = (id: number) =>
    baseAsyncActionCreator<GroupCard | null>(async () => {
        const result = await GroupsRepository.getCard(id);
        return [GroupsActionsEnum.updateGroupCard, result];
    });

const createGroupCard = (params: CreateGroupParams) =>
    baseAsyncActionCreator<string>(async () => {
        await GroupsRepository.create(params);
        toast.success('Запись успешно добавлена!');
        goToState('/groups');
        return [GlobalActionsEnum.empty, ''];
    });

const updateGroupCard = (id: number, params: CreateGroupParams) =>
    baseAsyncActionCreator<string>(async () => {
        await GroupsRepository.update(id, params);
        toast.success('Запись успешно обновлена!');
        goToState(`/group/${id}`);
        return [GlobalActionsEnum.empty, ''];
    });

const deleteGroupCard = (id: number) =>
    baseAsyncActionCreator<string>(async () => {
        await GroupsRepository.delete(id);
        toast.success('Запись успешно удалена!');
        return [GlobalActionsEnum.empty, ''];
    });

export {
    updateGroups,
    clearGroupCard,
    loadGroupCard,
    createGroupCard,
    updateGroupCard,
    deleteGroupCard,
    updateAllGroups,
};
