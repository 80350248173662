import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { match } from 'react-router-dom';
import { TextInput } from '../../../global/components/form-controls/text-input/text-input';
import { InfoItem } from '../../../global/components/info-item';
import { InfoLine } from '../../../global/components/info-line';
import { parseDateForApi } from '../../../global/helpers/date.helper';
import { goToState } from '../../../global/helpers/url-parse.helper';
import { useSpinner } from '../../../global/hooks/use-spinner';
import {
    familyStatusOptions,
    MemberFamilyStatusItem,
} from '../../../modules/members/models/member-family-status.model';
import {
    MemberSexItem,
    sexOptions,
} from '../../../modules/members/models/member-sex.model';
import { registrationMember } from '../../../modules/members/redux/members.actions';
import { RegistrationMemberParams } from '../../../modules/members/types/registration-member-params';
import { HashRepository } from '../../repositories/hash.repository';
import { Footer, Header, Root } from './registration.styles';
import { Button } from '../../../global/components/button';
import { TextInputMask } from '../../../global/components/form-controls/text-input-mask/text-input-mask';
import { BottomPadding } from '../../../global/components/main-wrap/main-wrap.styles';
import {
    Avatar,
    AvatarActionWrap,
    FileUploadInput,
    FileUploadLabel,
} from '../../../modules/members/pages/card/member-card.styles';
import { toast } from 'react-toastify';
import { Calendar } from 'primereact/calendar';
import { useEventValue } from '../../../global/hooks/use-event-value';

type RegistrationPageRouteParams = {
    hash: string;
};

type RegistrationProp = {
    match: match<RegistrationPageRouteParams>;
};

const Registration = (props: RegistrationProp) => {
    const dispatch = useDispatch();
    const spinnerRunner = useSpinner(dispatch);
    const [hashIsAvailable, setHashIsAvailable] = useState<boolean>(false);
    const [successRegistration, setSuccessRegistration] =
        useState<boolean>(false);
    const [firstName, setFirstName] = useState<string | null>(null);
    const [lastName, setLastName] = useState<string | null>(null);
    const [patronymic, setPatronymic] = useState<string | null>(null);
    const [phone, setPhone] = useState<string | null>(null);
    const [sex, setSex] = useState<MemberSexItem | null>(null);
    const [email, setEmail] = useState<string | null>(null);
    const [birthDay, setBirthDay] = useState<Date | null>(null);
    const [birthPlace, setBirthPlace] = useState<string | null>(null);
    const [familyStatus, setFamilyStatus] =
        useState<MemberFamilyStatusItem | null>(null);
    const [skills, setSkills] = useState<string | null>(null);
    const [repentanceYear, setRepentanceYear] = useState<string | null>(null);
    const [repentancePlace, setRepentancePlace] = useState<string | null>(null);
    const [baptismYear, setBaptismYear] = useState<string | null>(null);
    const [witnessesDate, setWitnessesDate] = useState<Date | null>(null);
    const [avatar, setAvatar] = useState<File | null>(null);
    const [avatarString, setAvatarString] = useState<string | null>(null);
    const [aboutMe, setAboutMe] = useState<string | null>(null);
    const handleBirthDateChange = useEventValue(setBirthDay);
    const handleWitnessesDateChange = useEventValue(setWitnessesDate);

    useEffect(() => {
        if (!props.match.params.hash) {
            return;
        }
        HashRepository.check(props.match.params.hash)
            .then(() => setHashIsAvailable(true))
            .catch(() => goToState('/'));
    }, [props.match.params.hash]);

    const isValidForm = useMemo(
        () =>
            !!firstName &&
            !!lastName &&
            !!phone &&
            phone.indexOf('_') === -1 &&
            !!sex &&
            !!email &&
            !!birthDay &&
            !!birthPlace &&
            !!familyStatus &&
            !!repentanceYear &&
            !!baptismYear &&
            !!witnessesDate,
        [
            firstName,
            lastName,
            phone,
            sex,
            email,
            birthDay,
            birthPlace,
            familyStatus,
            repentanceYear,
            baptismYear,
            witnessesDate,
        ],
    );

    const registrationCallback = useCallback(async () => {
        if (
            !firstName ||
            !lastName ||
            !phone ||
            phone.indexOf('_') !== -1 ||
            !sex ||
            !email ||
            !birthDay ||
            !birthPlace ||
            !familyStatus ||
            !repentanceYear ||
            !baptismYear ||
            !witnessesDate ||
            !props.match.params.hash
        ) {
            return;
        }
        const params: RegistrationMemberParams = {
            hash: props.match.params.hash,
            firstName,
            lastName,
            patronymic,
            phone,
            email,
            sex: sex.value,
            birthDay: parseDateForApi(birthDay),
            birthPlace,
            familyStatus: familyStatus?.value ?? null,
            skills,
            repentanceYear,
            repentancePlace,
            aboutMe,
            baptismYear,
            witnessesDate: parseDateForApi(witnessesDate),
        };
        await spinnerRunner(registrationMember(params, avatar));
        setSuccessRegistration(true);
    }, [
        spinnerRunner,
        registrationMember,
        firstName,
        lastName,
        phone,
        sex,
        email,
        birthDay,
        birthPlace,
        familyStatus,
        repentanceYear,
        baptismYear,
        witnessesDate,
        parseDateForApi,
        props.match.params.hash,
        avatar,
        aboutMe,
    ]);

    const handleAvatarToUpload = useCallback(
        async (e: React.ChangeEvent<HTMLInputElement>) => {
            const filesList = e.currentTarget.files;
            const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
            if (!allowedExtensions.exec(e.currentTarget.value)) {
                toast.error(
                    'Недопустимый формат файла (допустимые форматы: .jpg .jpeg .png .gif)',
                );
                e.currentTarget.value = '';
                return;
            }

            if (filesList) {
                if (filesList[0].size > 1024 * 1024 * 2) {
                    toast.error(
                        'Слишком большой файл (необходимо не больше 2МБ)',
                    );
                    e.currentTarget.value = '';
                    return;
                }
                setAvatar(filesList[0]);
                const reader = new FileReader();
                reader.readAsDataURL(filesList[0]);
                reader.onload = function () {
                    setAvatarString(`${reader.result}`);
                };
            }
        },
        [],
    );

    const deleteAvatarCallback = useCallback(() => {
        setAvatar(null);
        setAvatarString(null);
    }, []);

    if (!hashIsAvailable) {
        return <Root>У вас нет доступа к этой странице</Root>;
    }

    if (successRegistration) {
        return <Root>Спасибо! Вы успешно зарегистрированы в системе!</Root>;
    }

    return (
        <Root>
            <Header></Header>
            <InfoLine>
                <InfoItem
                    title="Фамилия *"
                    value={
                        <TextInput value={lastName} onChange={setLastName} />
                    }
                />
                <InfoItem
                    title="Имя *"
                    value={
                        <TextInput value={firstName} onChange={setFirstName} />
                    }
                />
                <InfoItem
                    title="Отчество"
                    value={
                        <TextInput
                            value={patronymic}
                            onChange={setPatronymic}
                        />
                    }
                />
            </InfoLine>
            <InfoLine>
                <InfoItem
                    title="Телефон *"
                    value={
                        <TextInputMask
                            value={phone}
                            mask="+99999999999"
                            onChange={setPhone}
                        />
                    }
                />
                <InfoItem
                    title="E-mail *"
                    value={<TextInput value={email} onChange={setEmail} />}
                />
            </InfoLine>
            <InfoLine>
                <InfoItem
                    title="Пол *"
                    value={
                        <Select
                            options={sexOptions}
                            defaultValue={sex}
                            onChange={setSex}
                            isClearable
                        />
                    }
                />
                <InfoItem
                    title="Дата рождения *"
                    value={
                        <Calendar
                            locale={'ru'}
                            className="local-datapicker"
                            value={birthDay}
                            onChange={handleBirthDateChange}
                            dateFormat={'dd.mm.yy'}
                        />
                    }
                />
                <InfoItem
                    title="Место рождения *"
                    value={
                        <TextInput
                            value={birthPlace}
                            onChange={setBirthPlace}
                        />
                    }
                />
                <InfoItem
                    title="Семейное положение *"
                    value={
                        <Select
                            options={familyStatusOptions}
                            defaultValue={familyStatus}
                            onChange={setFamilyStatus}
                            isClearable
                        />
                    }
                />
            </InfoLine>
            <InfoItem
                title="Способности"
                value={
                    <TextInput value={skills} onChange={setSkills} asTextarea />
                }
            />
            <InfoItem
                title="О себе"
                value={
                    <TextInput
                        value={aboutMe}
                        onChange={setAboutMe}
                        asTextarea
                    />
                }
            />
            <InfoLine>
                <InfoItem
                    title="Год покаяния *"
                    value={
                        <TextInput
                            value={repentanceYear}
                            onChange={setRepentanceYear}
                        />
                    }
                />
                <InfoItem
                    title="Место покаяния"
                    value={
                        <TextInput
                            value={repentancePlace}
                            onChange={setRepentancePlace}
                        />
                    }
                />
                <InfoItem
                    title="Год крещения *"
                    value={
                        <TextInput
                            value={baptismYear}
                            onChange={setBaptismYear}
                        />
                    }
                />
                <InfoItem
                    title="Дата рассказывания свидетельства *"
                    value={
                        <Calendar
                            locale={'ru'}
                            className="local-datapicker"
                            value={witnessesDate}
                            onChange={handleWitnessesDateChange}
                            dateFormat={'dd.mm.yy'}
                        />
                    }
                />
            </InfoLine>
            {avatarString && <Avatar src={avatarString} />}
            <AvatarActionWrap>
                <FileUploadLabel htmlFor="avatar-uploading">
                    <Button
                        title={avatar ? 'Обновить фото' : 'Загрузить фото'}
                    />
                </FileUploadLabel>
                {!!avatar && (
                    <Button
                        title="Удалить фото"
                        onClick={deleteAvatarCallback}
                    />
                )}
                <FileUploadInput
                    type="file"
                    id="avatar-uploading"
                    multiple={false}
                    onChange={handleAvatarToUpload}
                />
            </AvatarActionWrap>
            <Footer>
                <Button
                    title="Зарегистрироваться"
                    onClick={registrationCallback}
                    disabled={!isValidForm}
                />
            </Footer>
            <BottomPadding />
        </Root>
    );
};

export default Registration;
