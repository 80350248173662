import * as t from 'io-ts';
import { ResourcesPlatform, ResourcesPlatformItem } from './resources-platform.model';
import { ResourcesType, ResourcesTypeItem } from './resources-type.model';

export const ResourcesListType = t.type({
    id: t.number,
    name: t.string,
    type: t.string,
    platform: t.string,
});

export type ResourcesListDTO = t.TypeOf<typeof ResourcesListType>;

class ResourcesList {
    id: number;
    name: string;
    type: ResourcesTypeItem;
    platform: ResourcesPlatformItem;

    constructor(dto: ResourcesListDTO) {
        this.id = dto.id;
        this.name = dto.name;
        this.type = dto.type && ResourcesType[dto.type] ? ResourcesType[dto.type] : ResourcesType.internal;
        this.platform = dto.platform && ResourcesPlatform[dto.platform] ? ResourcesPlatform[dto.platform] : ResourcesPlatform.web;
    }
}

export { ResourcesList };
