import * as t from 'io-ts';
import { parseDateFromApi } from '../../../global/helpers/date.helper';

export const PaymentType = t.type({
    date: t.string,
});

export type PaymentDTO = t.TypeOf<typeof PaymentType>;

class Payment {
    date: Date | null;

    constructor(dto: PaymentDTO) {
        this.date = parseDateFromApi(dto.date);
    }
}

export { Payment };
