import * as t from 'io-ts';
import { FamilyListType } from './family-list.model';
import { MemberList, MemberListType } from '../../members/models/member-list.model';
import { parseDateFromApi } from '../../../global/helpers/date.helper';

export const FamilyCardType = t.intersection([
    FamilyListType,
    t.type({
        childCount: t.union([t.number, t.null]),
        members: t.array(MemberListType),
        weddingDay: t.union([t.string, t.undefined, t.number, t.null]),
    })
]);

export type FamilyCardDTO = t.TypeOf<typeof FamilyCardType>;

class FamilyCard {
    id: number;
    name: string;
    childCount: number;
    members: MemberList[];
    weddingDay: Date | null;

    constructor(dto: FamilyCardDTO) {
        this.id = dto.id;
        this.name = dto.name;
        this.childCount = dto.childCount || 0;
        this.members = dto.members.map(el => new MemberList(el));
        this.weddingDay = dto.weddingDay ? parseDateFromApi(dto.weddingDay) : null;
    }
}

export { FamilyCard };
