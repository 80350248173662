export type ResourcesTypeItem = {
    label: string;
    value: string;
};

type ResourcesTypeType = {
    [key: string]: ResourcesTypeItem;
};

export const ResourcesType: ResourcesTypeType = {
    external: {
        label: 'Внешний',
        value: 'external',
    },
    internal: {
        label: 'Внутренний',
        value: 'internal',
    },
};

export const resourcesTypeOptions = Object.values(ResourcesType);
