import { BaseActionResult } from '../../../global/helpers/actions';
import { ChatInfoCard } from '../models/chat-info-card.model';
import { ChatInfoList } from '../models/chat-info-list.model';

export class ChatInfoState {
    chatInfo: ChatInfoList[] = [];
    totalCount: number = 0;
    chatInfoCard: ChatInfoCard | null = null;
}

export type UpdateChatInfoAction = {
    chatInfo: ChatInfoList[];
    totalCount: number;
}

export enum ChatInfoActionsEnum {
    updateChatInfo = 'UPDATE_CHAT_INFO',
    updateChatInfoCard = 'UPDATE_CHAT_INFO_CARD',
}

export type ChatInfoActions =
    BaseActionResult<
        UpdateChatInfoAction,
        typeof ChatInfoActionsEnum.updateChatInfo
    >
    | BaseActionResult<
        ChatInfoCard | null,
        typeof ChatInfoActionsEnum.updateChatInfoCard
    >;
