export type UserPermissionsItem = {
    label: string;
    value: string;
};

type UserPermissionsType = {
    [key: string]: UserPermissionsItem | undefined;
    
    OV: UserPermissionsItem;
    MEMBERS: UserPermissionsItem;
    MESSAGES: UserPermissionsItem;
    SERVICE: UserPermissionsItem;
    PASTOR: UserPermissionsItem;
};

export const UserPermissions: UserPermissionsType = {
    OV: {
        label: 'Основы веры',
        value: 'OV',
    },
    MEMBERS: {
        label: 'Участники',
        value: 'MEMBERS',
    },
    MESSAGES: {
        label: 'Рассылки',
        value: 'MESSAGES',
    },
    SERVICE: {
        label: 'Воскресное служение',
        value: 'SERVICE',
    },
    PASTOR: {
        label: 'Пасторское служение',
        value: 'PASTOR',
    },
};

export const userPermissionsOptions = Object.values(UserPermissions).filter(Boolean) as UserPermissionsItem[];
