import { createSelector, Selector } from 'reselect';
import { StateModel } from '../../../global/redux/state.model';
import { ResourcesCard } from '../models/resources-card.model';
import { ResourcesList } from '../models/resources-list.model';
import { ResourcesState } from './resources.types';

export const getResourcesState = (state: StateModel): ResourcesState => state.resources;

export const getResourcesList: Selector<StateModel, ResourcesList[]> =
    createSelector(
        [getResourcesState],
        (resourcesState) => resourcesState.resources,
    );

export const getResourcesTotalCount: Selector<StateModel, number> =
    createSelector(
        [getResourcesState],
        (resourcesState) => resourcesState.totalCount,
    );

export const getResourcesCard: Selector<StateModel, ResourcesCard | null> =
    createSelector(
        [getResourcesState],
        (resourcesState) => resourcesState.resourcesCard,
    );
