import * as t from 'io-ts';
import { ChatInfoList, ChatInfoListType } from '../../chat-info/models/chat-info-list.model';
import { MemberMinList, MemberMinListType } from '../../members/models/member-min-list.model';
import { MinistryList, MinistryListType } from './ministry-list.model';

export const MinistryCardType = t.type({
    id: t.number,
    name: t.string,
    chat: t.union([ChatInfoListType, t.null]),
    description: t.union([t.string, t.null]),
    lead: MemberMinListType,
    assist: t.union([MemberMinListType, t.null]),
    parent: t.union([MinistryListType, t.null]),
    members: t.union([t.array(MemberMinListType), t.undefined]),
});

export type MinistryCardDTO = t.TypeOf<typeof MinistryCardType>;

class MinistryCard {
    id: number;
    name: string;
    chat: ChatInfoList | null;
    description: string | null;
    lead: MemberMinList;
    assist: MemberMinList | null;
    parent: MinistryList | null;

    constructor(dto: MinistryCardDTO) {
        this.id = dto.id;
        this.name = dto.name;
        this.chat = dto.chat ? new ChatInfoList(dto.chat) : null;
        this.description = dto.description;
        this.lead = new MemberMinList(dto.lead);
        this.assist = dto.assist ? new MemberMinList(dto.assist) : null;
        this.parent = dto.parent ? new MinistryList(dto.parent) : null;
    }
}

export { MinistryCard };
