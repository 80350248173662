import styled from 'styled-components/macro';
import { respondTo } from '../../styles/respond-to';

const Root = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    & > div {
        margin-right: 24px;
    }

    ${respondTo.sm`
        flex-direction: column;
    `}
`;

export { Root };
