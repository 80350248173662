import { toast } from 'react-toastify';
import { HashRepository } from '../../../auth/repositories/hash.repository';
import { baseAsyncActionCreator } from '../../../global/helpers/actions';
import { goToState } from '../../../global/helpers/url-parse.helper';
import { GlobalActionsEnum } from '../../../global/redux/global/global.types';
import { MemberCard } from '../models/member-card.model';
import { MembersRepository } from '../repositories/members.repository';
import { CreateMemberParams } from '../types/create-member-params';
import { GetMembersListBodyParams } from '../types/get-members-list-body-params';
import { RegistrationMemberParams } from '../types/registration-member-params';
import { MembersActionsEnum, UpdateMembersAction } from './members.types';
import { GetMessagesListQueryParams } from '../../daily-messages/types/get-messages-list-query-params';
import { AdoptedInfo } from '../models/adopted-info.model';
import { AdoptedInfoRepository } from '../repositories/adopted-info.repository';
import { CreateAdoptedInfoParams } from '../types/create-adopted-info-params';
import { SendListRepository } from '../../send-list/repositories/send-list.repository';

const updateMembers = (queryParams: GetMessagesListQueryParams, bodyData: GetMembersListBodyParams) =>
    baseAsyncActionCreator<UpdateMembersAction>(async () => {
        const result = await MembersRepository.getList(queryParams, bodyData);
        return [MembersActionsEnum.updateMembers, {
            members: result.items,
            totalCount: result.totalCount,
        }];
    });

const clearMemberCard = () =>
    baseAsyncActionCreator<MemberCard | null>(async () => {
        return [MembersActionsEnum.updateMemberCard, null];
    });

const clearAdoptedInfoCard = () =>
    baseAsyncActionCreator<AdoptedInfo | null>(async () => {
        return [MembersActionsEnum.updateAdoptedInfoCard, null];
    });

const loadMemberCard = (id: number) =>
    baseAsyncActionCreator<MemberCard | null>(async () => {
        const result = await MembersRepository.getCard(id);
        return [MembersActionsEnum.updateMemberCard, result];
    });

const loadAdoptedInfoCard = (id: number) =>
    baseAsyncActionCreator<AdoptedInfo | null>(async () => {
        let result: AdoptedInfo | null = null;
        try {
            result = await AdoptedInfoRepository.getCard(id);
        } catch {
            //
        }
        if (!result) {
            result = await AdoptedInfoRepository.create(id, {
                absenteeLetterStatus: null,
                interviewDate: null,
                interviewPastorsIds: [],
                interviewResult: null,
                interviewTime: null,
                ministryInfo: null,
                personalLeaderId: null,
                presentForChurchId: null,
                testimonyLink: null,
                testimonyRevision: null,
                testimonyStatus: null,
            });
        }
        return [MembersActionsEnum.updateAdoptedInfoCard, result];
    });

const createMemberCard = (params: CreateMemberParams) =>
    baseAsyncActionCreator<string>(async () => {
        await MembersRepository.create(params);
        toast.success('Запись успешно добавлена!');
        goToState('/members');
        return [GlobalActionsEnum.empty, ''];
    });

const registrationMember = (params: RegistrationMemberParams, avatar: File | null) =>
    baseAsyncActionCreator<string>(async () => {
        await HashRepository.registrationMember(params, avatar);
        toast.success('Спасибо! Вы успешно зарегистрированы в системе');
        return [GlobalActionsEnum.empty, ''];
    });

const updateMemberCard = (id: number, params: CreateMemberParams, adoptedInfoParams: CreateAdoptedInfoParams) =>
    baseAsyncActionCreator<string>(async () => {
        await MembersRepository.update(id, params);
        await AdoptedInfoRepository.update(id, adoptedInfoParams);
        toast.success('Запись успешно обновлена!');
        goToState(`/member/${id}`);
        return [GlobalActionsEnum.empty, ''];
    });

const deleteMemberCard = (id: number) =>
    baseAsyncActionCreator<string>(async () => {
        await MembersRepository.delete(id);
        toast.success('Запись успешно удалена!');
        return [GlobalActionsEnum.empty, ''];
    });

const savePastorNote = (id: number, pastorNote: string | null) =>
    baseAsyncActionCreator<string>(async () => {
        await MembersRepository.updatePastorNote(id, pastorNote);
        toast.success('Заметка успешно сохранена!');
        return [GlobalActionsEnum.empty, ''];
    });

const sendListProfiles = (bodyParams: GetMembersListBodyParams, message: string | null) =>
    baseAsyncActionCreator<string>(async () => {
        await SendListRepository.createSendListBySearchProfiles(bodyParams, message || '');
        toast.success('Рассылка успешно отправлена!');
        return [GlobalActionsEnum.empty, ''];
    });

const uploadNewAvatar = (id: number, file: File) =>
    baseAsyncActionCreator<string>(async () => {
        await MembersRepository.uploadNewAvatar(id, file);
        return null;
    });

const deleteAvatar = (id: number) =>
    baseAsyncActionCreator<string>(async () => {
        await MembersRepository.deleteAvatar(id);
        return null;
    });

export {
    updateMembers,
    clearMemberCard,
    loadMemberCard,
    createMemberCard,
    updateMemberCard,
    deleteMemberCard,
    registrationMember,
    uploadNewAvatar,
    deleteAvatar,
    savePastorNote,
    clearAdoptedInfoCard,
    loadAdoptedInfoCard,
    sendListProfiles,
};
