export type InterviewResultItem = {
    label: string;
    value: string;
};

type InterviewResultType = {
    [key: string]: InterviewResultItem;
};

export const InterviewResult: InterviewResultType = {
    SUCCESS: {
        label: 'Прошёл',
        value: 'SUCCESS',
    },
    REPEAT: {
        label: 'Повтор',
        value: 'REPEAT',
    },
    FAIL: {
        label: 'Не прошёл',
        value: 'FAIL',
    },
};

export const interviewResultOptions = Object.values(InterviewResult);
