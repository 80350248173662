export type ResourcesRestrictionItem = {
    label: string;
    value: string;
};

type ResourcesRestrictionType = {
    [key: string]: ResourcesRestrictionItem;
};

export const ResourcesRestriction: ResourcesRestrictionType = {
    M: {
        label: 'Только для братьев',
        value: 'M',
    },
    F: {
        label: 'Только для сестёр',
        value: 'F',
    },
    MINISTRY_LEAD: {
        label: 'Для лидеров служений',
        value: 'MINISTRY_LEAD',
    },
};

export const resourcesRestrictionOptions = Object.values(ResourcesRestriction);
