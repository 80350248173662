import { BaseActionResult } from '../../../../global/helpers/actions';
import { OVRegistration } from '../../models/registration/ov-registration.model';

export class OVRegistrationState {
    registrations: OVRegistration[] = [];
    totalCount: number = 0;
}

export type UpdateOVRegistrationAction = {
    registrations: OVRegistration[];
    totalCount: number;
}

export enum OVRegistrationActionsEnum {
    updateOVRegistrations = 'UPDATE_OV_REGISTRATIONS',
}

export type OVRegistrationActions =
    BaseActionResult<
    UpdateOVRegistrationAction,
        typeof OVRegistrationActionsEnum.updateOVRegistrations
    >;
