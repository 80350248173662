import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { setPositionPopupByClick } from '../../helpers/elements';

import { ContextMenuItem, ContextMenuItemLeftSpace, ContextMenuModeEnum, ContextMenuValueContext } from './context';
import {
    ChildContextMenuWrap,
    ContextMenuDivider,
    ContextMenuItemBlock,
    ContextMenuWrap,
    ItemTitle,
    LeftIcon,
    RightLabel,
} from './context-menu.styles';
import { ArrowRightIcon } from './icons';

type ContextMenuRowItemProps = {
    item: ContextMenuItem;
    withRightPadding?: boolean;
    withLeftPadding?: boolean;
    isChildMenu?: boolean;
    modeClassName: string;
    level: number;
    leftSpace?: ContextMenuItemLeftSpace;
    closeCallback?: () => void;
};

const ContextMenuRowItem: React.FC<ContextMenuRowItemProps> = ({
    item,
    closeCallback,
    withRightPadding,
    withLeftPadding,
    isChildMenu,
    modeClassName,
    level,
    leftSpace,
}) => {
    const [id] = useState(uuidv4());
    const [showChildMenu, setShowChildMenu] = useState<boolean>(false);
    const childMenuRef = useRef<HTMLDivElement>(null);
    const menuItemRef = useRef<HTMLDivElement>(null);
    const [childMenuStyle, setChildMenuStyle] = useState<React.CSSProperties>({});

    const [hideChildMenuTimeoutId, setHideChildMenuTimeoutId] = useState<number | null>(null);
    const isChildrenExist = item.child?.length ? item.child?.some( child => !child.hide ) : false;

    useEffect(() => {
        // если хотим скрыть дочернее меню
        if (!showChildMenu || !childMenuRef.current || !menuItemRef.current) {
            // запланируем скрытие дочернего меню
            setHideChildMenuTimeoutId(
                window.setTimeout(() => {
                    setChildMenuStyle({});
                }, 300),
            );
            return;
        }
        // если хотим показать дочернее меню

        // если было запланировано его скрытие,отменим планы
        if (hideChildMenuTimeoutId) {
            window.clearTimeout(hideChildMenuTimeoutId);
            setHideChildMenuTimeoutId(null);
        }
        // если у этого пункта меню есть дочерние пункты
        // насильно скроем дочерние меню у всех остальных пунктов на этом же уровне
        if (item.child?.length) {
            const allChildMenuByLevel = document.getElementsByClassName(`child-context-menu-wrap-${level}`);
            for (let i = 0; i < allChildMenuByLevel.length; i++) {
                if (allChildMenuByLevel[i].id !== id) {
                    // @ts-ignore
                    allChildMenuByLevel[i].style.top = '-10000px';
                    // @ts-ignore
                    allChildMenuByLevel[i].style.left = '-10000px';
                }
            }
        }
        const horizontalDelta: number = 8;
        const verticalDelta: number = 5;
        const boundMenu = childMenuRef.current.getBoundingClientRect();
        const boundItem = menuItemRef.current.getBoundingClientRect();
        const style: React.CSSProperties = {
            left: `calc(100% - ${horizontalDelta}px)`,
            top: `${verticalDelta}px`,
        };
        if (boundItem.right - horizontalDelta + boundMenu.width > window.innerWidth) {
            style.left = 'initial';
            style.right = `calc(100% - ${horizontalDelta}px)`;
        }
        if (boundItem.top + verticalDelta + boundMenu.height > window.innerHeight) {
            style.top ='initial';
            style.bottom = `${verticalDelta}px`;
        }
        setChildMenuStyle(style);
    }, [showChildMenu]);

    const itemCallback = useCallback(async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        if (item.disabled) {
            return;
        }

        // просто перейти по ссылке
        if (item.href && !item.download) {
            window.location.href = item.href;
        }

        // просто вызвать колбэк по клику
        if (item.onClick) {
            await item.onClick();
        }

        // закрываем меню, если не запрещено
        if (closeCallback && !item.withoutCloseMenu) {
            closeCallback();
        }
    }, [item.onClick, item.withoutCloseMenu, closeCallback]);

    const itemBlockCssClass = classNames(
        {
            'is-disabled': item.disabled,
            'is-child-menu': !!isChildMenu,
            'with-left-icon': !!withLeftPadding,
            'with-right-padding': !!withRightPadding,
        },
        modeClassName,
        `left-space-${leftSpace ?? 0}`,
    );

    const iconCssClass = classNames(item.labelClassName, {
        'is-disabled': item.disabled,
        'has-hot-key': !!item.hasHotKey,
    });

    const onMouseEnter = useCallback(() => {
        if (!isChildrenExist) {
            return;
        }
        setShowChildMenu(true);
    }, [isChildrenExist, setShowChildMenu]);

    const onMouseLeave = useCallback(() => {
        if (!isChildrenExist) {
            return;
        }
        setShowChildMenu(false);
    }, [isChildrenExist, setShowChildMenu]);

    const childWithLeftPadding = useMemo(
        () => (item.child || []).some((el) => el.checkboxFlag !== undefined || el.leftIcon),
        [item.child],
    );
    const childWithRightPadding = useMemo(() => (item.child || []).some((el) => !!el.rightLabel), [item.child]);

    const childMenuClassName = `${modeClassName} child-context-menu-wrap-${level}`;

    if (item.hide) {
        return null;
    }
    return (
        <>
            <ContextMenuItemBlock
                className={itemBlockCssClass}
                onClick={itemCallback}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                id={item.id}
                ref={menuItemRef}
            >
                <ItemTitle>{item.title}</ItemTitle>
                {!!item.leftIcon && <LeftIcon className={iconCssClass}>{item.leftIcon}</LeftIcon>}
                {!!item.rightLabel && <RightLabel className={iconCssClass} style={item.rightLabelStyle}>{item.rightLabel}</RightLabel>}
                {!!isChildrenExist && !item.disabled && (
                    <RightLabel>
                        <ArrowRightIcon />
                    </RightLabel>
                )}
                <ChildContextMenuWrap
                    ref={childMenuRef}
                    className={childMenuClassName}
                    id={id}
                    style={childMenuStyle}
                    onMouseEnter={onMouseEnter}
                >
                    {(item.child || []).map((item, index) => (
                        <ContextMenuRowItem
                            item={item}
                            key={index}
                            level={level + 1}
                            leftSpace={leftSpace}
                            withLeftPadding={childWithLeftPadding}
                            withRightPadding={childWithRightPadding}
                            closeCallback={closeCallback}
                            isChildMenu
                            modeClassName={modeClassName}
                        />
                    ))}
                </ChildContextMenuWrap>
            </ContextMenuItemBlock>
            {item.withDivider && <ContextMenuDivider />}
        </>
    );
};

const ContextMenu = memo(() => {
    const [style, setStyle] = useState<React.CSSProperties>({});
    const contextMenuRef = useRef<HTMLDivElement>(null);
    const contextMenuValue = useContext(ContextMenuValueContext);
    const [intervalId, setIntervalId] = useState<number>(0);

    useEffect(() => {
        if (contextMenuValue.isShow) {
            document.addEventListener('mousedown', closeMenu);
            document.addEventListener('wheel', closeMenu);
            startAnchorObserver();

            return () => {
                document.removeEventListener('mousedown', closeMenu);
                document.removeEventListener('wheel', closeMenu);
                stopAnchorObserver();
            };
        } else {
            document.removeEventListener('mousedown', closeMenu);
            document.removeEventListener('wheel', closeMenu);
            stopAnchorObserver();
        }
    }, [contextMenuValue.isShow]);

    const startAnchorObserver = () => {
        if (!contextMenuValue.anchorElement) {
            return;
        }
        // @ts-expect-error
        const startAnchorRect = contextMenuValue.anchorElement.getBoundingClientRect();
        // @ts-expect-error
        const startAnchorScrollTop = contextMenuValue.anchorElement.scrollTop;
        // @ts-expect-error
        const startAnchorScrollLeft = contextMenuValue.anchorElement.scrollLeft;
        const currentIntervalId = window.setInterval(() => {
            if (!contextMenuValue.closeCallback) {
                return;
            }
            if (!contextMenuValue.anchorElement) {
                window.clearInterval(currentIntervalId);
                setIntervalId(0);
                contextMenuValue.closeCallback();
                return;
            }
            // @ts-expect-error
            const currentAnchorRect = contextMenuValue.anchorElement?.getBoundingClientRect();
            // @ts-expect-error
            const currentAnchorScrollTop = contextMenuValue.anchorElement.scrollTop;
            // @ts-expect-error
            const currentAnchorScrollLeft = contextMenuValue.anchorElement.scrollLeft;
            if (
                currentAnchorRect.top !== startAnchorRect.top ||
                currentAnchorRect.left !== startAnchorRect.left ||
                currentAnchorRect.right !== startAnchorRect.right ||
                currentAnchorRect.bottom !== startAnchorRect.bottom ||
                startAnchorScrollTop !== currentAnchorScrollTop ||
                startAnchorScrollLeft !== currentAnchorScrollLeft
            ) {
                window.clearInterval(currentIntervalId);
                setIntervalId(0);
                contextMenuValue.closeCallback();
            }
        }, 300);
        setIntervalId(currentIntervalId);
    };
    const stopAnchorObserver = () => {
        if (!contextMenuValue.anchorElement || !intervalId) {
            return;
        }
        window.clearInterval(intervalId);
        setIntervalId(0);
    };

    useEffect(() => {
        if (!contextMenuRef.current) {
            setStyle({});
            return;
        }
        const customStyles: React.CSSProperties = {};
        if (contextMenuValue.maxHeight) {
            customStyles.maxHeight = contextMenuValue.maxHeight;
            customStyles.overflow = 'auto';
        }
        if (contextMenuValue.width) {
            customStyles.width = contextMenuValue.width;
        }
        const findPosition =
            setPositionPopupByClick(
                contextMenuRef.current,
                contextMenuValue.clickCoordinates,
            ) || {};
        setStyle({
            ...findPosition,
            ...customStyles,
        });
    }, [
        contextMenuValue.clickCoordinates,
        contextMenuValue.maxHeight,
        contextMenuRef.current,
        contextMenuValue.width,
    ]);

    const closeMenu = (event: Event) => {
        if (!contextMenuValue.closeCallback || !event) {
            return;
        }
        if (contextMenuRef.current && !contextMenuRef.current.contains(event.target as Node)) {
            contextMenuValue.closeCallback();
        }
    };

    const withRightPadding = useMemo(
        () =>
            (contextMenuValue.items || []).some(
                (el) => !!el.rightLabel || !!el.hasHotKey || !!el.child?.length,
            ),
        [contextMenuValue.items],
    );

    const isMainMenuMode = contextMenuValue.mode === ContextMenuModeEnum.mainMenu;

    // Лёша в src/index.tsx запретил событие wheel на window, приходится вот так разгребаться
    const onWheel = useCallback((e: React.WheelEvent<HTMLDivElement>) => e.stopPropagation(), []);

    const stopPropagation = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    return (
        <ContextMenuWrap
            style={style}
            ref={contextMenuRef}
            onWheel={onWheel}
            className="context-menu"
            data-qa="context-menu"
            onMouseMove={stopPropagation}
        >
            {(contextMenuValue.items || []).map((item, index) => (
                <ContextMenuRowItem
                    item={item}
                    key={index}
                    modeClassName={classNames( ( !!item.leftIcon || item.checkboxFlag !== undefined ) && 'with-left-icon', isMainMenuMode && 'main-menu-mode' )}
                    withRightPadding={withRightPadding}
                    withLeftPadding={!!item.leftIcon || item.checkboxFlag !== undefined}
                    closeCallback={contextMenuValue.closeCallback}
                    data-qa={`context-menu-row-item-${index}`}
                    leftSpace={item.leftSpace}
                    level={0}
                />
            ))}
        </ContextMenuWrap>
    );
});

export default ContextMenu;
