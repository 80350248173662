import { api } from '../../../config/api';
import { BaseRepository, ResolverMethods } from '../../../global/repository/base.repository';
import { apiHost } from '../../../params';
import { UserCard, UserCardDTO, UserCardType } from '../models/user-card.model';
import { UserList, UserListDTO, UserListType } from '../models/user-list.model';
import { CreateUserParams } from '../types/create-user-params';
import { GetUserListBodyParams } from '../types/get-user-list-body-params';
import { GetUserListQueryParams } from '../types/get-user-list-query-params';

export class UsersRepository extends BaseRepository {
    static defaultUrl = `${apiHost}/api/v1/users/`;
    static api = api;

    static getList(queryParams: GetUserListQueryParams, bodyData: GetUserListBodyParams) {
        return UsersRepository.resolveCollection<UserListDTO, UserList>({
            url: '',
            method: ResolverMethods.post,
            queryParams,
            bodyData,
            model: UserList,
            modelType: UserListType,
        });
    };

    static create(bodyData: CreateUserParams) {
        return UsersRepository.resolve<UserCardDTO, UserCard>({
            url: 'create',
            method: ResolverMethods.post,
            bodyData,
            model: UserCard,
            modelType: UserCardType,
        });
    };

    static createList() {
        return UsersRepository.resolveWithoutModel({
            url: 'create-list',
            method: ResolverMethods.post,
        });
    };

    static getCard(id: number) {
        return UsersRepository.resolve<UserCardDTO, UserCard>({
            url: `${id}/`,
            model: UserCard,
            modelType: UserCardType,
        });
    };

    static update(id: number, bodyData: CreateUserParams) {
        return UsersRepository.resolve<UserCardDTO, UserCard>({
            url: `${id}/update`,
            method: ResolverMethods.post,
            bodyData,
            model: UserCard,
            modelType: UserCardType,
        });
    };

    static delete(id: number) {
        return UsersRepository.resolveWithoutModel<void>({
            url: `${id}/delete`,
            method: ResolverMethods.delete,
        });
    };
}
