import styled from 'styled-components/macro';

const Root = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 24px;

    & > div, & > label {
        margin-right: 12px;
    }
`;

export { Root };
