import styled from 'styled-components/macro';
import { colors } from '../../../styles/variables';

const Wrapper = styled.div``;

const Description = styled.div`
    margin-top: 3px;
    margin-left: 3px;
    font-size: 10px;
    color: ${colors.GreyLighten1};
`;

const CurrentInput = styled.input`
    max-width: 100%;
    height: 38px;
    box-sizing: border-box;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 4px;
    padding-left: 8px;
    padding-right: 8px;

    &:focus,
    &:focus-visible,
    &:active {
        border-color: #2684ff;
        box-shadow: 0 0 0 1px #2684ff;
        outline: none;
    }
`;

const CurrentTextarea = styled.textarea`
    max-width: 100%;
    width: 300px;
    height: 100px;
    box-sizing: border-box;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 4px;
    padding: 8px;

    &:focus,
    &:focus-visible,
    &:active {
        border-color: #2684ff;
        box-shadow: 0 0 0 1px #2684ff;
        outline: none;
    }
`;

export { Wrapper, Description, CurrentInput, CurrentTextarea };
