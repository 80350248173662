import { toast } from 'react-toastify';
import { baseAsyncActionCreator } from '../../../global/helpers/actions';
import { goToState } from '../../../global/helpers/url-parse.helper';
import { GlobalActionsEnum } from '../../../global/redux/global/global.types';
import { ChatInfoCard } from '../models/chat-info-card.model';
import { ChatInfoRepository } from '../repositories/chat-info.repository';
import { GetChatInfoListBodyParams } from '../types/get-chat-info-list-body-params';
import { GetChatInfoListQueryParams } from '../types/get-chat-info-list-query-params';
import { UpdateLinkParams } from '../types/update-link-params';
import { ChatInfoActionsEnum, UpdateChatInfoAction } from './chat-info.types';

const updateChatInfoList = (queryParams: GetChatInfoListQueryParams, bodyData: GetChatInfoListBodyParams) =>
    baseAsyncActionCreator<UpdateChatInfoAction>(async () => {
        const result = await ChatInfoRepository.getList(queryParams, bodyData);
        return [ChatInfoActionsEnum.updateChatInfo, {
            chatInfo: result.items,
            totalCount: result.totalCount,
        }];
    });

const clearChatInfoCard = () =>
    baseAsyncActionCreator<ChatInfoCard | null>(async () => {
        return [ChatInfoActionsEnum.updateChatInfoCard, null];
    });

const loadChatInfoCard = (id: number) =>
    baseAsyncActionCreator<ChatInfoCard | null>(async () => {
        const result = await ChatInfoRepository.getCard(id);
        return [ChatInfoActionsEnum.updateChatInfoCard, result];
    });

const updateLinks = (chatInfoId: number, params: UpdateLinkParams, withoutRedirect?: boolean) =>
    baseAsyncActionCreator<string>(async () => {
        await ChatInfoRepository.updateLink(chatInfoId, params);
        toast.success('Ссылки успешно обновлены!');
        if (!withoutRedirect) {
            goToState('/chat-info');
        }
        return [GlobalActionsEnum.empty, ''];
    });

export {
    updateChatInfoList,
    clearChatInfoCard,
    loadChatInfoCard,
    updateLinks,
};
