import { toast } from 'react-toastify';
import { baseAsyncActionCreator } from '../../../global/helpers/actions';
import { goToState } from '../../../global/helpers/url-parse.helper';
import { GlobalActionsEnum } from '../../../global/redux/global/global.types';
import { SettingsCard } from '../models/settings-card.model';
import { SettingsRepository } from '../repositories/settings.repository';
import { CreateSettingsParams } from '../types/create-settings-params';
import { ServiceActionsEnum } from './service.types';

const clearSettingsCard = () =>
    baseAsyncActionCreator<SettingsCard | null>(async () => {
        return [ServiceActionsEnum.updateSettingCard, null];
    });

const loadSettingsCard = () =>
    baseAsyncActionCreator<SettingsCard | null>(async () => {
        const result = await SettingsRepository.getCard();
        return [ServiceActionsEnum.updateSettingCard, result];
    });

const updateSettingsCard = (params: CreateSettingsParams) =>
    baseAsyncActionCreator<string>(async () => {
        await SettingsRepository.update(params);
        toast.success('Запись успешно обновлена!');
        goToState('/service-settings');
        return [GlobalActionsEnum.empty, ''];
    });

export {
    clearSettingsCard,
    loadSettingsCard,
    updateSettingsCard,
};
