import * as t from 'io-ts';

export const OVCohortListType = t.type({
    id: t.number,
    title: t.string,
});

export type OVCohortListDTO = t.TypeOf<typeof OVCohortListType>;

class OVCohortList {
    id: number;
    title: string;

    constructor(dto: OVCohortListDTO) {
        this.id = dto.id;
        this.title = dto.title;
    }
}

export { OVCohortList };
