import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { match } from 'react-router-dom';
import { Button } from '../../../../global/components/button';
import { TextInput } from '../../../../global/components/form-controls/text-input/text-input';
import { InfoItem } from '../../../../global/components/info-item';
import { InfoLine } from '../../../../global/components/info-line';
import { goToState } from '../../../../global/helpers/url-parse.helper';
import { useSpinner } from '../../../../global/hooks/use-spinner';
import { Footer, Header, Root } from './ministry-edit.styles';
import {
    EntityForSelect,
} from '../../../../global/components/multiple-select-entity/multiple-select-entity';
import {
    loadMembersForSelect,
    parseMemberItemToEntityForSelect,
} from '../../../members/helpers/api.helpers';
import { getMinistryCard } from '../../redux/ministries.selectors';
import {
    clearMinistryCard,
    loadMinistryCard,
    updateMinistryCard,
} from '../../redux/ministries.actions';
import { loadMinistriesForSelect, parseMinistryItemToEntityForSelect } from '../../helpers/api.helpers';
import { CreateMinistryParams } from '../../types/create-ministry-params';
import SingleSelectEntity from '../../../../global/components/single-select-entity/single-select-entity';
import { loadChatInfoForSelect, parseChatInfoItemToEntityForSelect } from '../../../chat-info/helpers/api.helpers';
import { AuthService } from '../../../../auth/services/AuthService';
import { BottomPadding } from '../../../../global/components/main-wrap/main-wrap.styles';

type MinistryEditPageRouteParams = {
    id: string;
};

type MinistryEditProp = {
    match: match<MinistryEditPageRouteParams>;
};

const MinistryEdit = (props: MinistryEditProp) => {
    const showForAdmin = useMemo(() => AuthService.availableForAdmin(), [AuthService.availableForAdmin]);
    const showForLeader = useMemo(() => AuthService.availableForLeader(), [AuthService.availableForLeader]);
    const dispatch = useDispatch();
    const ministryCard = useSelector(getMinistryCard);
    const [id, setId] = useState<number | null>(null);
    const spinnerRunner = useSpinner(dispatch);
    const [name, setName] = useState<string | null>(null);
    const [chatInfo, setChatInfo] = useState<EntityForSelect | null>(null);
    const [description, setDescription] = useState<string | null>(null);
    const [lead, setLead] = useState<EntityForSelect | null>(null);
    const [assist, setAssist] = useState<EntityForSelect | null>(null);
    const [parent, setParent] = useState<EntityForSelect | null>(null);

    const editAvailableAsLeader = useMemo(() => {
        const member = AuthService.getUserInfo()?.member;
        if (!member || !ministryCard) {
            return false;
        }
        return member.id === ministryCard.lead.id || (ministryCard.assist && member.id === ministryCard.assist.id);
    }, [ministryCard]);

    useEffect(() => {
        if (!showForLeader) {
            goToState('/');
            return;
        }
    }, []);

    useEffect(() => {
        if (!ministryCard) {
            return;
        }
        if (!!ministryCard && !showForAdmin && !editAvailableAsLeader) {
            goToState('/');
            return;
        }
    }, [editAvailableAsLeader]);
    
    useEffect(() => {
        if (props.match.params.id && Number(props.match.params.id) !== id) {
            dispatch(clearMinistryCard());
            setId(Number(props.match.params.id));
            dispatch(loadMinistryCard(Number(props.match.params.id)));
        }
    }, [props.match.params.id]);

    useEffect(() => {
        if (ministryCard) {
            setId(ministryCard.id);
            setName(ministryCard.name);
            setChatInfo(ministryCard.chat ? parseChatInfoItemToEntityForSelect(ministryCard.chat) : null);
            setDescription(ministryCard.description);
            setLead(parseMemberItemToEntityForSelect(ministryCard.lead));
            setAssist(
                ministryCard.assist
                    ? parseMemberItemToEntityForSelect(ministryCard.assist)
                    : null,
            );
            setParent(
                ministryCard.parent
                    ? parseMinistryItemToEntityForSelect(ministryCard.parent)
                    : null,
            );
        }
    }, [ministryCard]);

    const isValidForm = useMemo(() => !!name && !!lead, [name, lead]);

    const saveCallback = useCallback(() => {
        if (!id || !name || !lead) {
            return;
        }
        const params: CreateMinistryParams = {
            name: name,
            chatId: chatInfo?.id || null,
            description,
            leadId: lead.id,
            assistId: assist?.id || null,
            parentId: parent?.id || null,
        };
        spinnerRunner(updateMinistryCard(id, params));
    }, [
        name,
        chatInfo,
        description,
        lead,
        assist,
        parent,
        spinnerRunner,
        updateMinistryCard,
    ]);

    const goToList = useCallback(() => {
        goToState('/ministries');
    }, []);

    const goToCard = useCallback(() => {
        goToState(`/ministry/${props.match.params.id}`);
    }, []);

    if (!ministryCard) {
        return (
            <Root>
                <Header>
                    <Button title="Перейти в список" onClick={goToList} />
                </Header>
            </Root>
        );
    }

    return (
        <Root>
            <Header>
                {
                    !!showForAdmin &&
                    <Button title="Перейти в список" onClick={goToList} />
                }
                <Button title="Перейти в карточку" onClick={goToCard} />
            </Header>
            <InfoItem title="id" value={id} />
            <InfoLine>
                <InfoItem
                    title="Название *"
                    value={<TextInput value={name} onChange={setName} />}
                />
                <InfoItem
                    title="Описание"
                    value={
                        <TextInput
                            value={description}
                            onChange={setDescription}
                        />
                    }
                />
                <SingleSelectEntity
                    label="Чат в телеграмме"
                    value={chatInfo}
                    onChange={setChatInfo}
                    loadOptions={loadChatInfoForSelect}
                />
            </InfoLine>
            <InfoLine>
                {
                    !!showForAdmin &&
                    <>
                        <SingleSelectEntity
                            label="Лидер *"
                            value={lead}
                            onChange={setLead}
                            loadOptions={loadMembersForSelect}
                        />
                        <SingleSelectEntity
                            label="Помощник"
                            value={assist}
                            onChange={setAssist}
                            loadOptions={loadMembersForSelect}
                        />
                    </>
                }
                {
                    !showForAdmin &&
                    <>
                        <InfoItem
                            title="Лидер"
                            value={<div>
                                <a href={`#/member/${ministryCard.lead.id}`}>{ministryCard.lead.name}</a>
                            </div>}
                        />
                        <InfoItem
                            title="Помощник"
                            value={<div>
                                {
                                    !!ministryCard.assist &&
                                    <a href={`#/member/${ministryCard.assist.id}`}>{ministryCard.assist.name}</a>
                                }
                            </div>}
                        />
                    </>
                }
                <SingleSelectEntity
                    label="Родительское служение"
                    value={parent}
                    onChange={setParent}
                    loadOptions={loadMinistriesForSelect}
                />
            </InfoLine>
            <Footer>
                <Button
                    title="Сохранить"
                    onClick={saveCallback}
                    disabled={!isValidForm}
                />
            </Footer>
            <BottomPadding />
        </Root>
    );
};

export default MinistryEdit;
