export type TestimonyRevisionItem = {
    label: string;
    value: string;
};

type TestimonyRevisionType = {
    [key: string]: TestimonyRevisionItem;
};

export const TestimonyRevision: TestimonyRevisionType = {
    NO: {
        label: 'Нет',
        value: 'NO',
    },
    FOR_TALKING: {
        label: 'До рассказа',
        value: 'FOR_TALKING',
    },
    FOR_INERVIEW: {
        label: 'До собеседования',
        value: 'FOR_INERVIEW',
    },
};

export const testimonyRevisionOptions = Object.values(TestimonyRevision);
