import styled, { css } from 'styled-components/macro';
import { InfoItem } from '../info-item';

const Root = styled(InfoItem)<{ maxWidth?: number }>`
    min-width: 200px;
    ${({ maxWidth }) => maxWidth ? css`
        max-width: ${maxWidth}px;
    ` : ''}
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
`;

export { Root };
