import React, { useMemo } from 'react';
import Select from 'react-select';
import { LocalAnyType } from '../../../helpers/type.helper';
import { SimpleSelectItem } from '../../../types/simple-select-item';
import { InfoItem } from '../../info-item';

type SimpleSelectProps = {
    label: string;
    value: SimpleSelectItem | null;
    options: SimpleSelectItem[];
    onChange: (value: SimpleSelectItem | null) => void;
    className?: string;
};

const SimpleSelect = (props: SimpleSelectProps) => {
    const selectStyles = useMemo(
        () => ({
            menuPortal: (base: LocalAnyType) => ({ ...base, zIndex: 9999 }),
        }),
        [],
    );

    return (
        <InfoItem
            className={props.className}
            title={props.label}
            value={
                <Select
                    options={props.options}
                    defaultValue={props.value}
                    value={props.value}
                    onChange={props.onChange}
                    isClearable
                    menuPortalTarget={document.body}
                    styles={selectStyles}
                />
            }
        />
    );
};

export default SimpleSelect;
