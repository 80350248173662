import moment from 'moment';
import { Payment } from '../models/payment.model';
import 'moment/locale/ru'; // without this line it didn't work
moment.locale('ru');

export type PaymentStatisticItem = {
    mounthTitle: string,
    hasPayments: boolean,
}

export const getPaymentYesrStatistic = (payments: Payment[], witnessesDate: Date | null): PaymentStatisticItem[] => {
    const result: PaymentStatisticItem[] = [];
    let data = moment();
    for (let i = 0; i < 12; i++) {
        data = data.subtract(1, 'months').date(1).hour(0).minute(0).second(0).millisecond(0);
        if (data.isBefore(moment(witnessesDate))) {
            continue;
        }

        result.push({
            hasPayments: payments.some(p => {
                if (!p.date) {
                    return false;
                }
                const currentDate = moment(p.date);
                return currentDate.month() === data.month() && currentDate.year() === data.year();
            }),
            mounthTitle: data.format('MMMM'),
        });
    }
    return result;
};