export const getAllHours = (): Array<{
    label: string,
    value: string,
}> => {
    const result: Array<{
        label: string,
        value: string,
    }> = [];
    for (let i = 0; i < 24; i++) {
        result.push({
            label: `${i}`,
            value: i < 10 ? `0${i}` : `${i}`,
        });
    }
    return result;
};

export const getAllMinutes = (): Array<{
    label: string,
    value: string,
}> => {
    const result: Array<{
        label: string,
        value: string,
    }> = [];
    for (let i = 0; i < 60; i++) {
        result.push({
            label: `${i}`,
            value: i < 10 ? `0${i}` : `${i}`,
        });
    }
    return result;
};
