import { ServiceActions, ServiceActionsEnum, ServiceState } from './service.types';

const initialState = new ServiceState();

const serviceReducer = (state = initialState, action: ServiceActions) => {
    switch (action.type) {
        case ServiceActionsEnum.updateSettingCard:
            return {
                ...state,
                settingCard: action.payload,
            };
        default:
            return state;
    }
};

export default serviceReducer;
