import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { match } from 'react-router-dom';
import { Button } from '../../../../global/components/button';
import { TextInput } from '../../../../global/components/form-controls/text-input/text-input';
import { InfoItem } from '../../../../global/components/info-item';
import { InfoLine } from '../../../../global/components/info-line';
import { goToState } from '../../../../global/helpers/url-parse.helper';
import { useSpinner } from '../../../../global/hooks/use-spinner';
import { Footer, Header, Root } from './family-edit.styles';
import { getFamilyCard } from '../../redux/family.selectors';
import { clearFamilyCard, loadFamilyCard, updateFamilyCard } from '../../redux/family.actions';
import { CreateFamilyParams } from '../../types/create-family-params';
import MultipleSelectEntity, { EntityForSelect } from '../../../../global/components/multiple-select-entity/multiple-select-entity';
import { loadMembersForSelect, parseMembersListToEntityForSelect } from '../../../members/helpers/api.helpers';
import { AuthService } from '../../../../auth/services/AuthService';
import { BottomPadding } from '../../../../global/components/main-wrap/main-wrap.styles';
import { parseDateForApi } from '../../../../global/helpers/date.helper';

type FamilyEditPageRouteParams = {
    id: string;
};

type FamilyEditProp = {
    match: match<FamilyEditPageRouteParams>;
};

const FamilyEdit = (props: FamilyEditProp) => {
    const showForAdmin = useMemo(() => AuthService.availableForAdmin(), [AuthService.availableForAdmin]);
    const dispatch = useDispatch();
    const familyCard = useSelector(getFamilyCard);
    const [id, setId] = useState<number | null>(null);
    const spinnerRunner = useSpinner(dispatch);
    const [name, setName] = useState<string | null>(null);
    const [childCount, setChildCount] = useState<string | null>(null);
    const [members, setMembers] = useState<EntityForSelect[]>([]);

    useEffect(() => {
        if (!showForAdmin) {
            goToState('/');
            return;
        }
    }, []);
    
    useEffect(
        () => {
            if (props.match.params.id && Number(props.match.params.id) !== id) {
                dispatch(clearFamilyCard());
                dispatch(loadFamilyCard(Number(props.match.params.id)));
            }
        },
        [props.match.params.id],
    );

    useEffect(() => {
        if (familyCard) {
            setId(familyCard.id);
            setName(familyCard.name);
            setChildCount(`${familyCard.childCount}`);
            setMembers(parseMembersListToEntityForSelect(familyCard.members));
        }
    }, [familyCard]);

    const isValidForm = useMemo(
        () => !!name && !!members.length,
        [
            name,
            members,
        ],
    );

    const saveCallback = useCallback(() => {
        if (!id || !name || !members.length) {
            return;
        }
        const params: CreateFamilyParams = {
            name,
            childCount: Number(childCount) || 0,
            members: members.map(el => el.id),
            weddingDay: parseDateForApi(familyCard?.weddingDay || null) || '',
        };
        spinnerRunner(updateFamilyCard(id, params));
    }, [
        name,
        childCount,
        members,
        spinnerRunner,
        updateFamilyCard,
    ]);

    const goToList = useCallback(() => {
        goToState('/family');
    }, []);

    const goToCard = useCallback(() => {
        goToState(`/family/${props.match.params.id}`);
    }, []);

    if (!familyCard) {
        return (
            <Root>
                <Header>
                    <Button
                        title="Перейти в список"
                        onClick={goToList}
                    />
                </Header>
            </Root>
        );
    }

    return (
        <Root>
            <Header>
                <Button
                    title="Перейти в список"
                    onClick={goToList}
                />
                <Button
                    title="Перейти в карточку"
                    onClick={goToCard}
                />
            </Header>
            <InfoItem
                title="id"
                value={id}
            />
            <InfoLine>
                <InfoItem
                    title="Название *"
                    value={<TextInput
                        value={name}
                        onChange={setName}
                    />}
                />
                <InfoItem
                    title="Кол-во детей"
                    value={<TextInput
                        value={childCount}
                        onChange={setChildCount}
                        typeInput="number"
                    />}
                />
                <MultipleSelectEntity
                    label="Участники *"
                    values={members}
                    onChange={setMembers}
                    loadOptions={loadMembersForSelect}
                />
            </InfoLine>
            <Footer>
                <Button
                    title="Сохранить"
                    onClick={saveCallback}
                    disabled={!isValidForm}
                />
            </Footer>
            <BottomPadding />
        </Root>
    );
};

export default FamilyEdit;
