import styled from 'styled-components/macro';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { colors } from '../../../../global/styles/variables';

const Root = styled.div`
    width: 100%;
    min-height: 200px;
    padding: 12px;
    box-sizing: border-box;
    position: relative;

    .action-icon {
        cursor: pointer;
        fill: ${colors.buttonStaticBack};
        margin-left: 12px;
    }
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 12px;
`;

const RowWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
`;

const ChildIcon = styled(ArrowRightIcon)`
    margin-right: 12px;
    margin-left: 0 !important;

    &.isOpen {
        transform: rotate(90deg);
    }
`;

const EmptyChildIcon = styled.div`
    width: 36px;
    height: 24px;
`;

const FilterPanel = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 12px;

    .clear-filter-button {
        margin: 25px 0 0 12px;
    }

    .filter-item {
        margin-right: 12px;
    }
`;

export { Root, Header, FilterPanel, RowWrap, ChildIcon, EmptyChildIcon };
