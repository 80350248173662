import { api } from '../../../config/api';
import { BaseRepository, ResolverMethods } from '../../../global/repository/base.repository';
import { apiHost } from '../../../params';
import { ChatInfoList, ChatInfoListType, ChatInfoListDTO } from '../models/chat-info-list.model';
import { ChatInfoCard, ChatInfoCardType, ChatInfoCardDTO } from '../models/chat-info-card.model';
import { GetChatInfoListBodyParams } from '../types/get-chat-info-list-body-params';
import { GetChatInfoListQueryParams } from '../types/get-chat-info-list-query-params';
import { UpdateLinkParams } from '../types/update-link-params';

export class ChatInfoRepository extends BaseRepository {
    static defaultUrl = `${apiHost}/api/v1/chat-info/`;
    static api = api;

    static getList(queryParams: GetChatInfoListQueryParams, bodyData: GetChatInfoListBodyParams) {
        return ChatInfoRepository.resolveCollection<ChatInfoListDTO, ChatInfoList>({
            url: '',
            method: ResolverMethods.post,
            queryParams,
            bodyData,
            model: ChatInfoList,
            modelType: ChatInfoListType,
        });
    };

    static getCard(id: number) {
        return ChatInfoRepository.resolve<ChatInfoCardDTO, ChatInfoCard>({
            url: `${id}/`,
            model: ChatInfoCard,
            modelType: ChatInfoCardType,
        });
    };

    static updateLink(id: number, bodyData: UpdateLinkParams) {
        return ChatInfoRepository.resolve<ChatInfoCardDTO, ChatInfoCard>({
            url: `${id}/update-links`,
            method: ResolverMethods.put,
            bodyData,
            model: ChatInfoList,
            modelType: ChatInfoListType,
        });
    };
}
