import { OVRegistrationActions, OVRegistrationActionsEnum, OVRegistrationState } from './ov-registration.types';

const initialState = new OVRegistrationState();

const ovRegistrationReducer = (state = initialState, action: OVRegistrationActions) => {
    switch (action.type) {
        case OVRegistrationActionsEnum.updateOVRegistrations:
            return {
                ...state,
                registrations: action.payload.registrations,
                totalCount: action.payload.totalCount,
            };
        default:
            return state;
    }
};

export default ovRegistrationReducer;
