import * as t from 'io-ts';
import { ChatInfoList, ChatInfoListType } from '../../chat-info/models/chat-info-list.model';
import { ResourcesListType } from './resources-list.model';
import { ResourcesPlatform, ResourcesPlatformItem } from './resources-platform.model';
import { ResourcesRestriction, ResourcesRestrictionItem } from './resources-restriction.model';
import { ResourcesType, ResourcesTypeItem } from './resources-type.model';

export const ResourcesCardType = t.intersection([
    ResourcesListType,
    t.type({
        description: t.union([t.string, t.null]),
        url: t.union([t.string, t.null]),
        chat: t.union([ChatInfoListType, t.null]),
        restriction: t.union([t.string, t.null]),
    })
]);

export type ResourcesCardDTO = t.TypeOf<typeof ResourcesCardType>;

class ResourcesCard {
    id: number;
    name: string;
    type: ResourcesTypeItem;
    platform: ResourcesPlatformItem;
    description: string | null;
    url: string | null;
    chat: ChatInfoList | null;
    restriction: ResourcesRestrictionItem | null;

    constructor(dto: ResourcesCardDTO) {
        this.id = dto.id;
        this.name = dto.name;
        this.description = dto.description;
        this.url = dto.url;
        this.chat = dto.chat ? new ChatInfoList(dto.chat) : null;
        this.type = dto.type && ResourcesType[dto.type] ? ResourcesType[dto.type] : ResourcesType.internal;
        this.platform = dto.platform && ResourcesPlatform[dto.platform] ? ResourcesPlatform[dto.platform] : ResourcesPlatform.web;
        this.restriction = dto.restriction && ResourcesRestriction[dto.restriction] ? ResourcesRestriction[dto.restriction] : null;
    }
}

export { ResourcesCard };
