import { api } from '../../../config/api';
import { BaseRepository, ResolverMethods } from '../../../global/repository/base.repository';
import { apiHost } from '../../../params';
import { SettingsCard, SettingsCardDTO, SettingsCardType } from '../models/settings-card.model';
import { CreateSettingsParams } from '../types/create-settings-params';

export class SettingsRepository extends BaseRepository {
    static defaultUrl = `${apiHost}/api/v1/service-settings/`;
    static api = api;

    static getCard() {
        return SettingsRepository.resolve<SettingsCardDTO, SettingsCard>({
            url: 'card',
            model: SettingsCard,
            modelType: SettingsCardType,
        });
    };

    static update(params: CreateSettingsParams) {
        return SettingsRepository.resolve<SettingsCardDTO, SettingsCard>({
            url: 'update',
            bodyData: params,
            method: ResolverMethods.put,
            model: SettingsCard,
            modelType: SettingsCardType,
        });
    };
}
