import * as t from 'io-ts';

export const GroupMinListType = t.type({
    id: t.number,
    name: t.string,
});

export type GroupMinListDTO = t.TypeOf<typeof GroupMinListType>;

class GroupMinList {
    id: number;
    name: string;

    constructor(dto: GroupMinListDTO) {
        this.id = dto.id;
        this.name = dto.name;
    }
}

export { GroupMinList };
