import { MinistriesActions, MinistriesActionsEnum, MinistriesState } from './ministries.types';

const initialState = new MinistriesState();

const ministriesReducer = (state = initialState, action: MinistriesActions) => {
    switch (action.type) {
        case MinistriesActionsEnum.updateMinistries:
            return {
                ...state,
                ministries: action.payload.ministries,
                totalCount: action.payload.totalCount,
            };
        case MinistriesActionsEnum.updateMinistryCard:
            return {
                ...state,
                ministryCard: action.payload,
            };
        default:
            return state;
    }
};

export default ministriesReducer;
