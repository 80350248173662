import styled from 'styled-components/macro';
import { colors, palette } from '../../styles/variables';

const ButtonStyle = styled.div`
    padding: 4px 12px;
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    background: ${colors.buttonStaticBack};
    color: ${colors.white};

    &.is-disabled {
        cursor: default;
        opacity: 0.6;
    }

    &.is-secondary {
        background: ${colors.BgToolkitNav};
        color: ${colors.mainFontColor};
    }

    &.green {
        background: ${palette.green};;
    }
`;

export { ButtonStyle };
