import { GlobalActionsEnum } from './global.types';
import { baseActionCreator, baseAsyncActionCreator } from '../../helpers/actions';
import { AuthRepository } from '../../../auth/repositories/auth.repository';
import { goToState } from '../../helpers/url-parse.helper';
import { toast } from 'react-toastify';

const keydownAction = (key: string) =>
    baseActionCreator<string[]>(({ global }) => {
        const activeKeys = [...global.activeKeys];
        if (!activeKeys.some(saveKey => saveKey === key)) {
            activeKeys.push(key);
        }
        return [
            GlobalActionsEnum.keydown,
            activeKeys,
        ];
    });

const keyupAction = (key: string) =>
    baseActionCreator<string[]>(({ global }) => {
        const activeKeys = [...global.activeKeys];
        const index = activeKeys.findIndex(saveKey => saveKey === key);
        if (index !== -1) {
            activeKeys.splice(index, 1);
        }
        return [
            GlobalActionsEnum.keyup,
            activeKeys,
        ];
    });

const toggleGlobalSpinner = (isShowGlobalSpinner: boolean) =>
    baseActionCreator<boolean>(() => {
        return [
            GlobalActionsEnum.toggleGlobalSpinner,
            isShowGlobalSpinner,
        ];
    });

const addDisplayedLocalSpinner = (key: string) =>
    baseActionCreator<string[]>(({ global }) => {
        const displayedLocalSpinners = [...global.displayedLocalSpinners];
        if (!displayedLocalSpinners.includes(key)) {
            displayedLocalSpinners.push(key);
        }
        return [
            GlobalActionsEnum.addDisplayedLocalSpinner,
            displayedLocalSpinners,
        ];
    });

const removeDisplayedLocalSpinner = (key: string) =>
    baseActionCreator<string[]>(({ global }) => {
        const displayedLocalSpinners = [...global.displayedLocalSpinners];
        const index = displayedLocalSpinners.findIndex(el => el === key);
        if (index !== -1) {
            displayedLocalSpinners.splice(index, 1);
        }
        return [
            GlobalActionsEnum.removeDisplayedLocalSpinner,
            displayedLocalSpinners,
        ];
    });

const updateLocationSearch = (search: string) =>
    baseActionCreator<string>(() => {
        return [
            GlobalActionsEnum.updateLocationSearch,
            search,
        ];
    });

const login = (login: string, password: string) =>
    baseAsyncActionCreator<null>(async () => {
        await AuthRepository.token(login, password);
        toast.success('Вы успешно авторизовались в системе');
        goToState('/');
        return [
            GlobalActionsEnum.empty,
            null,
        ];
    });

const setIsOpenMenu = (value: boolean) =>
    baseActionCreator<boolean>(() => {
        return [
            GlobalActionsEnum.setIsOpenMenu,
            value,
        ];
    });

export {
    keydownAction,
    keyupAction,
    toggleGlobalSpinner,
    addDisplayedLocalSpinner,
    removeDisplayedLocalSpinner,
    updateLocationSearch,
    login,
    setIsOpenMenu,
};
