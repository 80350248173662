import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { match } from 'react-router-dom';
import { Button } from '../../../../../global/components/button';
import { TextInput } from '../../../../../global/components/form-controls/text-input/text-input';
import { InfoItem } from '../../../../../global/components/info-item';
import { InfoLine } from '../../../../../global/components/info-line';
import { goToState } from '../../../../../global/helpers/url-parse.helper';
import { useSpinner } from '../../../../../global/hooks/use-spinner';
import { Footer, Header, Root } from './ov-cohort-edit.styles';
import { AuthService } from '../../../../../auth/services/AuthService';
import { BottomPadding } from '../../../../../global/components/main-wrap/main-wrap.styles';
import { getOVCohortCard } from '../../../redux/cohort/ov-cohort.selectors';
import { clearOVCohortCard, loadOVCohortCard, updateOVCohortCard } from '../../../redux/cohort/ov-cohort.actions';
import { CreateOVCohortParams } from '../../../types/cohort/create-ov-cohort-params';

type FamilyEditPageRouteParams = {
    id: string;
};

type FamilyEditProp = {
    match: match<FamilyEditPageRouteParams>;
};

const OVCohortEdit = (props: FamilyEditProp) => {
    const availablePage = useMemo(
        () => AuthService.availableForAdmin() || AuthService.hasOVPermission(),
        [],
    );
    const dispatch = useDispatch();
    const ovCohortCard = useSelector(getOVCohortCard);
    const [id, setId] = useState<number | null>(null);
    const spinnerRunner = useSpinner(dispatch);
    const [title, setTitle] = useState<string | null>(null);

    useEffect(() => {
        if (!availablePage) {
            goToState('/');
            return;
        }
    }, []);
    
    useEffect(
        () => {
            if (props.match.params.id && Number(props.match.params.id) !== id) {
                dispatch(clearOVCohortCard());
                dispatch(loadOVCohortCard(Number(props.match.params.id)));
            }
        },
        [props.match.params.id],
    );

    useEffect(() => {
        if (ovCohortCard) {
            setId(ovCohortCard.id);
            setTitle(ovCohortCard.title);
        }
    }, [ovCohortCard]);

    const isValidForm = useMemo(
        () => !!title,
        [
            title,
        ],
    );

    const saveCallback = useCallback(() => {
        if (!id || !title) {
            return;
        }
        const params: CreateOVCohortParams = {
            title,
        };
        spinnerRunner(updateOVCohortCard(id, params));
    }, [
        title,
        spinnerRunner,
    ]);

    const goToList = useCallback(() => {
        goToState('/ov/cohorts');
    }, []);

    if (!ovCohortCard) {
        return (
            <Root>
                <Header>
                    <Button
                        title="Перейти в список"
                        onClick={goToList}
                    />
                </Header>
            </Root>
        );
    }

    return (
        <Root>
            <Header>
                <Button
                    title="Перейти в список"
                    onClick={goToList}
                />
            </Header>
            <InfoItem
                title="id"
                value={id}
            />
            <InfoLine>
                <InfoItem
                    title="Название *"
                    value={<TextInput
                        value={title}
                        onChange={setTitle}
                    />}
                />
            </InfoLine>
            <Footer>
                <Button
                    title="Сохранить"
                    onClick={saveCallback}
                    disabled={!isValidForm}
                />
            </Footer>
            <BottomPadding />
        </Root>
    );
};

export default OVCohortEdit;
