import { toast } from 'react-toastify';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { BaseResponseApi } from '../types/base-reponse-api';
import { defaultErrorMessage, defaultToastTimeout } from '../constants';
import { TypeC, IntersectionC } from 'io-ts';
import { TypeHelper } from '../helpers/type.helper';
import { Collection } from '../types/collection';

export enum ResolverMethods {
    get = 'get',
    post = 'post',
    put = 'put',
    delete = 'delete',
}

export type QueryParamsType = {
    [index: string]: string | number | boolean | null | undefined;
}

// описание, передаваемого конфига в запросы
export type ResolverConfig = {
    url: string; // url, куда пойдёт запрос (без учёта defaultUrl)
    method?: ResolverMethods; // метод запроса (можно не передавать, по-умолчанию get)
    queryParams?: QueryParamsType; // get параметры
    // eslint-disable-next-line
    bodyData?: any; // post параметры
    // eslint-disable-next-line
    modelType?: TypeC<any> | IntersectionC<[TypeC<any>, TypeC<any>]>; // описание типов из io-ts для проверки модели
    // eslint-disable-next-line
    model?: any; // класс модели, если нужно dto промапить в модель
    axiosConfigs?: AxiosRequestConfig; // возможность пробросить любые другие конфиги из аксиоса
    withoutToast?: boolean;
};

/**
 * Общий класс для работы с запросами на бэк
 * Содержит в себе 
 * defaultUrl - общий url для группы запросов
 * api - экземпляр axios, может понадобиться переопределить со своими конфигами (например для интерцептора)
 */
export class BaseRepository {
    static defaultUrl: string = '';
    static api: AxiosInstance = axios;

    /**
     * вернёт true, если метод get или delete
     * @param method 
     * @returns 
     */
    private static _isGetViewRequest(method?: ResolverMethods): boolean {
        return !method || method === ResolverMethods.get || method === ResolverMethods.delete;
    }

    /**
     * Внутренний метод для обработки результатов запроса с мапой на модель
     */
    private static _parseResponse<DTO, Model>(result: AxiosResponse<DTO>, config: ResolverConfig): Model | null {
        const dto = result?.data || null;
        if (dto === null || !config.model) {
            return null;
        }
        if (config.modelType) {
            return TypeHelper.checkElement<Model, DTO>(
                config.modelType,
                config.model,
                config.model && config.model.name ? config.model.name : 'Неизвестная модель',
                dto,
            );
        }
        return new config.model(dto);
    }

    /**
     * Внутренний метод для обработки результатов запроса с мапой массива данных на модель
     */
    private static _parseResponseArray<DTO, Model>(result: AxiosResponse<DTO[]>, config: ResolverConfig): Model[] {
        const dto = result?.data || [];
        if (!dto.length || !config.model) {
            return [];
        }
        if (config.modelType) {
            return TypeHelper.checkElementsArray<Model, DTO>(
                config.modelType,
                config.model,
                config.model && config.model.name ? config.model.name : 'Неизвестная модель',
                dto,
            );
        }
        return dto.map(dtoItem => new config.model(dtoItem));
    }

    /**
     * Внутренний метод для обработки результатов запроса с мапой коллекции данных на модель
     */
    private static _parseResponseCollection<DTO, Model>(result: AxiosResponse<Collection<DTO>>, config: ResolverConfig): Collection<Model> {
        const dto = result?.data || [];
        if (!dto.items.length || !config.model) {
            return {
                items: [],
                totalCount: dto.totalCount,
            };
        }
        if (config.modelType) {
            return TypeHelper.checkElementsCollection<Model, DTO>(
                config.modelType,
                config.model,
                config.model && config.model.name ? config.model.name : 'Неизвестная модель',
                dto,
            );
        }
        return {
            items: dto.items.map(dtoItem => new config.model(dtoItem)),
            totalCount: dto.totalCount,
        };
    }

    private static _parseErrorText(errorResponse: AxiosResponse<BaseResponseApi>): string {
        let error: string = '';
        const errorMessageFromServer = errorResponse?.data?.Error || errorResponse?.data?.detail;
        if (!!errorResponse && errorResponse.data && (errorResponse.data.success === false || errorMessageFromServer)) {
            error = errorMessageFromServer || 'Неизвестная ошибка';
        }
        if (defaultErrorMessage[error]) {
            return defaultErrorMessage[error];
        }
        if (errorResponse?.data && typeof errorResponse.data === 'string') {
            error = errorResponse.data;
        }
        return error || 'Неизвестная ошибка';
    }

    private static _checkError<R extends BaseResponseApi>(promise: Promise<AxiosResponse<R>>, withoutToast: boolean): Promise<AxiosResponse<R>> {
        return new Promise((resolve, reject) => {
            promise
                .then(res => {
                    // если запрос вернул 200, но бэк положил в ответ информацию об ошибке
                    if (!!res && !!res.data && res.data.success === false) {
                        const errorText = this._parseErrorText(res);
                        if (!withoutToast) {
                            toast.error(errorText, { autoClose: defaultToastTimeout });
                        }
                        reject(res);
                        return;
                    }
                    resolve(res);
                })
                .catch(error => {
                    // если запрос вернул ошибку, попытаемся её распарсить
                    const errorText = defaultErrorMessage[error.toString()] || this._parseErrorText(error.response);
                    if (!withoutToast) {
                        toast.error(errorText, { autoClose: defaultToastTimeout });
                    }
                    reject(error);
                });
        });
    }

    /**
     * Метод для генерации запроса без мапы на модель
     * @param config 
     * @returns 
     */
    static resolveWithoutModel<R>(config: ResolverConfig): Promise<R | null> {
        const url = `${this.defaultUrl}${config.url}`;
        if (this._isGetViewRequest(config.method)) {
            const promise = this.api[config.method || ResolverMethods.get](url, {
                params: config.queryParams,
                ...config.axiosConfigs,
            });
            return this._checkError<R & BaseResponseApi>(promise, !!config.withoutToast)
                .then(result => result?.data || null);
        }
        const promise = this.api[config.method || ResolverMethods.post](url, config.bodyData, {
            params: config.queryParams,
            ...config.axiosConfigs,
        });
        return this._checkError<R & BaseResponseApi>(promise, !!config.withoutToast)
            .then(result => result?.data || null);
    }

    /**
     * Метод для генерации запроса с мапой на модель
     * @param config 
     * @returns 
     */
    static resolve<DTO, Model>(config: ResolverConfig): Promise<Model | null> {
        const url = `${this.defaultUrl}${config.url}`;
        if (this._isGetViewRequest(config.method)) {
            const promise = this.api[config.method || ResolverMethods.get](url, {
                params: config.queryParams,
                ...config.axiosConfigs,
            });
            return this._checkError<DTO & BaseResponseApi>(promise, !!config.withoutToast)
                .then(result => this._parseResponse<DTO, Model>(result, config));
        }
        const promise = this.api[config.method || ResolverMethods.post](url, config.bodyData, {
            params: config.queryParams,
            ...config.axiosConfigs,
        });
        return this._checkError<DTO & BaseResponseApi>(promise, !!config.withoutToast)
            .then(result => this._parseResponse<DTO, Model>(result, config));
    }

    /**
     * Метод для генерации запроса с мапой массива на модель
     * @param config 
     * @returns 
     */
    static resolveArray<DTO, Model>(config: ResolverConfig): Promise<Model[]> {
        const url = `${this.defaultUrl}${config.url}`;
        if (this._isGetViewRequest(config.method)) {
            const promise = this.api[config.method || ResolverMethods.get](url, {
                params: config.queryParams,
                ...config.axiosConfigs,
            });
            return this._checkError<DTO[] & BaseResponseApi>(promise, !!config.withoutToast)
                .then(result => this._parseResponseArray<DTO, Model>(result, config));
        }
        const promise = this.api[config.method || ResolverMethods.post](url, config.bodyData, {
            params: config.queryParams,
            ...config.axiosConfigs,
        });
        return this._checkError<DTO[] & BaseResponseApi>(promise, !!config.withoutToast)
            .then(result => this._parseResponseArray<DTO, Model>(result, config));
    }

    /**
     * Метод для генерации запроса с мапой коллекции на модель
     * @param config 
     * @returns 
     */
    static resolveCollection<DTO, Model>(config: ResolverConfig): Promise<Collection<Model>> {
        const url = `${this.defaultUrl}${config.url}`;
        if (this._isGetViewRequest(config.method)) {
            const promise = this.api[config.method || ResolverMethods.get](url, {
                params: config.queryParams,
                ...config.axiosConfigs,
            });
            return this._checkError<Collection<DTO> & BaseResponseApi>(promise, !!config.withoutToast)
                .then(result => this._parseResponseCollection<DTO, Model>(result, config));
        }
        const promise = this.api[config.method || ResolverMethods.post](url, config.bodyData, {
            params: config.queryParams,
            ...config.axiosConfigs,
        });
        return this._checkError<Collection<DTO> & BaseResponseApi>(promise, !!config.withoutToast)
            .then(result => this._parseResponseCollection<DTO, Model>(result, config));
    }
};
