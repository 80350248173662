import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import { match } from 'react-router-dom';
import { Button } from '../../../../global/components/button';
import { InfoItem } from '../../../../global/components/info-item';
import { goToState } from '../../../../global/helpers/url-parse.helper';
import { Header, MemberItem, Root } from './family-card.styles';
import { useSpinner } from '../../../../global/hooks/use-spinner';
import { getFamilyCard } from '../../redux/family.selectors';
import { clearFamilyCard, deleteFamilyCard, loadFamilyCard } from '../../redux/family.actions';
import { AuthService } from '../../../../auth/services/AuthService';
import { BottomPadding } from '../../../../global/components/main-wrap/main-wrap.styles';

type FamilyCardPageRouteParams = {
    id: string;
};

type FamilyCardProp = {
    match: match<FamilyCardPageRouteParams>;
};

const FamilyCard = (props: FamilyCardProp) => {
    const showForLeader = useMemo(() => AuthService.availableForLeader(), [AuthService.availableForLeader]);
    const dispatch = useDispatch();
    const spinnerRunner = useSpinner(dispatch);
    const familyCard = useSelector(getFamilyCard);
    const [id, setId] = useState<number | null>(null);

    useEffect(() => {
        if (!showForLeader) {
            goToState('/');
            return;
        }
    }, []);
    
    useEffect(
        () => {
            if (props.match.params.id && Number(props.match.params.id) !== id) {
                dispatch(clearFamilyCard());
                setId(Number(props.match.params.id));
                spinnerRunner(loadFamilyCard(Number(props.match.params.id)));
            }
        },
        [props.match.params.id],
    );

    const goToList = useCallback(() => {
        goToState('/family');
    }, []);

    const goToEdit = useCallback(() => {
        goToState(`/family/${props.match.params.id}/edit`);
    }, [props.match.params.id]);

    const goToDelete = useCallback(() => {
        if (!id) {
            return;
        }
        confirmAlert({
            title: 'Удаление',
            message: 'Вы уверены, что хотите удалить эту запись?',
            buttons: [
                {
                    label: 'Да',
                    onClick: async () => {
                        await spinnerRunner(deleteFamilyCard(id));
                        goToList();
                    },
                },
                {
                    label: 'Нет',
                    onClick: () => null,
                },
            ],
        });
    }, [confirmAlert, id]);
    const showForAdmin = useMemo(() => AuthService.availableForAdmin(), [AuthService.availableForAdmin]);

    if (!familyCard) {
        return (
            <Root>
                <Header>
                    {!!showForAdmin && <>
                        <Button
                            title="Перейти в список"
                            onClick={goToList}
                        />
                    </>}
                </Header>
            </Root>
        );
    }

    return (
        <Root>
            <Header>
                {!!showForAdmin && <>
                    <Button
                        title="Перейти в список"
                        onClick={goToList}
                    />
                    <Button
                        title="Редактировать"
                        onClick={goToEdit}
                    />
                    <Button
                        title="Удалить"
                        onClick={goToDelete}
                    />
                </>}
            </Header>
            <InfoItem
                title="Название"
                value={familyCard.name}
            />
            <InfoItem
                title="Кол-во детей"
                value={familyCard.childCount}
            />
            <InfoItem
                title="Участники"
                value={<div>
                    {
                        familyCard.members.map(el =>
                            <MemberItem key={el.id}>
                                <a href={`#/member/${el.id}`}>{el.name}</a>
                            </MemberItem>
                        )
                    }
                </div>}
            />
            <BottomPadding />
        </Root>
    );
};

export default FamilyCard;
