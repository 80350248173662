import * as t from 'io-ts';

export const OVCohortType = t.type({
    id: t.number,
    title: t.string,
    isRegistrationOpen: t.boolean,
});

export type OVCohortDTO = t.TypeOf<typeof OVCohortType>;

class OVCohort {
    id: number;
    title: string;
    isRegistrationOpen: boolean;

    constructor(dto: OVCohortDTO) {
        this.id = dto.id;
        this.title = dto.title;
        this.isRegistrationOpen = !!dto.isRegistrationOpen;
    }
}

export { OVCohort };
