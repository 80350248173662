import { createSelector, Selector } from 'reselect';
import { StateModel } from '../../../global/redux/state.model';
import { GroupCard } from '../models/group-card.model';
import { GroupList } from '../models/group-list.model';
import { GroupsState } from './groups.types';

export const getGroupsState = (state: StateModel): GroupsState => state.groups;

export const getGroupsList: Selector<StateModel, GroupList[]> =
    createSelector(
        [getGroupsState],
        (groupsState) => groupsState.groups,
    );

export const getGroupsTotalCount: Selector<StateModel, number> =
    createSelector(
        [getGroupsState],
        (groupsState) => groupsState.totalCount,
    );

export const getGroupCard: Selector<StateModel, GroupCard | null> =
    createSelector(
        [getGroupsState],
        (groupsState) => groupsState.groupCard,
    );

export const getAllGroups: Selector<StateModel, GroupCard[]> =
    createSelector(
        [getGroupsState],
        (groupsState) => groupsState.allGroups,
    );
