import { EntityForSelect } from '../../../global/components/multiple-select-entity/multiple-select-entity';
import { MinistryList } from '../models/ministry-list.model';
import { MinistriesRepository } from '../repositories/ministries.repository';

export const loadMinistriesForSelect = async (searchString: string | null): Promise<EntityForSelect[]> => {
    const result = await MinistriesRepository.getList({
        pageNo: 0,
        pageSize: 10,
    }, {
        searchString,
    });
    return parseMinistriesListToEntityForSelect(result.items);
};

export const parseMinistryItemToEntityForSelect = (member: MinistryList): EntityForSelect => ({
    id: member.id,
    name: member.name,
});

export const parseMinistriesListToEntityForSelect = (members: MinistryList[]): EntityForSelect[] => members.map(parseMinistryItemToEntityForSelect);
