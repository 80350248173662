import React, { useEffect, useState } from 'react';
import { AuthRepository } from '../../../auth/repositories/auth.repository';
import { goToState } from '../../helpers/url-parse.helper';
import { Header } from '../header/header.component';
import { ContentWrap, Root } from './auth-wrap.styles';

type AuthWrapProps = {
    children: React.ReactElement;
};

export const AuthWrap: React.FC<AuthWrapProps> = (props) => {
    const [isAuth, setIsAuth] = useState<boolean>(false);
    useEffect(() => {
        AuthRepository.whoami()
            .then(() => setIsAuth(true))
            .catch(() => {
                setIsAuth(false);
                goToState('/login');
            });
    }, []);

    if (!isAuth) {
        return null;
    }

    return <Root>
        <Header />
        <ContentWrap>
            {props.children}
        </ContentWrap>
    </Root>;
};
