import { ChatInfoActions, ChatInfoActionsEnum, ChatInfoState } from './chat-info.types';

const initialState = new ChatInfoState();

const chatInfoReducer = (state = initialState, action: ChatInfoActions) => {
    switch (action.type) {
        case ChatInfoActionsEnum.updateChatInfo:
            return {
                ...state,
                chatInfo: action.payload.chatInfo,
                totalCount: action.payload.totalCount,
            };
        case ChatInfoActionsEnum.updateChatInfoCard:
            return {
                ...state,
                chatInfoCard: action.payload,
            };
        default:
            return state;
    }
};

export default chatInfoReducer;
