import moment from 'moment';

export const parseDateForApi = (date: Date | null): string | null => {
    return date ? moment(date).format('YYYY-MM-DD') : null;
};

export const parseDateFromApi = (date: string | number | null | undefined): Date | null => {
    if (date === null || date === undefined) {
        return null;
    }
    if (typeof date === 'string' && date.length === 10) {
        return moment(date, 'YYYY-MM-DD').toDate();
    }
    return new Date(date);
};
