import { api } from '../../../config/api';
import { BaseRepository, QueryParamsType, ResolverMethods } from '../../../global/repository/base.repository';
import { apiHost } from '../../../params';
import { GroupCard, GroupCardDTO, GroupCardType } from '../models/group-card.model';
import { GroupList, GroupListDTO, GroupListType } from '../models/group-list.model';
import { CreateGroupParams } from '../types/create-group-params';
import { GetGroupListBodyParams } from '../types/get-group-list-body-params';
import { GetGroupListQueryParams } from '../types/get-group-list-query-params';

export class GroupsRepository extends BaseRepository {
    static defaultUrl = `${apiHost}/api/v1/groups/`;
    static api = api;

    static getList(queryParams: GetGroupListQueryParams, bodyData: GetGroupListBodyParams) {
        return GroupsRepository.resolveCollection<GroupListDTO, GroupList>({
            url: '',
            method: ResolverMethods.post,
            queryParams,
            bodyData,
            model: GroupList,
            modelType: GroupListType,
        });
    };

    static getAllGroups(queryParams?: QueryParamsType) {
        return GroupsRepository.resolveArray<GroupCardDTO, GroupCard>({
            url: 'all',
            method: ResolverMethods.get,
            queryParams,
            model: GroupCard,
            modelType: GroupCardType,
        });
    };

    static create(bodyData: CreateGroupParams) {
        return GroupsRepository.resolve<GroupCardDTO, GroupCard>({
            url: 'create',
            method: ResolverMethods.post,
            bodyData,
            model: GroupCard,
            modelType: GroupCardType,
        });
    };

    static getCard(id: number) {
        return GroupsRepository.resolve<GroupCardDTO, GroupCard>({
            url: `${id}/`,
            model: GroupCard,
            modelType: GroupCardType,
        });
    };

    static update(id: number, bodyData: CreateGroupParams) {
        return GroupsRepository.resolve<GroupCardDTO, GroupCard>({
            url: `${id}/update`,
            method: ResolverMethods.put,
            bodyData,
            model: GroupCard,
            modelType: GroupCardType,
        });
    };

    static delete(id: number) {
        return GroupsRepository.resolveWithoutModel<void>({
            url: `${id}/delete`,
            method: ResolverMethods.delete,
        });
    };
}
