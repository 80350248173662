import { api } from '../../../config/api';
import { BaseRepository, ResolverMethods } from '../../../global/repository/base.repository';
import { apiHost } from '../../../params';
import { OVQuestionList, OVQuestionListDTO, OVQuestionListType } from '../models/question/ov-question-list.model';
import { GetOVQuestionListBodyParams } from '../types/question/get-ov-question-list-body-params';
import { GetOVQuestionListQueryParams } from '../types/question/get-ov-question-list-query-params';

export class OVQuestionRepository extends BaseRepository {
    static defaultUrl = `${apiHost}/api/v1/fof-questions/`;
    static api = api;

    static getList(queryParams: GetOVQuestionListQueryParams, bodyData: GetOVQuestionListBodyParams) {
        return OVQuestionRepository.resolveCollection<OVQuestionListDTO, OVQuestionList>({
            url: '',
            method: ResolverMethods.post,
            queryParams,
            bodyData,
            model: OVQuestionList,
            modelType: OVQuestionListType,
        });
    };
}
