import { toast } from 'react-toastify';
import { baseAsyncActionCreator } from '../../../global/helpers/actions';
import { goToState } from '../../../global/helpers/url-parse.helper';
import { GlobalActionsEnum } from '../../../global/redux/global/global.types';
import { ResourcesCard } from '../models/resources-card.model';
import { ResourcesRepository } from '../repositories/resources.repository';
import { CreateResourcesParams } from '../types/create-resources-params';
import { GetResourcesListBodyParams } from '../types/get-resources-list-body-params';
import { GetResourcesListQueryParams } from '../types/get-resources-list-query-params';
import { ResourcesActionsEnum, UpdateResourcesAction } from './resources.types';

const updateResources = (queryParams: GetResourcesListQueryParams, bodyData: GetResourcesListBodyParams) =>
    baseAsyncActionCreator<UpdateResourcesAction>(async () => {
        const result = await ResourcesRepository.getList(queryParams, bodyData);
        return [ResourcesActionsEnum.updateResources, {
            resources: result.items,
            totalCount: result.totalCount,
        }];
    });

const clearResourcesCard = () =>
    baseAsyncActionCreator<ResourcesCard | null>(async () => {
        return [ResourcesActionsEnum.updateResourcesCard, null];
    });

const loadResourcesCard = (id: number) =>
    baseAsyncActionCreator<ResourcesCard | null>(async () => {
        const result = await ResourcesRepository.getCard(id);
        return [ResourcesActionsEnum.updateResourcesCard, result];
    });

const createResourcesCard = (params: CreateResourcesParams, withoutRedirect?: boolean) =>
    baseAsyncActionCreator<string>(async () => {
        await ResourcesRepository.create(params);
        toast.success('Запись успешно добавлена!');
        if (!withoutRedirect) {
            goToState('/resources');
        }
        return [GlobalActionsEnum.empty, ''];
    });

const updateResourcesCard = (id: number, params: CreateResourcesParams) =>
    baseAsyncActionCreator<string>(async () => {
        await ResourcesRepository.update(id, params);
        toast.success('Запись успешно обновлена!');
        goToState(`/resources/${id}`);
        return [GlobalActionsEnum.empty, ''];
    });

const deleteResourcesCard = (id: number) =>
    baseAsyncActionCreator<string>(async () => {
        await ResourcesRepository.delete(id);
        toast.success('Запись успешно удалена!');
        return [GlobalActionsEnum.empty, ''];
    });

export {
    updateResources,
    clearResourcesCard,
    loadResourcesCard,
    createResourcesCard,
    updateResourcesCard,
    deleteResourcesCard,
};
