import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { Button } from '../../../../global/components/button';
import { TextInput } from '../../../../global/components/form-controls/text-input/text-input';
import { InfoItem } from '../../../../global/components/info-item';
import { InfoLine } from '../../../../global/components/info-line';
import { goToState } from '../../../../global/helpers/url-parse.helper';
import { Footer, Header, Root } from './resources-create.styles';
import { useSpinner } from '../../../../global/hooks/use-spinner';
import { CreateResourcesParams } from '../../types/create-resources-params';
import { createResourcesCard } from '../../redux/resources.actions';
import { ResourcesTypeItem, resourcesTypeOptions } from '../../models/resources-type.model';
import { ResourcesPlatformItem, resourcesPlatformOptions } from '../../models/resources-platform.model';
import { ResourcesRestrictionItem, resourcesRestrictionOptions } from '../../models/resources-restriction.model';
import { AuthService } from '../../../../auth/services/AuthService';
import { BottomPadding } from '../../../../global/components/main-wrap/main-wrap.styles';
import SingleSelectEntity from '../../../../global/components/single-select-entity/single-select-entity';
import { loadChatInfoForSelect } from '../../../chat-info/helpers/api.helpers';
import { EntityForSelect } from '../../../../global/components/multiple-select-entity/multiple-select-entity';

const ResourcesCreate = () => {
    const showForAdmin = useMemo(() => AuthService.availableForAdmin(), [AuthService.availableForAdmin]);
    const dispatch = useDispatch();
    const spinnerRunner = useSpinner(dispatch);
    const [name, setName] = useState<string | null>(null);
    const [description, setDescription] = useState<string | null>(null);
    const [chatInfo, setChatInfo] = useState<EntityForSelect | null>(null);
    const [url, setUrl] = useState<string | null>(null);
    const [type, setType] = useState<ResourcesTypeItem | null>(null);
    const [platform, setPlatform] = useState<ResourcesPlatformItem | null>(null);
    const [restriction, setRestriction] = useState<ResourcesRestrictionItem | null>(null);
    const goToList = useCallback(() => {
        goToState('/resources');
    }, []);

    useEffect(() => {
        if (!showForAdmin) {
            goToState('/');
            return;
        }
    }, []);

    const isValidForm = useMemo(
        () => !!name && !!type && !!platform,
        [
            name,
            type,
            platform,
        ],
    );

    const createCallback = useCallback(() => {
        if (!name || !type || !platform) {
            return;
        }
        const params: CreateResourcesParams = {
            name,
            description,
            url,
            chatId: chatInfo ? `${chatInfo.id}` : null,
            type: type.value,
            platform: platform.value,
            restriction: restriction?.value || null,
        };
        spinnerRunner(createResourcesCard(params));
    }, [
        name,
        url,
        description,
        type,
        chatInfo,
        platform,
        restriction,
        spinnerRunner,
        createResourcesCard,
    ]);

    return (
        <Root>
            <Header>
                <Button
                    title="Перейти в список"
                    onClick={goToList}
                />
            </Header>
            <InfoLine>
                <InfoItem
                    title="Название *"
                    value={<TextInput
                        value={name}
                        onChange={setName}
                    />}
                />
                <InfoItem
                    title="Описание"
                    value={<TextInput
                        value={description}
                        onChange={setDescription}
                    />}
                />
            </InfoLine>
            <InfoLine>
                <InfoItem
                    title="Тип *"
                    value={<Select
                        options={resourcesTypeOptions}
                        defaultValue={type}
                        onChange={setType}
                        isClearable
                    />}
                />
                <InfoItem
                    title="Платформа *"
                    value={<Select
                        options={resourcesPlatformOptions}
                        defaultValue={platform}
                        onChange={setPlatform}
                        isClearable
                    />}
                />
                <InfoItem
                    title="URL"
                    value={<TextInput
                        value={url}
                        onChange={setUrl}
                    />}
                />
                <SingleSelectEntity
                    label="Чат в телеграмме"
                    value={chatInfo}
                    onChange={setChatInfo}
                    loadOptions={loadChatInfoForSelect}
                />
            </InfoLine>
            <InfoLine>
                <InfoItem
                    title="Ограничения"
                    value={<Select
                        options={resourcesRestrictionOptions}
                        defaultValue={restriction}
                        onChange={setRestriction}
                        isClearable
                    />}
                />
            </InfoLine>
            <Footer>
                <Button
                    title="Создать"
                    onClick={createCallback}
                    disabled={!isValidForm}
                />
            </Footer>
            <BottomPadding />
        </Root>
    );
};

export default ResourcesCreate;
