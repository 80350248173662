import { createSelector, Selector } from 'reselect';
import { StateModel } from '../../../global/redux/state.model';
import { ChatInfoCard } from '../models/chat-info-card.model';
import { ChatInfoList } from '../models/chat-info-list.model';
import { ChatInfoState } from './chat-info.types';

export const getChatInfoState = (state: StateModel): ChatInfoState => state.chatInfo;

export const getChatInfoList: Selector<StateModel, ChatInfoList[]> =
    createSelector(
        [getChatInfoState],
        (chatInfoState) => chatInfoState.chatInfo,
    );

export const getChatInfoTotalCount: Selector<StateModel, number> =
    createSelector(
        [getChatInfoState],
        (chatInfoState) => chatInfoState.totalCount,
    );

export const getChatInfoCard: Selector<StateModel, ChatInfoCard | null> =
    createSelector(
        [getChatInfoState],
        (chatInfoState) => chatInfoState.chatInfoCard,
    );
