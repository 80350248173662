import { EntityForSelect } from '../../../global/components/multiple-select-entity/multiple-select-entity';
import { MemberList } from '../models/member-list.model';
import { MemberMinList } from '../models/member-min-list.model';
import { MembersRepository } from '../repositories/members.repository';

export const loadMembersForSelect = async (
    searchString: string | null,
): Promise<EntityForSelect[]> => {
    const result = await MembersRepository.getList(
        {
            pageNo: 0,
            pageSize: 10,
        },
        {
            searchString: searchString || '',
            hasNotAvatar: false,
        },
    );
    return parseMembersListToEntityForSelect(result.items);
};

export const parseMemberItemToEntityForSelect = (
    member: MemberList | MemberMinList | null,
): EntityForSelect | null =>
    member
        ? {
            id: member.id,
            name: member.name,
        }
        : null;

export const parseMembersListToEntityForSelect = (
    members: MemberMinList[],
): EntityForSelect[] =>
    members
        .map(parseMemberItemToEntityForSelect)
        .filter(Boolean) as EntityForSelect[];
