export type DayItem = {
    label: string;
    value: string;
};

type DayType = {
    [key: string]:DayItem;
};

export const Day: DayType = {
    '1': {
        label: 'Понедельник',
        value: '1',
    },
    '2': {
        label: 'Вторник',
        value: '2',
    },
    '3': {
        label: 'Среда',
        value: '3',
    },
    '4': {
        label: 'Четверг',
        value: '4',
    },
    '5': {
        label: 'Пятница',
        value: '5',
    },
    '6': {
        label: 'Суббота',
        value: '6',
    },
    '7': {
        label: 'Воскресенье',
        value: '7',
    },
};

export const dayOptions = Object.values(Day);
