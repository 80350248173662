export type UserRoleItem = {
    label: string;
    value: string;
};

type UserRoleType = {
    [key: string]: UserRoleItem;
};

export const UserRole: UserRoleType = {
    ADMIN: {
        label: 'Администратор',
        value: 'ADMIN',
    },
    LEADER: {
        label: 'Лидер группы/служения',
        value: 'LEADER',
    },
    USER: {
        label: 'Пользователь',
        value: 'USER',
    },
};

export const UserRoleOptions = Object.values(UserRole);
