import { api } from '../../../config/api';
import { BaseRepository, ResolverMethods } from '../../../global/repository/base.repository';
import { apiHost } from '../../../params';
import { OVCohort, OVCohortDTO, OVCohortType } from '../models/cohort/ov-cohort.model';
import { CreateOVCohortParams } from '../types/cohort/create-ov-cohort-params';
import { GetOVCohortListBodyParams } from '../types/cohort/get-ov-cohort-list-body-params';
import { GetOVCohortListQueryParams } from '../types/cohort/get-ov-cohort-list-query-params';

export class OVCohortRepository extends BaseRepository {
    static defaultUrl = `${apiHost}/api/v1/fof-cohorts/`;
    static api = api;

    static getList(queryParams: GetOVCohortListQueryParams, bodyData: GetOVCohortListBodyParams) {
        return OVCohortRepository.resolveCollection<OVCohortDTO, OVCohort>({
            url: '',
            method: ResolverMethods.post,
            queryParams,
            bodyData,
            model: OVCohort,
            modelType: OVCohortType,
        });
    };

    static getCard(id: number) {
        return OVCohortRepository.resolve<OVCohortDTO, OVCohort>({
            url: `${id}/`,
            method: ResolverMethods.get,
            model: OVCohort,
            modelType: OVCohortType,
        });
    };

    static create(bodyData: CreateOVCohortParams) {
        return OVCohortRepository.resolve<OVCohortDTO, OVCohort>({
            url: 'create',
            method: ResolverMethods.post,
            bodyData,
            model: OVCohort,
            modelType: OVCohortType,
        });
    };

    static edit(id: number, bodyData: CreateOVCohortParams) {
        return OVCohortRepository.resolve<OVCohortDTO, OVCohort>({
            url: `${id}/update`,
            method: ResolverMethods.put,
            bodyData,
            model: OVCohort,
            modelType: OVCohortType,
        });
    };

    static delete(id: number) {
        return OVCohortRepository.resolveWithoutModel<void>({
            url: `${id}/delete`,
            method: ResolverMethods.delete,
        });
    };

    static run(id: number) {
        return OVCohortRepository.resolveWithoutModel<void>({
            url: `${id}/run`,
            method: ResolverMethods.post,
        });
    };
}
