import { BaseActionResult } from '../../../global/helpers/actions';
import { FamilyCard } from '../models/family-card.model';
import { FamilyList } from '../models/family-list.model';

export class FamilyState {
    family: FamilyList[] = [];
    totalCount: number = 0;
    familyCard: FamilyCard | null = null;
}

export type UpdateFamilyAction = {
    family: FamilyList[];
    totalCount: number;
}

export enum FamilyActionsEnum {
    updateFamily = 'UPDATE_FAMILY',
    updateFamilyCard = 'UPDATE_FAMILY_CARD',
}

export type FamilyActions =
    BaseActionResult<
        UpdateFamilyAction,
        typeof FamilyActionsEnum.updateFamily
    >
    | BaseActionResult<
        FamilyCard | null,
        typeof FamilyActionsEnum.updateFamilyCard
    >;
