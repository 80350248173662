import { MessagesState, MessagesActions, MessagesActionsEnum } from './messages.types';

const initialState = new MessagesState();

const messagesReducer = (state = initialState, action: MessagesActions) => {
    switch (action.type) {
        case MessagesActionsEnum.updateMessages:
            return {
                ...state,
                messages: action.payload.messages,
                totalCount: action.payload.totalCount,
            };
        case MessagesActionsEnum.updateMessageCard:
            return {
                ...state,
                messageCard: action.payload,
            };
        default:
            return state;
    }
};

export default messagesReducer;
