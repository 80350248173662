import React, { useCallback } from 'react';
import classNames from 'classnames';
import { ButtonStyle } from './index.styles';

type ButtonProps = {
    title: string;
    onClick?: () => void;
    disabled?: boolean;
    secondary?: boolean;
    className?: string;
};

export const Button = ({ title, onClick, disabled, className, secondary }: ButtonProps) => {
    const cssClass = classNames(
        {
            'is-disabled': !!disabled,
            'is-secondary': !!secondary,
        },
        className,
    );

    const onClickCallback = useCallback(() => {
        if (disabled || !onClick) {
            return;
        }
        onClick();
    }, [onClick, disabled]);
    return <ButtonStyle className={cssClass} onClick={onClickCallback}>
        {title}
    </ButtonStyle>;
};
