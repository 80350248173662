import { api } from '../../../config/api';
import { BaseRepository, ResolverMethods } from '../../../global/repository/base.repository';
import { apiHost } from '../../../params';
import { MemberCard, MemberCardDTO, MemberCardType } from '../models/member-card.model';
import { MemberList, MemberListDTO, MemberListType } from '../models/member-list.model';
import { CreateMemberParams } from '../types/create-member-params';
import { GetMembersListBodyParams } from '../types/get-members-list-body-params';
import { GetMembersListQueryParams } from '../types/get-members-list-query-params';
import { RegistrationMemberParams } from '../types/registration-member-params';

export class MembersRepository extends BaseRepository {
    static defaultUrl = `${apiHost}/api/v1/members/`;
    static api = api;

    static getList(queryParams: GetMembersListQueryParams, bodyData: GetMembersListBodyParams) {
        return MembersRepository.resolveCollection<MemberListDTO, MemberList>({
            url: '',
            method: ResolverMethods.post,
            queryParams,
            bodyData,
            model: MemberList,
            modelType: MemberListType,
        });
    };

    static create(bodyData: CreateMemberParams) {
        return MembersRepository.resolve<MemberCardDTO, MemberCard>({
            url: 'create',
            method: ResolverMethods.post,
            bodyData,
            model: MemberCard,
            modelType: MemberCardType,
        });
    };

    static registration(bodyData: RegistrationMemberParams, avatar: File | null) {
        const formData = new FormData();
        if (avatar) {
            formData.append('avatar', avatar);
        }
        Object.keys(bodyData).forEach(key => {
            const value = bodyData[key];
            if (value) {
                formData.append(key, value);
            }
        });
        return MembersRepository.resolveWithoutModel({
            url: 'registration',
            method: ResolverMethods.post,
            bodyData: formData,
        });
    };
    static getAvatar(id: number) {
        return MembersRepository.resolveWithoutModel<string>({
            url: `${id}/avatar`,
            method: ResolverMethods.get,
        });
    };

    static uploadNewAvatar(id: number, file: File) {
        const formData = new FormData();
        formData.append('photo', file);

        return MembersRepository.resolve<MemberCardDTO, MemberCard>({
            url: `${id}/upload-avatar`,
            bodyData: formData,
            method: ResolverMethods.post,
            model: MemberCard,
            modelType: MemberCardType,
        });
    };

    static deleteAvatar(id: number) {
        return MembersRepository.resolve<MemberCardDTO, MemberCard>({
            url: `${id}/delete-avatar`,
            method: ResolverMethods.delete,
            model: MemberCard,
            modelType: MemberCardType,
        });
    };

    static getCard(id: number) {
        return MembersRepository.resolve<MemberCardDTO, MemberCard>({
            url: `${id}/`,
            model: MemberCard,
            modelType: MemberCardType,
        });
    };

    static update(id: number, bodyData: CreateMemberParams) {
        return MembersRepository.resolve<MemberCardDTO, MemberCard>({
            url: `${id}/update`,
            method: ResolverMethods.put,
            bodyData,
            model: MemberCard,
            modelType: MemberCardType,
        });
    };

    static updateAboutMe(id: number, aboutMe: string | null) {
        return MembersRepository.resolve<MemberCardDTO, MemberCard>({
            url: `${id}/update-about-me`,
            method: ResolverMethods.put,
            bodyData: {
                aboutMe,
            },
            model: MemberCard,
            modelType: MemberCardType,
        });
    };

    static updatePastorNote(id: number, pastorNote: string | null) {
        return MembersRepository.resolve<MemberCardDTO, MemberCard>({
            url: `${id}/update-pastor-note`,
            method: ResolverMethods.put,
            bodyData: {
                pastorNote,
            },
            model: MemberCard,
            modelType: MemberCardType,
        });
    };

    static delete(id: number) {
        return MembersRepository.resolveWithoutModel<void>({
            url: `${id}/delete`,
            method: ResolverMethods.delete,
        });
    };

    static createUsers() {
        return MembersRepository.resolveWithoutModel<void>({
            url: 'create/users',
            method: ResolverMethods.post,
        });
    };
}
