import React, { useCallback, useMemo } from 'react';
import { CurrentInput, CurrentTextarea, Description } from './text-input.styles';

interface TextInputProps {
    className?: string;
    autofocus?: boolean;
    style?: React.CSSProperties;
    onChange: (v: string | null) => void;
    onEnter?: () => void;
    onBlur?: () => void;
    value: string | number| null;
    description?: string;
    typeInput?: string;
    asTextarea?: boolean;
}

export const TextInput: React.FC<TextInputProps> = ({
    className,
    style,
    autofocus,
    onChange,
    onEnter,
    onBlur,
    description,
    value,
    typeInput,
    asTextarea,
}) => {
    const onChangeCallback = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange(e.currentTarget.value);
        },
        [onChange],
    );

    const onKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            e.stopPropagation();
        },
        [],
    );

    const onKeyUp = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            e.stopPropagation();

            if (onEnter && e.key === 'Enter') {
                onEnter();
            }
        },
        [onEnter],
    );

    const currentTypeInput = typeInput ? typeInput : 'text';
    const visibleValue = useMemo(() => {
        if (value === null) {
            return '';
        }
        return `${value}`;
    }, [value]);

    const Component = asTextarea ? CurrentTextarea : CurrentInput;

    return (
        <>
            {/* @ts-expect-error */}
            <Component
                type={currentTypeInput}
                style={style}
                className={className}
                autoFocus={autofocus}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                onChange={onChangeCallback}
                onBlur={onBlur}
                value={visibleValue}
            />
            {
                description &&
                <Description>{description}</Description>
            }
        </>
    );
};
