import { api } from '../../../config/api';
import { LocalAnyType } from '../../../global/helpers/type.helper';
import { BaseRepository } from '../../../global/repository/base.repository';
import { apiHost } from '../../../params';
import { Payment, PaymentDTO, PaymentType } from '../models/payment.model';

export class PaymentsRepository extends BaseRepository {
    static defaultUrl = `${apiHost}/api/v1/members/payments/`;
    static api = api;

    static getByFamily(familyId: number) {
        return PaymentsRepository.resolveArray<PaymentDTO, Payment>({
            url: `family/${familyId}`,
            model: Payment,
            modelType: PaymentType,
        });
    };

    static getByMember(memberId: number) {
        return PaymentsRepository.resolveArray<PaymentDTO, Payment>({
            url: `profile/${memberId}`,
            model: Payment,
            modelType: PaymentType,
        });
    };

    static getDonatedRecently(memberId: number) {
        return PaymentsRepository.resolveWithoutModel({
            url: `profile/${memberId}/donated-recently`,
        }).then((res: LocalAnyType) => !!res?.donatedRecently);
    };
}
