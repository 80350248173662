import { createSelector, Selector } from 'reselect';
import { StateModel } from '../../../../global/redux/state.model';
import { OVCohortState } from './ov-cohort.types';
import { OVCohort } from '../../models/cohort/ov-cohort.model';

export const getOVCohortState = (state: StateModel): OVCohortState => state.ovCohort;

export const getOVCohortList: Selector<StateModel, OVCohort[]> =
    createSelector(
        [getOVCohortState],
        (ovCohortState) => ovCohortState.cohorts,
    );

export const getOVCohortTotalCount: Selector<StateModel, number> =
    createSelector(
        [getOVCohortState],
        (ovCohortState) => ovCohortState.totalCount,
    );

export const getOVCohortCard: Selector<StateModel, OVCohort | null> =
    createSelector(
        [getOVCohortState],
        (ovCohortState) => ovCohortState.cohortCard,
    );
