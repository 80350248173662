import { MinistryList } from '../models/ministry-list.model';

export const setChildMinistries = (
    parents: MinistryList[],
    parentId: number,
    child: MinistryList[],
): MinistryList[] => {
    const findIndex = parents.findIndex(el => el.id === parentId);
    if (findIndex !== -1) {
        parents[findIndex].child = child;
        return parents;
    }
    for (let i = 0; i < parents.length; i++) {
        if (parents[i].child.length) {
            parents[i].child = setChildMinistries(parents[i].child, parentId, child);
        }
    }
    return parents;
};