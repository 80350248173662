import { BaseActionResult } from '../../../global/helpers/actions';
import { GroupCard } from '../models/group-card.model';
import { GroupList } from '../models/group-list.model';

export class GroupsState {
    groups: GroupList[] = [];
    totalCount: number = 0;
    groupCard: GroupCard | null = null;
    allGroups: GroupCard[] = [];
}

export type UpdateGroupsAction = {
    groups: GroupList[];
    totalCount: number;
}

export enum GroupsActionsEnum {
    updateGroups = 'UPDATE_GROUPS',
    updateGroupCard = 'UPDATE_GROUP_CARD',
    updateAllGroups = 'UPDATE_ALL_GROUPS',
}

export type GroupsActions =
    BaseActionResult<
        UpdateGroupsAction,
        typeof GroupsActionsEnum.updateGroups
    >
    | BaseActionResult<
        GroupCard | null,
        typeof GroupsActionsEnum.updateGroupCard
    >
    | BaseActionResult<
        GroupCard[],
        typeof GroupsActionsEnum.updateAllGroups
    >;
