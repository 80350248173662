import * as t from 'io-ts';
import { MemberList, MemberListType } from '../../../members/models/member-list.model';
import { OVCohortList, OVCohortListType } from '../cohort/ov-cohort-list.model';
import { OVRegistrationStatusEnum } from '../../enums/ov-registration-status.enum';

export const OVRegistrationType = t.type({
    id: t.number,
    profile: MemberListType,
    cohort: OVCohortListType,
    isStudent: t.boolean,
    status: t.union([t.null, t.string]),
});

export type OVRegistrationDTO = t.TypeOf<typeof OVRegistrationType>;

class OVRegistration {
    id: number;
    profile: MemberList;
    cohort: OVCohortList;
    isStudent: boolean;
    status: OVRegistrationStatusEnum;

    constructor(dto: OVRegistrationDTO) {
        this.id = dto.id;
        this.profile = new MemberList(dto.profile);
        this.cohort = new OVCohortList(dto.cohort);
        this.isStudent = !!dto.isStudent;
        // @ts-ignore
        this.status = dto.status && OVRegistrationStatusEnum[dto.status] ? OVRegistrationStatusEnum[dto.status] : OVRegistrationStatusEnum.START;
    }
}

export { OVRegistration };
