import { toast } from 'react-toastify';
import { baseAsyncActionCreator } from '../../../global/helpers/actions';
import { goToState } from '../../../global/helpers/url-parse.helper';
import { GlobalActionsEnum } from '../../../global/redux/global/global.types';
import { MinistryCard } from '../models/ministry-card.model';
import { MinistriesRepository } from '../repositories/ministries.repository';
import { CreateMinistryParams } from '../types/create-ministry-params';
import { GetMinistryListBodyParams } from '../types/get-ministry-list-body-params';
import { GetMinistryListQueryParams } from '../types/get-ministry-list-query-params';
import { MinistriesActionsEnum, UpdateMinistriesAction } from './ministries.types';
import { setChildMinistries } from '../helpers/main.helpers';

const updateMinistries = (queryParams: GetMinistryListQueryParams, bodyData: GetMinistryListBodyParams) =>
    baseAsyncActionCreator<UpdateMinistriesAction>(async () => {
        const result = await MinistriesRepository.getList(queryParams, bodyData);
        return [MinistriesActionsEnum.updateMinistries, {
            ministries: result.items,
            totalCount: result.totalCount,
        }];
    });

const updateChildMinistries = (parentId: string | null) =>
    baseAsyncActionCreator<UpdateMinistriesAction>(async (state) => {
        const result = await MinistriesRepository.getChildList(parentId);
        if (!parentId) {
            return [MinistriesActionsEnum.updateMinistries, {
                ministries: result,
                totalCount: result.length,
            }];
        }
        const ministries = setChildMinistries(state.ministries.ministries, Number(parentId), result);

        return [MinistriesActionsEnum.updateMinistries, {
            ministries: ministries,
            totalCount: ministries.length,
        }];
    });

const closeMinistryChild = (parentId: number) =>
    baseAsyncActionCreator<UpdateMinistriesAction>(async (state) => {
        const ministries = setChildMinistries(state.ministries.ministries, Number(parentId), []);

        return [MinistriesActionsEnum.updateMinistries, {
            ministries: ministries,
            totalCount: ministries.length,
        }];
    });

const clearMinistryCard = () =>
    baseAsyncActionCreator<MinistryCard | null>(async () => {
        return [MinistriesActionsEnum.updateMinistryCard, null];
    });

const loadMinistryCard = (id: number) =>
    baseAsyncActionCreator<MinistryCard | null>(async () => {
        const result = await MinistriesRepository.getCard(id);
        return [MinistriesActionsEnum.updateMinistryCard, result];
    });

const createMinistryCard = (params: CreateMinistryParams) =>
    baseAsyncActionCreator<string>(async () => {
        await MinistriesRepository.create(params);
        toast.success('Запись успешно добавлена!');
        goToState('/ministries');
        return [GlobalActionsEnum.empty, ''];
    });

const updateMinistryCard = (id: number, params: CreateMinistryParams) =>
    baseAsyncActionCreator<string>(async () => {
        await MinistriesRepository.update(id, params);
        toast.success('Запись успешно обновлена!');
        goToState(`/ministry/${id}`);
        return [GlobalActionsEnum.empty, ''];
    });

const deleteMinistryCard = (id: number) =>
    baseAsyncActionCreator<string>(async () => {
        await MinistriesRepository.delete(id);
        toast.success('Запись успешно удалена!');
        return [GlobalActionsEnum.empty, ''];
    });

export {
    updateMinistries,
    clearMinistryCard,
    loadMinistryCard,
    createMinistryCard,
    updateMinistryCard,
    deleteMinistryCard,
    updateChildMinistries,
    closeMinistryChild,
};
