import React, { useCallback } from 'react';
import { Root } from './create-send-list-from-csv.styles';
import { Button } from '../../../../global/components/button';
import { FileUploadInput, FileUploadLabel } from '../../../members/pages/card/member-card.styles';
import { toast } from 'react-toastify';
import { uploadSCVSendList } from '../../redux/actions/send-list.actions';
import { useDispatch } from 'react-redux';
import { useSpinner } from '../../../../global/hooks/use-spinner';

type CreateSendListFromCSVProps = {
    label?: string;
}

const CreateSendListFromCSV = ({
    label,
}: CreateSendListFromCSVProps) => {
    const dispatch = useDispatch();
    const spinnerRunner = useSpinner(dispatch);

    const handleCSVToUpload = useCallback(
        async (e: React.ChangeEvent<HTMLInputElement>) => {
            const filesList = e.currentTarget.files;
            const allowedExtensions = /(\.csv)$/i;
            if (!allowedExtensions.exec(e.currentTarget.value)) {
                toast.error('Недопустимый формат файла (допустимые форматы: .csv)');
                e.currentTarget.value = '';
                return;
            }
            if (!filesList) {
                return;
            }

            await spinnerRunner(uploadSCVSendList(filesList[0]));
        },
        [],
    );
    return (
        <Root>
            <FileUploadLabel htmlFor="send-list-uploading">
                <Button
                    title={label || 'Загрузить файл рассылки'}
                />
            </FileUploadLabel>
            <FileUploadInput type="file" id="send-list-uploading" multiple={false} onChange={handleCSVToUpload} />
        </Root>
    );
};

export default CreateSendListFromCSV;
