import * as t from 'io-ts';
import { MemberMinList, MemberMinListType } from '../../members/models/member-min-list.model';
import { UserListType } from './user-list.model';
import { UserPermissions, UserPermissionsItem } from './user-permissions.model';
import { UserRole, UserRoleItem } from './user-role.model';

export const UserPermissionType = t.type({
    id: t.number,
    name: t.string,
});

export const UserCardType = t.intersection([
    UserListType,
    t.type({
        member: t.union([MemberMinListType, t.null]),
        permissions: t.array(UserPermissionType),
    })
]);

export type UserCardDTO = t.TypeOf<typeof UserCardType>;

class UserCard {
    id: number;
    login: string;
    role: UserRoleItem;
    member: MemberMinList | null;
    permissions: UserPermissionsItem[];

    constructor(dto: UserCardDTO) {
        this.id = dto.id;
        this.login = dto.login;
        this.role = UserRole[dto.role] || UserRole.USER;
        this.member = dto.member ? new MemberMinList(dto.member) : null;
        this.permissions = dto.permissions.map(el =>el.name).map(el => UserPermissions[el]).filter(Boolean) as UserPermissionsItem[];
    }
}

export { UserCard };
