import { AnyAction, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { BaseActionResult } from '../../helpers/actions';
import { addDisplayedLocalSpinner, removeDisplayedLocalSpinner } from '../../redux/global/global.actions';
import { StateModel } from '../../redux/state.model';

export function useLocalSpinner(
    dispatch: Dispatch,
) {
    const startCallback = async <R = void>(
        createdAction: ThunkAction<
            Promise<BaseActionResult<R> | null>,
            StateModel,
            null,
            BaseActionResult<R>
        >,
        key: string,
    ) => {
        const actionStartSpinner = addDisplayedLocalSpinner(key) as unknown as AnyAction;
        const actionStopSpinner = removeDisplayedLocalSpinner(key) as unknown as AnyAction;
        dispatch(actionStartSpinner);
        try {
            // @ts-expect-error
            await dispatch(createdAction);
            dispatch(actionStopSpinner);
        } finally {
            dispatch(actionStopSpinner);
        }
    };

    return startCallback;
}
