import * as t from 'io-ts';

export const FamilyListType = t.type({
    id: t.number,
    name: t.string,
});

export type FamilyListDTO = t.TypeOf<typeof FamilyListType>;

class FamilyList {
    id: number;
    name: string;

    constructor(dto: FamilyListDTO) {
        this.id = dto.id;
        this.name = dto.name;
    }
}

export { FamilyList };
