import { baseAsyncActionCreator } from '../../../../global/helpers/actions';
import { OVRegistrationActionsEnum, UpdateOVRegistrationAction } from './ov-registration.types';
import { GetOVRegistrationListQueryParams } from '../../types/registration/get-ov-registration-list-query-params';
import { GetOVRegistrationListBodyParams } from '../../types/registration/get-ov-registration-list-body-params';
import { OVRegistrationRepository } from '../../repositories/ov-registration.repository';

const updateOVRegistrations = (queryParams: GetOVRegistrationListQueryParams, bodyData: GetOVRegistrationListBodyParams) =>
    baseAsyncActionCreator<UpdateOVRegistrationAction>(async () => {
        const result = await OVRegistrationRepository.getList(queryParams, bodyData);
        return [OVRegistrationActionsEnum.updateOVRegistrations, {
            registrations: result.items,
            totalCount: result.totalCount,
        }];
    });

export {
    updateOVRegistrations,
};