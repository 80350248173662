import * as t from 'io-ts';

export const MemberMinListType = t.type({
    id: t.number,
    firstName: t.union([t.string, t.null]),
    lastName: t.union([t.string, t.null]),
});

export type MemberMinListDTO = t.TypeOf<typeof MemberMinListType>;

class MemberMinList {
    id: number;
    firstName: string;
    lastName: string;

    constructor(dto: MemberMinListDTO) {
        this.id = dto.id;
        this.firstName = dto.firstName || '';
        this.lastName = dto.lastName || '';
    }

    get name() {
        let result: string = '';
        if (!this) {
            return result;
        }
        if (this.lastName) {
            result = `${this.lastName}`;
        }
        if (this.firstName) {
            result = `${result}${result ? ' ' : ''}${this.firstName}`;
        }
        return result;
    }
}

export { MemberMinList };
