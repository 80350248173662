import { EntityForSelect } from '../../../global/components/multiple-select-entity/multiple-select-entity';
import { ChatInfoList } from '../models/chat-info-list.model';
import { ChatInfoRepository } from '../repositories/chat-info.repository';

export const loadChatInfoForSelect = async (searchString: string): Promise<EntityForSelect[]> => {
    const result = await ChatInfoRepository.getList({
        pageNo: 0,
        pageSize: 10,
    }, {
        searchString,
        hasNotLink: false,
    });
    return parseChatInfoListToEntityForSelect(result.items);
};

export const parseChatInfoItemToEntityForSelect = (member: ChatInfoList): EntityForSelect => ({
    id: member.id,
    name: member.chatName,
});

export const parseChatInfoListToEntityForSelect = (members: ChatInfoList[]): EntityForSelect[] => members.map(parseChatInfoItemToEntityForSelect);
