import React, { useCallback, useMemo } from 'react';
import { SingleValue } from 'react-select';
import AsyncSelect from 'react-select/async';
import { LocalAnyType } from '../../helpers/type.helper';
import { Root } from './single-select-entity.styles';

export type EntityForSelect = {
    id: number;
    name: string;
};

type SingleSelectEntityProps = {
    label: string;
    value: EntityForSelect | null;
    maxWidth?: number;
    onChange: (value: EntityForSelect | null) => void;
    loadOptions: (searchString: string) => Promise<EntityForSelect[]>;
};

let timeout: LocalAnyType = null;

const SingleSelectEntity = (props: SingleSelectEntityProps) => {
    const onChangeCallback = useCallback(
        (newValue: SingleValue<EntityForSelect>) => {
            props.onChange(newValue);
        },
        [props.onChange],
    );

    const loadOptionsCallback = (searchString: string | null) => {
        return new Promise((resolve) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                resolve(props.loadOptions(searchString || ''));
            }, 500);
        });
    };
    const selectStyles = useMemo(
        () => ({
            menuPortal: (base: LocalAnyType) => ({ ...base, zIndex: 9999 }),
        }),
        [],
    );

    return (
        <Root
            title={props.label}
            maxWidth={props.maxWidth}
            value={
                <AsyncSelect
                    defaultValue={props.value}
                    value={props.value}
                    onChange={onChangeCallback}
                    loadOptions={loadOptionsCallback}
                    defaultOptions
                    styles={selectStyles}
                    getOptionValue={(option) => `${option['id']}`}
                    getOptionLabel={(option) => `${option['name']}`}
                    menuPortalTarget={document.body}
                    isClearable
                />
            }
        />
    );
};

export default SingleSelectEntity;
