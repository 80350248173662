import * as t from 'io-ts';
import { ChatInfoList, ChatInfoListType } from '../../chat-info/models/chat-info-list.model';
import { MemberMinList, MemberMinListType } from '../../members/models/member-min-list.model';
import { Day, DayItem } from '../../daily-messages/models/day.model';

export const GroupCardType = t.type({
    id: t.number,
    name: t.string,
    chat: t.union([ChatInfoListType, t.null]),
    lead: t.union([MemberMinListType, t.null]),
    assist: t.union([MemberMinListType, t.null, t.undefined]),
    district: t.string,
    coords: t.string,
    day: t.union([t.number, t.null]),
    startTime: t.union([t.string, t.null]),
});

export type GroupCardDTO = t.TypeOf<typeof GroupCardType>;

class GroupCard {
    id: number;
    name: string;
    chat: ChatInfoList | null;
    lead: MemberMinList | null;
    assist: MemberMinList | null;
    district: string;
    coords: string;
    day: DayItem | null;
    startTime: string | null;

    constructor(dto: GroupCardDTO) {
        this.id = dto.id;
        this.name = dto.name;
        this.chat = dto.chat ? new ChatInfoList(dto.chat) : null;
        this.lead = dto.lead ? new MemberMinList(dto.lead) : null;
        this.assist = dto.assist ? new MemberMinList(dto.assist) : null;
        this.district = dto.district;
        this.coords = dto.coords;
        this.day = Day[`${dto.day}`] || null;
        this.startTime = dto.startTime;
    }
}

export { GroupCard };
