import { createSelector, Selector } from 'reselect';
import { StateModel } from '../../../../global/redux/state.model';
import { OVRegistrationState } from './ov-registration.types';
import { OVRegistration } from '../../models/registration/ov-registration.model';

export const getOVRegistrationState = (state: StateModel): OVRegistrationState => state.ovRegistration;

export const getOVRegistrationList: Selector<StateModel, OVRegistration[]> =
    createSelector(
        [getOVRegistrationState],
        (ovRegistrationState) => ovRegistrationState.registrations,
    );

export const getOVRegistrationTotalCount: Selector<StateModel, number> =
    createSelector(
        [getOVRegistrationState],
        (ovRegistrationState) => ovRegistrationState.totalCount,
    );
