import { ResourcesActions, ResourcesActionsEnum, ResourcesState } from './resources.types';

const initialState = new ResourcesState();

const resourcesReducer = (state = initialState, action: ResourcesActions) => {
    switch (action.type) {
        case ResourcesActionsEnum.updateResources:
            return {
                ...state,
                resources: action.payload.resources,
                totalCount: action.payload.totalCount,
            };
        case ResourcesActionsEnum.updateResourcesCard:
            return {
                ...state,
                resourcesCard: action.payload,
            };
        default:
            return state;
    }
};

export default resourcesReducer;
