import React from 'react';
import { StyledButton } from './button.styles';
import classNames from 'classnames';

interface ButtonProps {
    isPressed?: boolean;
    style?: React.CSSProperties;
    className?: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    noBorderRadius?: boolean;
    disabled?: boolean;
}

export const Button: React.FC<ButtonProps> = (props) => {
    const cssClass = classNames(
        {
            'pressed': props.isPressed,
            'no-border-radius': props.noBorderRadius
        },
        props.className,
    );

    return (
        <>
            <StyledButton
                onClick={props.onClick}
                style={props.style}
                className={cssClass}
            >
                {props.children}
            </StyledButton>
        </>
    );
};
