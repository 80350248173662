import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-modal';
import { Content, Footer, Root, Title } from './create-family-modal.styles';
import { MemberList } from '../../../members/models/member-list.model';
import MultipleSelectEntity, { EntityForSelect } from '../../../../global/components/multiple-select-entity/multiple-select-entity';
import { loadMembersForSelect, parseMembersListToEntityForSelect } from '../../../members/helpers/api.helpers';
import { CreateFamilyParams } from '../../types/create-family-params';
import { useSpinner } from '../../../../global/hooks/use-spinner';
import { createFamilyCard } from '../../redux/family.actions';
import { InfoItem } from '../../../../global/components/info-item';
import { TextInput } from '../../../../global/components/form-controls/text-input/text-input';
import { Button } from '../../../../global/components/button';

type CreateFamilyModalProps = {
    member: MemberList;
    onClose: (withUpdate?: boolean) => void;
};

const CreateFamilyModal = (props: CreateFamilyModalProps) => {
    const dispatch = useDispatch();
    const spinnerRunner = useSpinner(dispatch);
    const [name, setName] = useState<string | null>(null);
    const [childCount, setChildCount] = useState<string | null>(null);
    const [members, setMembers] = useState<EntityForSelect[]>(parseMembersListToEntityForSelect([props.member]));

    const isValidForm = useMemo(
        () => !!name && !!members.length,
        [
            name,
            members,
        ],
    );

    const createCallback = useCallback(async () => {
        if (!name || !members.length) {
            return;
        }
        const params: CreateFamilyParams = {
            name,
            childCount: Number(childCount) || 0,
            members: members.map(el => el.id),
            weddingDay: '',
        };
        await spinnerRunner(createFamilyCard(params, true));
        props.onClose(true);
    }, [
        name,
        childCount,
        members,
        spinnerRunner,
        createFamilyCard,
        props.onClose,
    ]);

    const cancelCallback = useCallback(() => {
        props.onClose(false);
    }, [props.onClose]);

    const modalStyle: Modal.Styles = useMemo(() => ({
        content: {
            width: '600px',
            height: '400px',
        }
    }), []);

    return (
        <Modal
            isOpen
            style={modalStyle}
        >
            <Root>
                <Title>Добавить семью</Title>
                <Content>
                    <InfoItem
                        title="Название *"
                        value={<TextInput
                            value={name}
                            onChange={setName}
                        />}
                    />
                    <InfoItem
                        title="Кол-во детей"
                        value={<TextInput
                            value={childCount}
                            onChange={setChildCount}
                            typeInput="number"
                        />}
                    />
                    <MultipleSelectEntity
                        label="Участники *"
                        values={members}
                        onChange={setMembers}
                        loadOptions={loadMembersForSelect}
                    />
                </Content>
                <Footer>
                    <Button
                        title="Закрыть"
                        onClick={cancelCallback}
                        secondary
                    />
                    <Button
                        title="Создать"
                        onClick={createCallback}
                        disabled={!isValidForm}
                    />
                </Footer>
            </Root>
        </Modal>
    );
};

export default CreateFamilyModal;
