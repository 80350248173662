import * as t from 'io-ts';
import { MemberMinList, MemberMinListType } from './member-min-list.model';
import { parseDateFromApi } from '../../../global/helpers/date.helper';
import { InterviewResult, InterviewResultItem } from './interview-result.model';
import { TestimonyRevision, TestimonyRevisionItem } from './testimony-revision.model';
import { TestimonyStatus, TestimonyStatusItem } from './testimony-status.model';
import { AbsenteeLetterStatus, AbsenteeLetterStatusItem } from './absentee-letter-status.model';

export const AdoptedInfoType = t.type({
    id: t.number,
    absenteeLetterStatus: t.union([t.string, t.null]),
    testimonyStatus: t.union([t.string, t.null]),
    testimonyRevision: t.union([t.string, t.null]),
    testimonyLink: t.union([t.string, t.null]),
    interviewDate: t.union([t.string, t.undefined, t.number, t.null]),
    interviewTime: t.union([t.string, t.null]),
    interviewResult: t.union([t.string, t.null]),
    ministryInfo: t.union([t.string, t.null]),
    profile: MemberMinListType,
    personalLeader: t.union([MemberMinListType, t.null]),
    presentForChurch: t.union([MemberMinListType, t.null]),
    interviewPastors: t.array(MemberMinListType),
});

export type AdoptedInfoDTO = t.TypeOf<typeof AdoptedInfoType>;

class AdoptedInfo {
    id: number;
    absenteeLetterStatus: AbsenteeLetterStatusItem | null;
    testimonyStatus: TestimonyStatusItem | null;
    testimonyRevision: TestimonyRevisionItem | null;
    testimonyLink: string | null;
    interviewDate: Date | null;
    interviewTime: string | null;
    interviewResult: InterviewResultItem | null;
    ministryInfo: string | null;
    profile: MemberMinList;
    personalLeader: MemberMinList | null;
    presentForChurch: MemberMinList | null;
    interviewPastors: MemberMinList[];

    constructor(dto: AdoptedInfoDTO) {
        this.id = dto.id;
        this.absenteeLetterStatus = dto.absenteeLetterStatus ? AbsenteeLetterStatus[dto.absenteeLetterStatus] : null;
        this.testimonyStatus = dto.testimonyStatus ? TestimonyStatus[dto.testimonyStatus] : null;
        this.testimonyRevision = dto.testimonyRevision ? TestimonyRevision[dto.testimonyRevision] : null;
        this.testimonyLink = dto.testimonyLink;
        this.interviewDate = parseDateFromApi(dto.interviewDate);
        this.interviewTime = dto.interviewTime;
        this.interviewResult = dto.interviewResult ? InterviewResult[dto.interviewResult] : null;
        this.ministryInfo = dto.ministryInfo;
        this.profile = new MemberMinList(dto.profile);
        this.personalLeader = dto.personalLeader ? new MemberMinList(dto.personalLeader) : null;
        this.presentForChurch = dto.presentForChurch ? new MemberMinList(dto.presentForChurch) : null;
        this.interviewPastors = dto.interviewPastors.map(el => new MemberMinList(el));
    }
}

export { AdoptedInfo };
