export type CompleteCourseItem = {
    label: string;
    value: string;
};

type CompleteCourseType = {
    [key: string]: CompleteCourseItem;
};

export const CompleteCourse: CompleteCourseType = {
    COMPLETE_AU: {
        label: 'Прошёл АУ',
        value: 'COMPLETE_AU',
    },
    NOT_COMPLETE_AU: {
        label: 'Не прошёл АУ',
        value: 'NOT_COMPLETE_AU',
    },
};

export const completeCourseOptions = Object.values(CompleteCourse);
