import styled, { css } from 'styled-components/macro';
import { colors, palette } from '../../../../../global/styles/variables';
import { OVRegistrationStatusEnum } from '../../../enums/ov-registration-status.enum';

const Root = styled.div`
    width: 100%;
    min-height: 200px;
    padding: 12px;
    box-sizing: border-box;
    position: relative;

    .action-icon {
        cursor: pointer;
        fill: ${colors.buttonStaticBack};
        margin-left: 12px;
    }
`;

const ActionWrap = styled.div`
    display: flex;
`;

const AnswersButton = styled.div<{ status?: OVRegistrationStatusEnum }>`
    cursor: pointer;
    padding: 2px 4px;
    border-radius: 4px;
    background: ${palette.grayLight};
    color: ${colors.black};
    margin: 4px 0;

    ${(props) => props.status === OVRegistrationStatusEnum.START ? css`
        background: ${palette.red};
        color: ${colors.white};
    ` : ''}
    ${(props) => props.status === OVRegistrationStatusEnum.IN_PROCESS ? css`
        background: ${palette.grayLight};
    ` : ''}
    ${(props) => props.status === OVRegistrationStatusEnum.COMPLETE ? css`
        background: ${palette.green};
        color: ${colors.white};
    ` : ''}
`;

export { Root, ActionWrap, AnswersButton };
