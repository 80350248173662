import { createSelector, Selector, ParametricSelector } from 'reselect';
import { getParamFromUrl } from '../../helpers/url-parse.helper';

import { StateModel } from '../state.model';
import { GlobalState } from './global.types';

export const getGlobal = (state: StateModel): GlobalState => state.global;

export const getActiveKeys: Selector<StateModel, string[]> =
    createSelector([getGlobal], (global) => global.activeKeys);

export const keyIsPress: ParametricSelector<StateModel, string, boolean> =
    createSelector([getActiveKeys, (state: StateModel, key: string) => key], (activeKeys, key) => activeKeys.some(activeKey => activeKey === key));

export const makeKeyIsPress = (key: string) => (state: StateModel) => keyIsPress(state, key);

export const getIsShowGlobalSpinner: Selector<StateModel, boolean> =
    createSelector([getGlobal], (global) => global.isShowGlobalSpinner);

export const getIsShowLocalSpinner: ParametricSelector<StateModel, string, boolean> =
    createSelector(
        [
            getGlobal,
            (state: StateModel, key: string) => key,
        ],
        (global, key) => global.displayedLocalSpinners.includes(key),
    );

export const makeGetIsShowLocalSpinner = (key: string) => (state: StateModel) => getIsShowLocalSpinner(state, key);

export const getLocationSearch: Selector<StateModel, string> =
    createSelector([getGlobal], (global) => global.locationSearch || window.location.hash);

export const getLocationSearchParam: ParametricSelector<StateModel, string, string | null> =
    createSelector(
        [
            getLocationSearch,
            (state: StateModel, key: string) => key,
        ],
        (locationSearch, key) => getParamFromUrl(key, locationSearch),
    );

export const getLocationSearchParamByKey = (key: string) => (state: StateModel) => getLocationSearchParam(state, key);

export const getIsOpenMenu: Selector<StateModel, boolean> =
    createSelector([getGlobal], (global) => global.isOpenMenu);
