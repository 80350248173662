import { api } from '../../config/api';
import { LocalAnyType } from '../../global/helpers/type.helper';
import { BaseRepository, ResolverMethods } from '../../global/repository/base.repository';
import { RegistrationMemberParams } from '../../modules/members/types/registration-member-params';
import { apiHost } from '../../params';

export class HashRepository extends BaseRepository {
    static defaultUrl = `${apiHost}/api/v1/hash/`;
    static api = api;

    static check(hash: string) {
        return HashRepository.resolveWithoutModel({
            url: 'check',
            queryParams: {
                hash_id: hash,
            },
            method: ResolverMethods.get,
        });
    };

    static create(purpose: string): Promise<string> {
        return HashRepository.resolveWithoutModel<LocalAnyType>({
            url: 'create',
            method: ResolverMethods.post,
            bodyData: {
                purpose,
            }
        }).then(res => res?.id || '');
    };

    static registrationMember(bodyData: RegistrationMemberParams, avatar: File | null): Promise<string> {
        const formData = new FormData();
        if (avatar) {
            formData.append('avatar', avatar);
        }
        Object.keys(bodyData).forEach(key => {
            const value = bodyData[key];
            if (value) {
                formData.append(key, value);
            }
        });
        return HashRepository.resolveWithoutModel<LocalAnyType>({
            url: 'user/create',
            method: ResolverMethods.post,
            queryParams: {
                hash_id: bodyData.hash,
            },
            bodyData: formData,
        });
    };
}
