import { BaseRepository, ResolverMethods } from '../../../global/repository/base.repository';
import { apiHost } from '../../../params';
import { MinistryCard, MinistryCardDTO, MinistryCardType } from '../models/ministry-card.model';
import { CreateMinistryParams } from '../types/create-ministry-params';
import { GetMinistryListBodyParams } from '../types/get-ministry-list-body-params';
import { GetMinistryListQueryParams } from '../types/get-ministry-list-query-params';
import { MinistryList, MinistryListDTO, MinistryListType } from '../models/ministry-list.model';
import { api } from '../../../config/api';

export class MinistriesRepository extends BaseRepository {
    static defaultUrl = `${apiHost}/api/v1/ministries/`;
    static api = api;

    static getList(queryParams: GetMinistryListQueryParams, bodyData: GetMinistryListBodyParams) {
        return MinistriesRepository.resolveCollection<MinistryListDTO, MinistryList>({
            url: '',
            method: ResolverMethods.post,
            queryParams,
            bodyData,
            model: MinistryList,
            modelType: MinistryListType,
        });
    };

    static getChildList(parentId: string | null) {
        return MinistriesRepository.resolveArray<MinistryListDTO, MinistryList>({
            url: 'children',
            method: ResolverMethods.get,
            queryParams: {
                ministryId: parentId,
            },
            model: MinistryList,
            modelType: MinistryListType,
        });
    };

    static create(bodyData: CreateMinistryParams) {
        return MinistriesRepository.resolve<MinistryCardDTO, MinistryCard>({
            url: 'create',
            method: ResolverMethods.post,
            bodyData,
            model: MinistryCard,
            modelType: MinistryCardType,
        });
    };

    static getCard(id: number) {
        return MinistriesRepository.resolve<MinistryCardDTO, MinistryCard>({
            url: `${id}/`,
            model: MinistryCard,
            modelType: MinistryCardType,
        });
    };

    static update(id: number, bodyData: CreateMinistryParams) {
        return MinistriesRepository.resolve<MinistryCardDTO, MinistryCard>({
            url: `${id}/update`,
            method: ResolverMethods.put,
            bodyData,
            model: MinistryCard,
            modelType: MinistryCardType,
        });
    };

    static delete(id: number) {
        return MinistriesRepository.resolveWithoutModel<void>({
            url: `${id}/delete`,
            method: ResolverMethods.delete,
        });
    };
}
