import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AuthService } from '../../../auth/services/AuthService';
import { Root, Title, UserName, Menu, MenuItem } from './header.styles';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { getIsOpenMenu } from '../../redux/global/global.selectors';
import { setIsOpenMenu } from '../../redux/global/global.actions';
import { goToState, isModule } from '../../helpers/url-parse.helper';

export const Header: React.FC = () => {
    const showForAdmin = useMemo(() => AuthService.availableForAdmin(), [AuthService.availableForAdmin]);
    const dispatch = useDispatch();
    const menuRef = useRef<HTMLDivElement | null>(null);
    const [checkCloseMenu, setCheckCloseMenu] = useState<boolean>(false);
    const userInfo = AuthService.getUserInfo();
    const isOpenMenu = useSelector(getIsOpenMenu);
    const logoutCallback = useCallback(() => {
        AuthService.logOut();
    }, [AuthService.logOut]);

    useEffect(() => {
        return () => {
            document.removeEventListener('mousedown', closeMenu);
        };
    }, []);

    const goToMyCard = useCallback(() => {
        const member = AuthService.getUserInfo()?.member;
        if (!member) {
            return false;
        }
        goToState(`/member/${member.id}`);
    }, []);

    const menuIconClass = useMemo(() => classNames(
        'menu-icon',
        {
            'is-open': !!isOpenMenu,
        },
    ), [isOpenMenu]);
    const toggleMenu = useCallback(() => {
        if (!isOpenMenu) {
            if (checkCloseMenu) {
                setCheckCloseMenu(false);
            } else {
                dispatch(setIsOpenMenu(true));
                document.addEventListener('mousedown', closeMenu);
            }
        } else {
            dispatch(setIsOpenMenu(false));
            document.removeEventListener('mousedown', closeMenu);
        }
    }, [dispatch, setIsOpenMenu, isOpenMenu, setCheckCloseMenu, checkCloseMenu]);

    const closeMenu = (event: MouseEvent) => {
        if (
            menuRef.current &&
            !menuRef.current.contains(event.target as Node)
        ) {
            dispatch(setIsOpenMenu(false));
            setCheckCloseMenu(true);
            window.setTimeout(() => setCheckCloseMenu(false), 500);
        }
    };

    const goToMembers = useCallback(() => {
        goToState('/members');
        dispatch(setIsOpenMenu(false));
    }, [goToState, dispatch, setIsOpenMenu]);

    const goToMinistries = useCallback(() => {
        goToState('/ministries');
        dispatch(setIsOpenMenu(false));
    }, [goToState, dispatch, setIsOpenMenu]);

    const goToGroups = useCallback(() => {
        goToState('/groups');
        dispatch(setIsOpenMenu(false));
    }, [goToState, dispatch, setIsOpenMenu]);

    const goToFamily = useCallback(() => {
        goToState('/family');
        dispatch(setIsOpenMenu(false));
    }, [goToState, dispatch, setIsOpenMenu]);

    const goToResources = useCallback(() => {
        goToState('/resources');
        dispatch(setIsOpenMenu(false));
    }, [goToState, dispatch, setIsOpenMenu]);

    const goToChatInfo = useCallback(() => {
        goToState('/chat-info');
        dispatch(setIsOpenMenu(false));
    }, [goToState, dispatch, setIsOpenMenu]);

    // const goToMessages = useCallback(() => {
    //     goToState('/daily-messages');
    //     dispatch(setIsOpenMenu(false));
    // }, [goToState, dispatch, setIsOpenMenu]);

    const goToUsers = useCallback(() => {
        goToState('/users');
        dispatch(setIsOpenMenu(false));
    }, [goToState, dispatch, setIsOpenMenu]);

    const goToOVCohorts = useCallback(() => {
        goToState('/ov/cohorts');
        dispatch(setIsOpenMenu(false));
    }, [goToState, dispatch, setIsOpenMenu]);

    const goToSettings = useCallback(() => {
        goToState('/service-settings');
        dispatch(setIsOpenMenu(false));
    }, [goToState, dispatch, setIsOpenMenu]);

    const title = () => {
        if (isModule('member')) {
            return 'Участники';
        }
        if (isModule('ministr')) {
            return 'Служения';
        }
        if (isModule('group')) {
            return 'Группы';
        }
        if (isModule('family')) {
            return 'Семьи';
        }
        if (isModule('resource')) {
            return 'Ресурсы';
        }
        if (isModule('chat-info')) {
            return 'Информация о чатах';
        }
        if (isModule('daily-message')) {
            return 'Рассылки';
        }
        if (isModule('user')) {
            return 'Пользователи';
        }
        if (isModule('ov')) {
            return 'Основы веры';
        }
        if (isModule('service-settings')) {
            return 'Настройки воскресного собрания';
        }
        return 'Админка РБЦ';
    };

    const classNameMenuItemMember = classNames({
        'is-select': isModule('member'),
    });

    const classNameMenuItemMinistry = classNames({
        'is-select': isModule('ministr'),
    });

    const classNameMenuItemGroup = classNames({
        'is-select': isModule('group'),
    });

    const classNameMenuItemFamily = classNames({
        'is-select': isModule('family'),
    });

    const classNameMenuItemResources = classNames({
        'is-select': isModule('resource'),
    });

    const classNameMenuItemChatInfo = classNames({
        'is-select': isModule('chat-info'),
    });

    // const classNameMenuItemMessages = classNames({
    //     'is-select': isModule('daily-message'),
    // });

    const classNameMenuItemUsers = classNames({
        'is-select': isModule('user'),
    });

    const classNameMenuItemOV = classNames({
        'is-select': isModule('ov'),
    });

    const classNameMenuItemSettings = classNames({
        'is-select': isModule('service-settings'),
    });

    const showOV = useMemo(() => !!showForAdmin || AuthService.hasOVPermission(), [showForAdmin]);
    const showService = useMemo(() => !!showForAdmin || AuthService.hasServicePermission(), [showForAdmin]);
    // const showMessages = useMemo(() => !!showForAdmin || AuthService.hasMessagesPermission(), [showForAdmin]);

    if (!userInfo) {
        return null;
    }

    return <Root>
        <MenuIcon className={menuIconClass} onClick={toggleMenu}/>
        <Title>{title()}</Title>
        <UserName onClick={goToMyCard}>{userInfo.login}</UserName>
        <LogoutIcon onClick={logoutCallback} className="logout-icon"/>
        {
            isOpenMenu &&
            <Menu ref={menuRef}>
                <MenuItem className={classNameMenuItemMember} onClick={goToMembers}>Участники</MenuItem>
                {!!showForAdmin && <MenuItem className={classNameMenuItemFamily} onClick={goToFamily}>Семьи</MenuItem>}
                {!!showForAdmin && <MenuItem className={classNameMenuItemMinistry} onClick={goToMinistries}>Служения</MenuItem>}
                {!!showForAdmin && <MenuItem className={classNameMenuItemGroup} onClick={goToGroups}>Группы</MenuItem>}
                {!!showOV && <MenuItem className={classNameMenuItemOV} onClick={goToOVCohorts}>Основы веры</MenuItem>}
                {!!showService && <MenuItem className={classNameMenuItemSettings} onClick={goToSettings}>Настройки собрания</MenuItem>}
                {!!showForAdmin && <MenuItem className={classNameMenuItemResources} onClick={goToResources}>Ресурсы</MenuItem>}
                {!!showForAdmin && <MenuItem className={classNameMenuItemChatInfo} onClick={goToChatInfo}>Информация о чатах</MenuItem>}
                {/* {!!showMessages && <MenuItem className={classNameMenuItemMessages} onClick={goToMessages}>Рассылки</MenuItem>} */}
                {!!showForAdmin && <MenuItem className={classNameMenuItemUsers} onClick={goToUsers}>Пользователи</MenuItem>}
            </Menu>
        }
    </Root>;
};
