import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '../../../global/components/button';
import { TextInput } from '../../../global/components/form-controls/text-input/text-input';
import { InfoItem } from '../../../global/components/info-item';
import { useSpinner } from '../../../global/hooks/use-spinner';
import { login as loginAction } from '../../../global/redux/global/global.actions';
import { Footer, Root } from './login.styles';

const Login = () => {
    const dispatch = useDispatch();
    const spinnerRunner = useSpinner(dispatch);
    const [login, setLogin] = useState<string | null>(null);
    const [password, setPassword] = useState<string | null>(null);

    const isValidForm = useMemo(() => !!login && !!password, [login, password]);

    const authCallback = useCallback(() => {
        if (!login || !password) {
            return;
        }
        spinnerRunner(loginAction(login, password));
    }, [login, password, spinnerRunner, loginAction]);
    return (
        <Root>
            Авторизация
            <InfoItem
                title="Логин"
                value={<TextInput value={login} onChange={setLogin} />}
            />
            <InfoItem
                title="Пароль"
                value={<TextInput value={password} onChange={setPassword} typeInput="password" />}
            />
            <Footer>
                <Button
                    title="Войти"
                    onClick={authCallback}
                    disabled={!isValidForm}
                />
            </Footer>
        </Root>
    );
};

export default Login;
