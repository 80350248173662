export type MemberConfirmationItem = {
    label: string;
    value: string;
};

type MemberConfirmationType = {
    [key: string]: MemberConfirmationItem;
};

export const MemberConfirmation: MemberConfirmationType = {
    EMPTY: {
        label: 'Ещё не отметил(а)',
        value: 'EMPTY',
    },
    CONFIRM: {
        label: 'Подтвердил(а)',
        value: 'CONFIRM',
    },
    NOT_CONFIRM: {
        label: 'Не подтвердил(а)',
        value: 'NOT_CONFIRM',
    },
};

export const confirmationOptions = Object.values(MemberConfirmation);
