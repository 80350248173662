import { FamilyActions, FamilyActionsEnum, FamilyState } from './family.types';

const initialState = new FamilyState();

const familyReducer = (state = initialState, action: FamilyActions) => {
    switch (action.type) {
        case FamilyActionsEnum.updateFamily:
            return {
                ...state,
                family: action.payload.family,
                totalCount: action.payload.totalCount,
            };
        case FamilyActionsEnum.updateFamilyCard:
            return {
                ...state,
                familyCard: action.payload,
            };
        default:
            return state;
    }
};

export default familyReducer;
