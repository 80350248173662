
export type UrlParams = {
    [key: string]: string | null | undefined;
};

export const getAllParamFromUrl = (locationSearch: string = window.location.hash): UrlParams => {
    const index = locationSearch.indexOf('?');
    if (index === -1) {
        return {};
    }
    return locationSearch
        .substring(index)
        .replace('?', '')
        .split('&')
        .reduce<UrlParams>((p, e) => {
            const a = e.split('=');
            if (!a[0]) {
                return p;
            }
            return {
                ...p,
                [decodeURIComponent(a[0])]: decodeURIComponent(a[1]),
            };
        },
        {});
};

export const getParamFromUrl = (key: string, locationSearch: string = window.location.hash): string | null => {
    const params = getAllParamFromUrl(locationSearch);
    return params[key] !== undefined && params[key] !== null ? `${params[key]}`: null;
};

export const parseParamsToSearchString = (params: Array<{key: string, value: string | number | boolean | undefined | null}>) => {
    let currentParams = getAllParamFromUrl();
    params.forEach(param => {
        currentParams = {
            ...currentParams,
            [encodeURIComponent(param.key)]: param.value === undefined || param.value === null ? undefined : encodeURIComponent(param.value),
        };
    });
    return Object.keys(currentParams)
        .filter(key => currentParams[key] !== undefined)
        .map(key => `${key}=${currentParams[key]}`)
        .join('&');
};

export const addParamsToUrl = (
    params: Array<{key: string, value: string | number | boolean | undefined | null}>,
) => {
    goToState(window.location.hash.replace('#/', '').split('?')[0], params);
};

export const goToState = (
    state: string,
    params: Array<{key: string, value: string | number | boolean | undefined | null}> = [],
) => {
    const search = params.length ? parseParamsToSearchString(params) : '';
    // eslint-disable-next-line
    const a: any = document.createElement('A');
    const newLocationSearch = `#${state}${search ? `?${search}`: ''}`;
    a.href = `/${newLocationSearch}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

export const isModule = (moduleBaseName: string): boolean => {
    return window.location.hash.indexOf(`#/${moduleBaseName}`) === 0;
};
