import { api } from '../../../config/api';
import { BaseRepository, ResolverMethods } from '../../../global/repository/base.repository';
import { apiHost } from '../../../params';
import { FamilyCard, FamilyCardDTO, FamilyCardType } from '../models/family-card.model';
import { FamilyList, FamilyListType, FamilyListDTO } from '../models/family-list.model';
import { CreateFamilyParams } from '../types/create-family-params';
import { GetFamilyListBodyParams } from '../types/get-family-list-body-params';
import { GetFamilyListQueryParams } from '../types/get-family-list-query-params';

export class FamilyRepository extends BaseRepository {
    static defaultUrl = `${apiHost}/api/v1/family/`;
    static api = api;

    static getList(queryParams: GetFamilyListQueryParams, bodyData: GetFamilyListBodyParams) {
        return FamilyRepository.resolveCollection<FamilyListDTO, FamilyList>({
            url: '',
            method: ResolverMethods.post,
            queryParams,
            bodyData,
            model: FamilyList,
            modelType: FamilyListType,
        });
    };

    static create(bodyData: CreateFamilyParams) {
        return FamilyRepository.resolve<FamilyCardDTO, FamilyCard>({
            url: 'create',
            method: ResolverMethods.post,
            bodyData,
            model: FamilyCard,
            modelType: FamilyCardType,
        });
    };

    static getCard(id: number) {
        return FamilyRepository.resolve<FamilyCardDTO, FamilyCard>({
            url: `${id}/`,
            model: FamilyCard,
            modelType: FamilyCardType,
        });
    };

    static update(id: number, bodyData: CreateFamilyParams) {
        return FamilyRepository.resolve<FamilyCardDTO, FamilyCard>({
            url: `${id}/update`,
            method: ResolverMethods.put,
            bodyData,
            model: FamilyCard,
            modelType: FamilyCardType,
        });
    };

    static delete(id: number) {
        return FamilyRepository.resolveWithoutModel<void>({
            url: `${id}/delete`,
            method: ResolverMethods.delete,
        });
    };
}
