import { createSelector, Selector } from 'reselect';
import { StateModel } from '../../../global/redux/state.model';
import { MemberCard } from '../models/member-card.model';
import { MemberList } from '../models/member-list.model';
import { MembersState } from './members.types';
import { AdoptedInfo } from '../models/adopted-info.model';

export const getMembersState = (state: StateModel): MembersState => state.members;

export const getMembersList: Selector<StateModel, MemberList[]> =
    createSelector(
        [getMembersState],
        (membersState) => membersState.members,
    );

export const getMembersTotalCount: Selector<StateModel, number> =
    createSelector(
        [getMembersState],
        (membersState) => membersState.totalCount,
    );

export const getMemberCard: Selector<StateModel, MemberCard | null> =
    createSelector(
        [getMembersState],
        (membersState) => membersState.memberCard,
    );

export const getAdoptedInfoCard: Selector<StateModel, AdoptedInfo | null> =
    createSelector(
        [getMembersState],
        (membersState) => membersState.adoptedInfoCard,
    );
