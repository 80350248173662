import * as t from 'io-ts';

export const SettingsCardType = t.type({
    translationLink: t.union([t.string, t.null]),
    plan: t.union([t.string, t.null]),
});

export type SettingsCardDTO = t.TypeOf<typeof SettingsCardType>;

class SettingsCard {
    translationLink: string | null;
    plan: string | null;

    constructor(dto: SettingsCardDTO) {
        this.translationLink = dto.translationLink;
        this.plan = dto.plan;
    }
}

export { SettingsCard };
