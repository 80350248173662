export type MemberStatusItem = {
    label: string;
    value: string;
};

type MemberStatusType = {
    [key: string]: MemberStatusItem;
};

export const MemberStatus: MemberStatusType = {
    GUEST: {
        label: 'Гость',
        value: 'GUEST',
    },
    OV: {
        label: 'На пути к членству',
        value: 'OV',
    },
    RBC: {
        label: 'Член РБЦ',
        value: 'RBC',
    },
    ON_REMARK: {
        label: 'На замечании',
        value: 'ON_REMARK',
    },
    TEMP_DROPPED: {
        label: 'Временно выбыл(а)',
        value: 'TEMP_DROPPED',
    },
    DROPPED: {
        label: 'Выбыл(а)',
        value: 'DROPPED',
    },
    EXCOMMUNICATED: {
        label: 'Отлучен(а)',
        value: 'EXCOMMUNICATED',
    },
};

export const statusOptions = Object.values(MemberStatus);
