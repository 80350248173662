import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select, { MultiValue } from 'react-select';
import { match } from 'react-router-dom';
import { Button } from '../../../../global/components/button';
import { TextInput } from '../../../../global/components/form-controls/text-input/text-input';
import { InfoItem } from '../../../../global/components/info-item';
import { InfoLine } from '../../../../global/components/info-line';
import { goToState } from '../../../../global/helpers/url-parse.helper';
import { useSpinner } from '../../../../global/hooks/use-spinner';
import { Footer, Header, Root } from './user-edit.styles';
import {
    EntityForSelect,
} from '../../../../global/components/multiple-select-entity/multiple-select-entity';
import {
    loadMembersForSelect,
    parseMemberItemToEntityForSelect,
} from '../../../members/helpers/api.helpers';
import SingleSelectEntity from '../../../../global/components/single-select-entity/single-select-entity';
import { clearUserCard, loadUserCard, updateUserCard } from '../../redux/users.actions';
import { getUserCard } from '../../redux/users.selectors';
import { CreateUserParams } from '../../types/create-user-params';
import { AuthService } from '../../../../auth/services/AuthService';
import { UserRoleItem, UserRoleOptions } from '../../models/user-role.model';
import { BottomPadding } from '../../../../global/components/main-wrap/main-wrap.styles';
import { UserPermissionsItem, userPermissionsOptions } from '../../models/user-permissions.model';

type UserEditPageRouteParams = {
    id: string;
};

type UserEditProp = {
    match: match<UserEditPageRouteParams>;
};

const UserEdit = (props: UserEditProp) => {
    const showForAdmin = useMemo(() => AuthService.availableForAdmin(), [AuthService.availableForAdmin]);
    const dispatch = useDispatch();
    const userCard = useSelector(getUserCard);
    const [id, setId] = useState<number | null>(null);
    const spinnerRunner = useSpinner(dispatch);
    const [login, setLogin] = useState<string | null>(null);
    const [password, setPassword] = useState<string | null>(null);
    const [role, setRole] = useState<UserRoleItem | null>(null);
    const [member, setMember] = useState<EntityForSelect | null>(null);
    const [permissions, setPermissions] = useState<UserPermissionsItem[]>([]);

    useEffect(() => {
        if (!showForAdmin) {
            goToState('/');
            return;
        }
    }, []);

    useEffect(() => {
        if (props.match.params.id && Number(props.match.params.id) !== id) {
            dispatch(clearUserCard());
            setId(Number(props.match.params.id));
            dispatch(loadUserCard(Number(props.match.params.id)));
        }
    }, [props.match.params.id]);

    useEffect(() => {
        if (userCard) {
            setId(userCard.id);
            setLogin(userCard.login);
            setRole(userCard.role);
            setPermissions(userCard.permissions);
            setMember(
                userCard.member
                    ? parseMemberItemToEntityForSelect(userCard.member)
                    : null,
            );
        }
    }, [userCard]);
    
    const onChangePermissions = useCallback(
        (newValue: MultiValue<UserPermissionsItem>) => {
            setPermissions(newValue.map((el) => ({ ...el })));
        },
        [setPermissions],
    );

    const isValidForm = useMemo(
        () => !!login && !!role,
        [
            login,
            role,
        ],
    );

    const saveCallback = useCallback(() => {
        if (!id || !login || !role) {
            return;
        }
        const params: CreateUserParams = {
            login,
            role: role.value,
            password: password || null,
            memberId: member?.id || null,
            permissions: permissions.map(el => el.value),
        };
        spinnerRunner(updateUserCard(id, params));
    }, [
        login,
        role,
        password,
        member,
        spinnerRunner,
        updateUserCard,
        permissions,
    ]);

    const goToList = useCallback(() => {
        goToState('/users');
    }, []);

    const goToCard = useCallback(() => {
        goToState(`/user/${props.match.params.id}`);
    }, []);

    if (!userCard) {
        return (
            <Root>
                <Header>
                    <Button title="Перейти в список" onClick={goToList} />
                </Header>
            </Root>
        );
    }

    return (
        <Root>
            <Header>
                <Button title="Перейти в список" onClick={goToList} />
                <Button title="Перейти в карточку" onClick={goToCard} />
            </Header>
            <InfoItem title="id" value={id} />
            <InfoLine>
                <InfoItem
                    title="Логин *"
                    value={<TextInput
                        value={login}
                        onChange={setLogin}
                    />}
                />
                <InfoItem
                    title="Роль *"
                    value={<Select
                        options={UserRoleOptions}
                        defaultValue={role}
                        value={role}
                        onChange={setRole}
                        isClearable
                    />}
                />
            </InfoLine>
            <InfoItem
                title="Доступ к сервисам"
                value={<Select
                    options={userPermissionsOptions}
                    defaultValue={permissions}
                    value={permissions}
                    onChange={onChangePermissions}
                    isClearable
                    isMulti
                />}
            />
            <SingleSelectEntity
                label="Член церкви"
                value={member}
                onChange={setMember}
                loadOptions={loadMembersForSelect}
            />
            <InfoItem
                title="Новый пароль (оставьте пустым, если не хотите менять пароль)"
                value={<TextInput
                    value={password}
                    onChange={setPassword}
                />}
            />
            <Footer>
                <Button
                    title="Сохранить"
                    onClick={saveCallback}
                    disabled={!isValidForm}
                />
            </Footer>
            <BottomPadding />
        </Root>
    );
};

export default UserEdit;
