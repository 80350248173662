import React from 'react';
import { Root } from './index.styles';

type InfoLineProps = {
    children: JSX.Element | Array<JSX.Element | boolean>;
};

export const InfoLine = ({ children }: InfoLineProps) => {
    return <Root>
        {
            children
        }
    </Root>;
};
