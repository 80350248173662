import { createSelector, Selector } from 'reselect';
import { StateModel } from '../../../global/redux/state.model';
import { MinistryCard } from '../models/ministry-card.model';
import { MinistryList } from '../models/ministry-list.model';
import { MinistriesState } from './ministries.types';

export const getMinistriesState = (state: StateModel): MinistriesState => state.ministries;

export const getMinistriesList: Selector<StateModel, MinistryList[]> =
    createSelector(
        [getMinistriesState],
        (ministriesState) => ministriesState.ministries,
    );

export const getMinistriesTotalCount: Selector<StateModel, number> =
    createSelector(
        [getMinistriesState],
        (ministriesState) => ministriesState.totalCount,
    );

export const getMinistryCard: Selector<StateModel, MinistryCard | null> =
    createSelector(
        [getMinistriesState],
        (ministriesState) => ministriesState.ministryCard,
    );
