import { Coordinates } from '../types/coordinates';

/**
 * Функция для того, чтобы расположить выпадашку максимально удобно, относительно того места, где её вызвали (чтобы выпадашка не уезжала за края экрана)
 */
export const setPositionPopupByClick = (popup: HTMLDivElement | null, coordinates?: Coordinates): { top: number; left: number } | null => {
    // если чего-то не прислали, забьём и не будем ничего делать
    if (!popup || !coordinates) {
        return null;
    }
    const delta = 5;
    const clickX = coordinates.x || 0;
    const clickY = coordinates.y || 0;
    // получаем координаты якоря в окне браузера
    // по-умолчанию выпадашка должна располагаться с левой нижней стороны от клика
    let top = clickY + delta;
    let left = clickX + delta;
    // привязали выпадашку и получаем её координаты в окне
    const popupRect: DOMRect = popup.getBoundingClientRect();
    // считаем координаты правого нижнего угла выпадашки (если они вылезают за пределы окна, надо что-то делать)
    const heightWithPopup: number = top + popupRect.height;
    const widthWithPopup: number = left + popupRect.width;
    // если выпадашка вылезла за правый край экрана привязываем её не к правой, а к левой нижней стороне от клика
    if (widthWithPopup > document.documentElement.clientWidth) {
        left = clickX - popupRect.width - delta;
    }
    // если выпадашка вылезла за нижний край экрана привязываем её не к нижней, а к верхней стороне от клика
    if (heightWithPopup > document.documentElement.clientHeight) {
        top = clickY - popupRect.height - delta;
    }
    // если теперь выпадашка вылезла за левый край экрана, значит она шире экрана, просто центрируем её относитльно позиции клика
    if (left < 0) {
        left = clickX - popupRect.width / 2;
    }
    // если теперь выпадашка вылезла за верхний край экрана, значит она выше экрана, просто центрируем её относитльно позиции клика
    if (top < 0) {
        top = clickY - popupRect.height / 2;
    }

    return { top, left };
};

/**
 * Считает ширину текста в зависимости от font для Canvas
 * https://developer.mozilla.org/ru/docs/Web/API/CanvasRenderingContext2D/font
 * @param text
 * @param font
 */
export const getTextMetrics = (text: string, font: string): TextMetrics | undefined => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (!context) {
        return;
    }
    context.font = font;
    return context.measureText(text);
};