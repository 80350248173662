import * as t from 'io-ts';
import { OVQuestionList, OVQuestionListType } from '../question/ov-question-list.model';

export const OVAnswerType = t.type({
    id: t.number,
    answer: t.string,
    question: OVQuestionListType,
});

export type OVAnswerDTO = t.TypeOf<typeof OVAnswerType>;

class OVAnswer {
    id: number;
    answer: string;
    question: OVQuestionList;

    constructor(dto: OVAnswerDTO) {
        this.id = dto.id;
        this.answer = dto.answer;
        this.question = new OVQuestionList(dto.question);
    }
}

export { OVAnswer };
