import * as t from 'io-ts';
import { MemberMinList, MemberMinListType } from '../../members/models/member-min-list.model';

export const MinistryListType = t.type({
    id: t.number,
    name: t.string,
    description: t.union([t.string, t.null]),
    lead: MemberMinListType,
    assist: t.union([MemberMinListType, t.null]),
    hasChild: t.union([t.boolean, t.number, t.null, t.undefined]),
});

export type MinistryListDTO = t.TypeOf<typeof MinistryListType>;

class MinistryList {
    id: number;
    name: string;
    description: string | null;
    lead: MemberMinList;
    assist: MemberMinList | null;
    child: MinistryList[];
    hasChild: boolean;

    constructor(dto: MinistryListDTO) {
        this.id = dto.id;
        this.name = dto.name;
        this.description = dto.description;
        this.lead = new MemberMinList(dto.lead);
        this.assist = dto.assist ? new MemberMinList(dto.assist) : null;
        this.child = [];
        this.hasChild = !!dto.hasChild;
    }
}

export { MinistryList };
