import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import { match } from 'react-router-dom';
import { Button } from '../../../../../global/components/button';
import { InfoItem } from '../../../../../global/components/info-item';
import { goToState } from '../../../../../global/helpers/url-parse.helper';
import { Header, Root } from './ov-cohort-card.styles';
import { useSpinner } from '../../../../../global/hooks/use-spinner';
import { AuthService } from '../../../../../auth/services/AuthService';
import { BottomPadding } from '../../../../../global/components/main-wrap/main-wrap.styles';
import { getOVCohortCard } from '../../../redux/cohort/ov-cohort.selectors';
import { clearOVCohortCard, deleteOVCohortCard, loadOVCohortCard } from '../../../redux/cohort/ov-cohort.actions';
import OVRegistrationList from '../../../components/registration/registration-list/ov-registration-list';

type OVCohortCardPageRouteParams = {
    id: string;
};

type OVCohortCardProp = {
    match: match<OVCohortCardPageRouteParams>;
};

const OVCohortCard = (props: OVCohortCardProp) => {
    const availablePage = useMemo(
        () => AuthService.availableForAdmin() || AuthService.hasOVPermission(),
        [],
    );
    const dispatch = useDispatch();
    const spinnerRunner = useSpinner(dispatch);
    const ovCohortCard = useSelector(getOVCohortCard);
    const [id, setId] = useState<number | null>(null);

    useEffect(() => {
        if (!availablePage) {
            goToState('/');
            return;
        }
    }, []);
    
    useEffect(
        () => {
            if (props.match.params.id && Number(props.match.params.id) !== id) {
                dispatch(clearOVCohortCard());
                setId(Number(props.match.params.id));
                spinnerRunner(loadOVCohortCard(Number(props.match.params.id)));
            }
        },
        [props.match.params.id],
    );

    const goToList = useCallback(() => {
        goToState('/ov/cohorts');
    }, []);

    const goToEdit = useCallback(() => {
        goToState(`/ov/cohort/${props.match.params.id}/edit`);
    }, [props.match.params.id]);

    const goToDelete = useCallback(() => {
        if (!id) {
            return;
        }
        confirmAlert({
            title: 'Удаление',
            message: 'Вы уверены, что хотите удалить эту когорту?',
            buttons: [
                {
                    label: 'Да',
                    onClick: async () => {
                        await spinnerRunner(deleteOVCohortCard(id));
                        goToList();
                    },
                },
                {
                    label: 'Нет',
                    onClick: () => null,
                },
            ],
        });
    }, [confirmAlert, id]);

    if (!ovCohortCard) {
        return (
            <Root>
                <Header>
                    <Button
                        title="Перейти в список"
                        onClick={goToList}
                    />
                </Header>
            </Root>
        );
    }

    return (
        <Root>
            <Header>
                <Button
                    title="Перейти в список"
                    onClick={goToList}
                />
                <Button
                    title="Редактировать"
                    onClick={goToEdit}
                />
                {
                    !ovCohortCard.isRegistrationOpen &&
                    <Button
                        title="Удалить"
                        onClick={goToDelete}
                    />
                }
            </Header>
            <InfoItem
                title="Название"
                value={ovCohortCard.title}
            />
            <OVRegistrationList
                cohortId={ovCohortCard.id}
            />
            <BottomPadding />
        </Root>
    );
};

export default OVCohortCard;
