import styled from 'styled-components/macro';

const Root = styled.div`
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 12px;
`;

export { Root, Header };