import { createSelector, Selector } from 'reselect';
import { StateModel } from '../../../global/redux/state.model';
import { FamilyCard } from '../models/family-card.model';
import { FamilyList } from '../models/family-list.model';
import { FamilyState } from './family.types';

export const getFamilyState = (state: StateModel): FamilyState => state.family;

export const getFamilyList: Selector<StateModel, FamilyList[]> =
    createSelector(
        [getFamilyState],
        (familyState) => familyState.family,
    );

export const getFamilyTotalCount: Selector<StateModel, number> =
    createSelector(
        [getFamilyState],
        (familyState) => familyState.totalCount,
    );

export const getFamilyCard: Selector<StateModel, FamilyCard | null> =
    createSelector(
        [getFamilyState],
        (familyState) => familyState.familyCard,
    );
