import { api } from '../../../config/api';
import { BaseRepository, ResolverMethods } from '../../../global/repository/base.repository';
import { apiHost } from '../../../params';
import { AdoptedInfo, AdoptedInfoDTO, AdoptedInfoType } from '../models/adopted-info.model';
import { CreateAdoptedInfoParams } from '../types/create-adopted-info-params';

export class AdoptedInfoRepository extends BaseRepository {
    static defaultUrl = `${apiHost}/api/v1/adopted-info/`;
    static api = api;

    static getCard(profileId: number) {
        return AdoptedInfoRepository.resolve<AdoptedInfoDTO, AdoptedInfo>({
            url: `${profileId}/`,
            model: AdoptedInfo,
            modelType: AdoptedInfoType,
            withoutToast: true,
        });
    };

    static create(profileId: number, bodyData: CreateAdoptedInfoParams) {
        return AdoptedInfoRepository.resolve<AdoptedInfoDTO, AdoptedInfo>({
            url: `${profileId}/create`,
            method: ResolverMethods.post,
            bodyData,
            model: AdoptedInfo,
            modelType: AdoptedInfoType,
        });
    };

    static update(profileId: number, bodyData: CreateAdoptedInfoParams) {
        return AdoptedInfoRepository.resolveWithoutModel<void>({
            url: `${profileId}/update`,
            method: ResolverMethods.put,
            bodyData,
        });
    };
}
