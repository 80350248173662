import { combineReducers } from 'redux';
import chatInfoReducer from '../../modules/chat-info/redux/chat-info.reducer';
import messagesReducer from '../../modules/daily-messages/redux/messages.reducer';
import familyReducer from '../../modules/family/redux/family.reducer';
import groupsReducer from '../../modules/groups/redux/groups.reducer';
import membersReducer from '../../modules/members/redux/members.reducer';
import ministriesReducer from '../../modules/ministries/redux/ministries.reducer';
import resourcesReducer from '../../modules/resources/redux/resources.reducer';
import serviceReducer from '../../modules/service/redux/service.reducer';
import usersReducer from '../../modules/users/redux/users.reducer';
import globalReducer from './global/global.reducer';
import ovCohortReducer from '../../modules/ov/redux/cohort/ov-cohort.reducer';
import ovRegistrationReducer from '../../modules/ov/redux/registrsation/ov-registration.reducer';

export const rootReducer = combineReducers({
    members: membersReducer,
    ministries: ministriesReducer,
    groups: groupsReducer,
    global: globalReducer,
    family: familyReducer,
    resources: resourcesReducer,
    chatInfo: chatInfoReducer,
    messages: messagesReducer,
    users: usersReducer,
    service: serviceReducer,
    ovCohort: ovCohortReducer,
    ovRegistration: ovRegistrationReducer,
});
