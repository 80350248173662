import { api } from '../../../config/api';
import { BaseRepository, ResolverMethods } from '../../../global/repository/base.repository';
import { apiHost } from '../../../params';
import { OVAnswer, OVAnswerDTO, OVAnswerType } from '../models/answer/ov-answer.model';
import { GetOVAnswerListBodyParams } from '../types/answer/get-ov-answer-list-body-params';
import { GetOVAnswerListQueryParams } from '../types/answer/get-ov-answer-list-query-params';

export class OVAnswerRepository extends BaseRepository {
    static defaultUrl = `${apiHost}/api/v1/fof-registration-answers/`;
    static api = api;

    static getList(queryParams: GetOVAnswerListQueryParams, bodyData: GetOVAnswerListBodyParams) {
        return OVAnswerRepository.resolveCollection<OVAnswerDTO, OVAnswer>({
            url: '',
            method: ResolverMethods.post,
            queryParams,
            bodyData,
            model: OVAnswer,
            modelType: OVAnswerType,
        });
    };
}
