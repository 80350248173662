import {
    GlobalActions,
    GlobalActionsEnum,
    GlobalState,
} from './global.types';

const initialState = new GlobalState();

const globalReducer = (state = initialState, action: GlobalActions) => {
    switch (action.type) {
        case GlobalActionsEnum.keydown:
        case GlobalActionsEnum.keyup:
            return {
                ...state,
                activeKeys: action.payload,
            };
        case GlobalActionsEnum.toggleGlobalSpinner:
            return {
                ...state,
                isShowGlobalSpinner: action.payload,
            };
        case GlobalActionsEnum.addDisplayedLocalSpinner:
        case GlobalActionsEnum.removeDisplayedLocalSpinner:
            return {
                ...state,
                displayedLocalSpinners: action.payload,
            };
        case GlobalActionsEnum.updateLocationSearch:
            return {
                ...state,
                locationSearch: action.payload,
            };
        case GlobalActionsEnum.setIsOpenMenu:
            return {
                ...state,
                isOpenMenu: action.payload,
            };
        default:
            return state;
    }
};

export default globalReducer;
