import styled from 'styled-components/macro';

const Root = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
`;

const Item = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px;
    margin: 2px;
    border-radius: 4px;
    background: #c30404;
    color: #fff;

    &.hasPayments {
        background: #02992f;
    }
`;

export { Root, Item };
