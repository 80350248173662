export type MemberSexItem = {
    label: string;
    value: string;
};

type MemberSexType = {
    [key: string]: MemberSexItem;
};

export const MemberSex: MemberSexType = {
    M: {
        label: 'Мужской',
        value: 'M',
    },
    F: {
        label: 'Женский',
        value: 'F',
    },
};

export const sexOptions = Object.values(MemberSex);
