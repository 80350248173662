import { api } from '../../../config/api';
import { BaseRepository, ResolverMethods } from '../../../global/repository/base.repository';
import { apiHost } from '../../../params';
import { OVRegistration, OVRegistrationDTO, OVRegistrationType } from '../models/registration/ov-registration.model';
import { GetOVRegistrationListBodyParams } from '../types/registration/get-ov-registration-list-body-params';
import { GetOVRegistrationListQueryParams } from '../types/registration/get-ov-registration-list-query-params';

export class OVRegistrationRepository extends BaseRepository {
    static defaultUrl = `${apiHost}/api/v1/fof-registrations/`;
    static api = api;

    static getList(queryParams: GetOVRegistrationListQueryParams, bodyData: GetOVRegistrationListBodyParams) {
        return OVRegistrationRepository.resolveCollection<OVRegistrationDTO, OVRegistration>({
            url: '',
            method: ResolverMethods.post,
            queryParams,
            bodyData,
            model: OVRegistration,
            modelType: OVRegistrationType,
        });
    };
}
