export type AbsenteeLetterStatusItem = {
    label: string;
    value: string;
};

type AbsenteeLetterStatusType = {
    [key: string]: AbsenteeLetterStatusItem;
};

export const AbsenteeLetterStatus: AbsenteeLetterStatusType = {
    WAIT: {
        label: 'Ждём',
        value: 'WAIT',
    },
    EXIST: {
        label: 'Есть',
        value: 'EXIST',
    },
    NOT: {
        label: 'Не нужно',
        value: 'NOT',
    },
    PROBLEM: {
        label: 'Проблема',
        value: 'PROBLEM',
    },
};

export const absenteeLetterStatusOptions = Object.values(AbsenteeLetterStatus);
