import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { match } from 'react-router-dom';
import { Button } from '../../../../global/components/button';
import { InfoItem } from '../../../../global/components/info-item';
import { goToState } from '../../../../global/helpers/url-parse.helper';
import { Header, Root } from './chat-info-card.styles';
import { InfoLine } from '../../../../global/components/info-line';
import { getChatInfoCard } from '../../redux/chat-info.selectors';
import { clearChatInfoCard, loadChatInfoCard } from '../../redux/chat-info.actions';
import UpdateLinkModal from '../../components/update-link-modal/update-link-modal';
import { useSpinner } from '../../../../global/hooks/use-spinner';
import { AuthService } from '../../../../auth/services/AuthService';
import { BottomPadding } from '../../../../global/components/main-wrap/main-wrap.styles';

type ChatInfoCardPageRouteParams = {
    id: string;
};

type ChatInfoCardProp = {
    match: match<ChatInfoCardPageRouteParams>;
};

const ChatInfoCard = (props: ChatInfoCardProp) => {
    const showForAdmin = useMemo(() => AuthService.availableForAdmin(), [AuthService.availableForAdmin]);
    const dispatch = useDispatch();
    const spinnerRunner = useSpinner(dispatch);
    const [openUpdateLinkModal, setOpenUpdateLinkModal] =
        useState<boolean>(false);
    const chatInfoCard = useSelector(getChatInfoCard);
    const [id, setId] = useState<number | null>(null);

    useEffect(() => {
        if (!showForAdmin) {
            goToState('/');
            return;
        }
    }, []);

    useEffect(() => {
        if (props.match.params.id && Number(props.match.params.id) !== id) {
            dispatch(clearChatInfoCard());
            setId(Number(props.match.params.id));
            spinnerRunner(loadChatInfoCard(Number(props.match.params.id)));
        }
    }, [props.match.params.id]);

    const openUpdateLinkModalCallback = useCallback(() => {
        setOpenUpdateLinkModal(true);
    }, [setOpenUpdateLinkModal]);

    const closeUpdateLinkModalCallback = useCallback((withUpdate?: boolean) => {
        setOpenUpdateLinkModal(false);
        if (withUpdate) {
            spinnerRunner(loadChatInfoCard(Number(props.match.params.id)));
        }
    }, [setOpenUpdateLinkModal, spinnerRunner, loadChatInfoCard, props.match.params.id]);

    const goToList = useCallback(() => {
        goToState('/chat-info');
    }, []);
    if (!chatInfoCard) {
        return (
            <Root>
                <Header>
                    <Button
                        title="Перейти в список"
                        onClick={goToList}
                    />
                </Header>
            </Root>
        );
    }

    return (
        <Root>
            <Header>
                <Button
                    title="Перейти в список"
                    onClick={goToList}
                />
                <Button
                    title="Обновить ссылки"
                    onClick={openUpdateLinkModalCallback}
                />
            </Header>
            <InfoLine>
                <InfoItem
                    title="id в телеграмме"
                    value={chatInfoCard.chatId}
                />
                <InfoItem
                    title="Название чата"
                    value={chatInfoCard.chatName}
                />
                <InfoItem
                    title="Описание чата"
                    value={chatInfoCard.chatDescription}
                />
            </InfoLine>
            <InfoLine>
                <InfoItem
                    title="Связанная группа"
                    value={<div>
                        {
                            chatInfoCard.group && <a href={`#/group/${chatInfoCard.group.id}`}>{chatInfoCard.group.name}</a>
                        }
                    </div>}
                />
                <InfoItem
                    title="Связанное служение"
                    value={<div>
                        {
                            chatInfoCard.ministry && <a href={`#/ministry/${chatInfoCard.ministry.id}`}>{chatInfoCard.ministry.name}</a>
                        }
                    </div>}
                />
            </InfoLine>
            {!!openUpdateLinkModal && (
                <UpdateLinkModal
                    chatInfo={chatInfoCard}
                    onClose={closeUpdateLinkModalCallback}
                />
            )}
            <BottomPadding />
        </Root>
    );
};

export default ChatInfoCard;
