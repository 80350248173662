import * as t from 'io-ts';
import { GroupList, GroupListType } from '../../groups/models/group-list.model';
import { MemberMinList, MemberMinListType } from '../../members/models/member-min-list.model';
import { MinistryListType, MinistryList } from '../../ministries/models/ministry-list.model';

export const ChatInfoListType = t.type({
    id: t.number,
    chatId: t.string,
    name: t.string,
    group: t.union([t.null, t.undefined, GroupListType]),
    ministry: t.union([t.null, t.undefined, MinistryListType]),
    profiles: t.union([t.array(MemberMinListType), t.undefined]),
});

export type ChatInfoListDTO = t.TypeOf<typeof ChatInfoListType>;

class ChatInfoList {
    id: number;
    chatId: string;
    chatName: string;
    group: GroupList | null;
    ministry: MinistryList | null;
    profiles: MemberMinList[];

    constructor(dto: ChatInfoListDTO) {
        this.id = dto.id;
        this.chatId = dto.chatId;
        this.chatName = dto.name;
        this.group = dto.group ? new GroupList(dto.group) : null;
        this.ministry = dto.ministry ? new MinistryList(dto.ministry) : null;
        this.profiles = dto.profiles?.map(el =>new MemberMinList(el)) || [];
    }
}

export { ChatInfoList };
