import { BaseActionResult } from '../../../global/helpers/actions';
import { MessagesCard } from '../models/messages-card.model';
import { MessagesList } from '../models/messages-list.model';

export class MessagesState {
    messages: MessagesList[] = [];
    totalCount: number = 0;
    messageCard: MessagesCard | null = null;
}

export type UpdateMessagesAction = {
    messages: MessagesList[];
    totalCount: number;
}

export enum MessagesActionsEnum {
    updateMessages = 'UPDATE_MESSAGES',
    updateMessageCard = 'UPDATE_MESSAGE_CARD',
}

export type MessagesActions =
    BaseActionResult<
        UpdateMessagesAction,
        typeof MessagesActionsEnum.updateMessages
    >
    | BaseActionResult<
        MessagesCard | null,
        typeof MessagesActionsEnum.updateMessageCard
    >;
