import * as t from 'io-ts';
import { ChatInfoListType } from './chat-info-list.model';
import { GroupList } from '../../groups/models/group-list.model';
import { MinistryList } from '../../ministries/models/ministry-list.model';
import { MemberMinList } from '../../members/models/member-min-list.model';

export const ChatInfoCardType = t.intersection([
    ChatInfoListType,
    t.type({
        description: t.union([t.null, t.string]),
    })
]);

export type ChatInfoCardDTO = t.TypeOf<typeof ChatInfoCardType>;

class ChatInfoCard {
    id: number;
    chatId: string;
    chatName: string;
    chatDescription: string | null;
    group: GroupList | null;
    ministry: MinistryList | null;
    profiles: MemberMinList[];

    constructor(dto: ChatInfoCardDTO) {
        this.id = dto.id;
        this.chatId = dto.chatId;
        this.chatName = dto.name;
        this.chatDescription = dto.description;
        this.group = dto.group ? new GroupList(dto.group) : null;
        this.ministry = dto.ministry ? new MinistryList(dto.ministry) : null;
        this.profiles = dto.profiles?.map(el =>new MemberMinList(el)) || [];
    }
}

export { ChatInfoCard };
