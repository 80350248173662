import React, { useCallback, useEffect, useMemo } from 'react';
import { Button } from '../../../../global/components/button';
import { Header, Root } from './groups-list-on-map.styles';
import { AuthService } from '../../../../auth/services/AuthService';
import { goToState } from '../../../../global/helpers/url-parse.helper';
import { LocalSpinner } from '../../../../global/components/main-wrap/main-wrap.styles';
import { InfoItem } from '../../../../global/components/info-item';
import MapComponent, { MapObject } from '../../../../global/components/map/map';
import { useDispatch, useSelector } from 'react-redux';
import { getAllGroups } from '../../redux/groups.selectors';
import { useLocalSpinner } from '../../../../global/hooks/use-local-spinner';
import { makeGetIsShowLocalSpinner } from '../../../../global/redux/global/global.selectors';
import { LocalSpinnerKeysEnum } from '../../../../global/constants';
import { ClipLoader } from 'react-spinners';
import { updateAllGroups } from '../../redux/groups.actions';
import { getNumberCoordByString } from '../../../../global/helpers/map.helper';
import { getLeadersNamesStringHelper } from '../../helpers/groups-on-map.helpers';

export const GroupsOnMap = () => {
    const dispatch = useDispatch();
    const localSpinnerRunner = useLocalSpinner(dispatch);
    const isShowSpinner = useSelector(makeGetIsShowLocalSpinner(LocalSpinnerKeysEnum.allGroups));
    const allGroups = useSelector(getAllGroups);

    const showForAdmin = useMemo(() => AuthService.availableForAdmin(), [AuthService.availableForAdmin]);

    useEffect(() => {
        if (!showForAdmin) {
            goToState('/');
            return;
        }
        
        localSpinnerRunner(updateAllGroups(), LocalSpinnerKeysEnum.allGroups);
    }, []);

    const goToList = useCallback(() => {        
        goToState('/groups');
    }, []);

    const handleMarkerClick = useCallback((id: number) => {
        goToState(`/group/${id}`);
    }, []);

    const mapObjects = useMemo<MapObject[]>(() => {
        return allGroups.map<MapObject>(group => ({
            coords: getNumberCoordByString(group.coords),
            onClickCallback: () => handleMarkerClick(group.id),
            tooltipData: {
                description: `${getLeadersNamesStringHelper(group) || ''}<br>${(group.day || null)?.label}`,
                header: group?.name || '',
            },
        }));
    }, [allGroups, handleMarkerClick]);

    return (
        <Root>
            <Header>
                {!!showForAdmin &&
                    <Button
                        title="Перейти в список"
                        onClick={goToList}
                    />
                }
            </Header>
            {!!mapObjects.length && (<InfoItem
                title=""
                value={
                    <MapComponent
                        objects={mapObjects}
                        disable
                        size={{ width: 100, height: 100 }}
                    />}
            />)}
            <ClipLoader loading={isShowSpinner} size={30} css={LocalSpinner} />
        </Root>
    );
};
