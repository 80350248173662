import { createSelector, Selector } from 'reselect';
import { StateModel } from '../../../global/redux/state.model';
import { SettingsCard } from '../models/settings-card.model';
import { ServiceState } from './service.types';

export const getServiceState = (state: StateModel): ServiceState => state.service;

export const getSettingsCard: Selector<StateModel, SettingsCard | null> =
    createSelector(
        [getServiceState],
        (serviceState) => serviceState.settingCard,
    );
