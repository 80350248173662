import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { Button } from '../../../../global/components/button';
import { TextInput } from '../../../../global/components/form-controls/text-input/text-input';
import { InfoItem } from '../../../../global/components/info-item';
import { InfoLine } from '../../../../global/components/info-line';
import { goToState } from '../../../../global/helpers/url-parse.helper';
import { Footer, Header, Root } from './groups-create.styles';
import { useSpinner } from '../../../../global/hooks/use-spinner';
import { EntityForSelect } from '../../../../global/components/multiple-select-entity/multiple-select-entity';
import { loadMembersForSelect } from '../../../members/helpers/api.helpers';
import SingleSelectEntity from '../../../../global/components/single-select-entity/single-select-entity';
import { CreateGroupParams } from '../../types/create-group-params';
import { createGroupCard } from '../../redux/groups.actions';
import MapComponent, { MapObject } from '../../../../global/components/map/map';
import { getNumberCoordByString } from '../../../../global/helpers/map.helper';
import { loadChatInfoForSelect } from '../../../chat-info/helpers/api.helpers';
import { AuthService } from '../../../../auth/services/AuthService';
import { DayItem, dayOptions } from '../../../daily-messages/models/day.model';
import RunTime from '../../../daily-messages/components/run-time/run-time';
import { BottomPadding } from '../../../../global/components/main-wrap/main-wrap.styles';

const GroupCreate = () => {
    const showForAdmin = useMemo(() => AuthService.availableForAdmin(), [AuthService.availableForAdmin]);
    const dispatch = useDispatch();
    const spinnerRunner = useSpinner(dispatch);
    const [name, setName] = useState<string | null>(null);
    const [chatInfo, setChatInfo] = useState<EntityForSelect | null>(null);
    const [district, setDistrict] = useState<string | null>(null);
    const [lead, setLead] = useState<EntityForSelect | null>(null);
    const [assist, setAssist] = useState<EntityForSelect | null>(null);
    const [coords, setCoords] = useState<string | null>(null);
    const [day, setDay] = useState<DayItem | null>(null);
    const [startTime, setStartTime] = useState<string | null>(null);

    useEffect(() => {
        if (!showForAdmin) {
            goToState('/');
            return;
        }
    }, []);

    const goToList = useCallback(() => {
        goToState('/groups');
    }, []);

    const isValidForm = useMemo(
        () => !!name && !!lead && !!district && !!coords && !!day && !!startTime,
        [
            name,
            lead,
            district,
            coords,
            day,
            startTime,
        ],
    );

    const createCallback = useCallback(() => {
        if (!name || !lead || !district || !coords || !day || !startTime) {
            return;
        }
        const params: CreateGroupParams = {
            name: name,
            chatInfoId: chatInfo?.id || null,
            district,
            leadId: lead.id,
            assistId: assist?.id || null,
            coords,
            day: Number(day.value),
            startTime,
        };
        spinnerRunner(createGroupCard(params));
    }, [
        name,
        day,
        startTime,
        chatInfo,
        district,
        lead,
        assist,
        coords,
        spinnerRunner,
        createGroupCard,
    ]);

    const coordsValue = useMemo(() => {
        if (!coords) {
            navigator.geolocation.getCurrentPosition(
                (result) => {
                    setCoords(`${result.coords.latitude},${result.coords.longitude}`);
                },
                () => {
                    setCoords('55.740488,37.626576');
                },
            );
            return null;
        }
        return getNumberCoordByString(coords);
    }, [coords, setCoords, getNumberCoordByString]);

    const updateCoordsCallback = useCallback((newCoords: number[]) => {
        setCoords(`${newCoords[0]},${newCoords[1]}`);
    }, [setCoords]);

    const mapObjects = useMemo<MapObject[]>(() => {
        return [{
            coords: coordsValue,
            updateCoordsCallback,
        }];
    }, [coordsValue, updateCoordsCallback]);

    return (
        <Root>
            <Header>
                <Button
                    title="Перейти в список"
                    onClick={goToList}
                />
            </Header>
            <InfoLine>
                <InfoItem
                    title="Название *"
                    value={<TextInput
                        value={name}
                        onChange={setName}
                    />}
                />
                <InfoItem
                    title="Район *"
                    value={<TextInput
                        value={district}
                        onChange={setDistrict}
                    />}
                />
                <SingleSelectEntity
                    label="Чат в телеграмме"
                    value={chatInfo}
                    onChange={setChatInfo}
                    loadOptions={loadChatInfoForSelect}
                />
            </InfoLine>
            <InfoLine>
                <InfoItem
                    title="День проведения *"
                    value={<Select
                        options={dayOptions}
                        defaultValue={day}
                        onChange={setDay}
                        isClearable
                    />}
                />
                <InfoItem
                    title="Время начала *"
                    value={<RunTime
                        value={startTime}
                        onChange={setStartTime}
                    />}
                />
            </InfoLine>
            <InfoLine>
                <SingleSelectEntity
                    label="Лидер *"
                    value={lead}
                    onChange={setLead}
                    loadOptions={loadMembersForSelect}
                />
                <SingleSelectEntity
                    label="Второй лидер"
                    value={assist}
                    onChange={setAssist}
                    loadOptions={loadMembersForSelect}
                />
            </InfoLine>
            {
                !!coordsValue && 
                <InfoItem
                    title="Координаты *"
                    value={<MapComponent
                        objects={mapObjects}
                    />}
                />
            }
            <Footer>
                <Button
                    title="Создать"
                    onClick={createCallback}
                    disabled={!isValidForm}
                />
            </Footer>
            <BottomPadding />
        </Root>
    );
};

export default GroupCreate;
