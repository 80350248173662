import React, { useMemo } from 'react';
import moment from 'moment';
import { Root, Title, Value } from './index.styles';

type InfoItemProps = {
    title: string;
    value: string | number | Date | null | JSX.Element;
    dateFormat?: string;
    className?: string;
    valueStyle?: React.CSSProperties;
    hideIsEmpty?: boolean;
    valueWidth?: number;
};

export const InfoItem = ({ title, value, dateFormat, className, valueStyle, hideIsEmpty, valueWidth }: InfoItemProps) => {
    const visibleValue = useMemo(() => {
        if (value === null) {
            return '-';
        }
        if (!dateFormat) {
            return value;
        }
        return moment(`${value}`).format(dateFormat);
    }, [value, dateFormat]);

    if (hideIsEmpty && !value) {
        return null;
    }

    return <Root className={className}>
        <Title>{title}</Title>
        <Value style={valueStyle} width={valueWidth}>{visibleValue}</Value>
    </Root>;
};
