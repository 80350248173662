import { toast } from 'react-toastify';
import { baseAsyncActionCreator } from '../../../global/helpers/actions';
import { goToState } from '../../../global/helpers/url-parse.helper';
import { GlobalActionsEnum } from '../../../global/redux/global/global.types';
import { FamilyCard } from '../models/family-card.model';
import { FamilyRepository } from '../repositories/family.repository';
import { CreateFamilyParams } from '../types/create-family-params';
import { GetFamilyListBodyParams } from '../types/get-family-list-body-params';
import { GetFamilyListQueryParams } from '../types/get-family-list-query-params';
import { FamilyActionsEnum, UpdateFamilyAction } from './family.types';

const updateFamily = (queryParams: GetFamilyListQueryParams, bodyData: GetFamilyListBodyParams) =>
    baseAsyncActionCreator<UpdateFamilyAction>(async () => {
        const result = await FamilyRepository.getList(queryParams, bodyData);
        return [FamilyActionsEnum.updateFamily, {
            family: result.items,
            totalCount: result.totalCount,
        }];
    });

const clearFamilyCard = () =>
    baseAsyncActionCreator<FamilyCard | null>(async () => {
        return [FamilyActionsEnum.updateFamilyCard, null];
    });

const loadFamilyCard = (id: number) =>
    baseAsyncActionCreator<FamilyCard | null>(async () => {
        const result = await FamilyRepository.getCard(id);
        return [FamilyActionsEnum.updateFamilyCard, result];
    });

const createFamilyCard = (params: CreateFamilyParams, withoutRedirect?: boolean) =>
    baseAsyncActionCreator<string>(async () => {
        await FamilyRepository.create(params);
        toast.success('Запись успешно добавлена!');
        if (!withoutRedirect) {
            goToState('/family');
        }
        return [GlobalActionsEnum.empty, ''];
    });

const updateFamilyCard = (id: number, params: CreateFamilyParams) =>
    baseAsyncActionCreator<string>(async () => {
        await FamilyRepository.update(id, params);
        toast.success('Запись успешно обновлена!');
        goToState(`/family/${id}`);
        return [GlobalActionsEnum.empty, ''];
    });

const deleteFamilyCard = (id: number) =>
    baseAsyncActionCreator<string>(async () => {
        await FamilyRepository.delete(id);
        toast.success('Запись успешно удалена!');
        return [GlobalActionsEnum.empty, ''];
    });

export {
    updateFamily,
    clearFamilyCard,
    loadFamilyCard,
    createFamilyCard,
    updateFamilyCard,
    deleteFamilyCard,
};
