import React, { useEffect, useMemo, useState } from 'react';
import { Item, Root } from './payment-info.styles';
import { Payment } from '../../models/payment.model';
import { PaymentsRepository } from '../../repositories/payments.repository';
import { getPaymentYesrStatistic } from '../../helpers/payment.helpers';
import classNames from 'classnames';

type PaymentInfoProps = {
    memberId: number;
    witnessesDate: Date | null;
};

const PaymentInfo = (props: PaymentInfoProps) => {
    const [payments, setPayments] = useState<Payment[]>([]);

    useEffect(() => {
        PaymentsRepository.getByMember(props.memberId)
            .then(data => setPayments(data.filter(el => !!el.date)));
    }, []);

    const paymentsStatistics = useMemo(
        () => getPaymentYesrStatistic(payments, props.witnessesDate),
        [payments, props.witnessesDate],
    );
   
    return (
        <Root>
            {
                paymentsStatistics.map((el, i) => <Item
                    key={i}
                    className={classNames({
                        hasPayments: !!el.hasPayments,
                    })}
                >
                    {el.mounthTitle}
                </Item>)
            }
        </Root>
    );
};

export default PaymentInfo;
