import { BaseActionResult } from '../../../global/helpers/actions';
import { UserCard } from '../models/user-card.model';
import { UserList } from '../models/user-list.model';

export class UsersState {
    users: UserList[] = [];
    totalCount: number = 0;
    userCard: UserCard | null = null;
}

export type UpdateUsersAction = {
    users: UserList[];
    totalCount: number;
}

export enum UsersActionsEnum {
    updateUsers = 'UPDATE_USERS',
    updateUserCard = 'UPDATE_USER_CARD',
}

export type UsersActions =
    BaseActionResult<
        UpdateUsersAction,
        typeof UsersActionsEnum.updateUsers
    >
    | BaseActionResult<
        UserCard | null,
        typeof UsersActionsEnum.updateUserCard
    >;
