import React, { useCallback, useMemo } from 'react';
import { MultiValue } from 'react-select';
import AsyncSelect from 'react-select/async';
import { LocalAnyType } from '../../helpers/type.helper';
import { Root } from './multiple-select-entity.styles';

export type EntityForSelect = {
    id: number;
    name: string;
};

type MultipleSelectEntityProps = {
    label: string;
    values: EntityForSelect[];
    maxWidth?: number;
    onChange: (values: EntityForSelect[]) => void;
    loadOptions: (searchString: string | null) => Promise<EntityForSelect[]>;
};

let timeout: LocalAnyType = null;

const MultipleSelectEntity = (props: MultipleSelectEntityProps) => {
    const onChangeCallback = useCallback(
        (newValue: MultiValue<EntityForSelect>) => {
            props.onChange(newValue.map((el) => ({ ...el })));
        },
        [props.onChange],
    );

    const loadOptionsCallback = (searchString: string | null) => {
        return new Promise((resolve) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                resolve(props.loadOptions(searchString));
            }, 500);
        });
    };
    const selectStyles = useMemo(
        () => ({
            menuPortal: (base: LocalAnyType) => ({ ...base, zIndex: 9999 }),
        }),
        [],
    );

    return (
        <Root
            title={props.label}
            maxWidth={props.maxWidth}
            value={
                <AsyncSelect
                    defaultValue={props.values}
                    value={props.values}
                    onChange={onChangeCallback}
                    isMulti
                    loadOptions={loadOptionsCallback}
                    defaultOptions
                    styles={selectStyles}
                    getOptionValue={(option) => `${option['id']}`}
                    getOptionLabel={(option) => `${option['name']}`}
                    menuPortalTarget={document.body}
                />
            }
        />
    );
};

export default MultipleSelectEntity;
