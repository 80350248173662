import styled from 'styled-components/macro';

const Root = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
`;

const Description = styled.div`
    margin: 0 12px 0 6px;
`;

export { Root, Description };
